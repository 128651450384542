import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { getCompanyLanguagePath } from "utils/localStorage";

// import LngDetector from 'i18next-browser-languagedetector';

const initConf = {
  fallbackLng: "se",
  interpolation: {
    escapeValue: false,
  },
  backend: {},
  // debug: process.env.NODE_ENV === "development",
  debug: false,
};
if (getCompanyLanguagePath()) {
  initConf.backend = {
    loadPath: getCompanyLanguagePath(),
  };
}

i18n.use(initReactI18next).use(HttpApi).init(initConf);

export default i18n;
