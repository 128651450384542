import Axios from "axios";
import config from "config";
import {
  getRefreshCookie,
  setAuthCookie,
  deleteAuthCookie,
  getAccessCookie,
  getXGroupId,
} from "utils/auth";

import { refreshTokenEndpoint } from "api/auth";
import { paths } from "routing/routes";

const HTTP = Axios.create({
  baseURL: config.api_url,
  Accept: "application/json",
});

HTTP.interceptors.request.use(async (config) => {
  const token = getAccessCookie();
  if (token !== undefined) {
    config.headers["Authorization"] = "Bearer " + getAccessCookie();
  }
  return config;
});

HTTP.interceptors.request.use(async (config) => {
  const groupId = getXGroupId();
  if (groupId !== undefined && !isNaN(groupId)) {
    config.headers["X-Group-ID"] = groupId;
  }
  return config;
});

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const { path } = refreshTokenEndpoint();
    if (error.response.status === 401 && originalRequest.url === path) {
      deleteAuthCookie();
      window.location.href = paths.LOGIN;
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error?.response?.data?.code === "token_not_valid"
    ) {
      originalRequest._retry = true;
      const refresh_token = getRefreshCookie();
      try {
        const {
          data: { access },
        } = await HTTP.post(path, {
          refresh: refresh_token,
        });
        originalRequest.headers["Authorization"] = "Bearer " + access;
        setAuthCookie(access, refresh_token);
        return HTTP(originalRequest);
      } catch {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default HTTP;
