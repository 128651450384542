import { Route } from "react-router-dom";
import { useAuth } from "context/auth-context";
import { useHistory } from "react-router-dom";
import { useCompany } from "context/company-context";
import { paths } from "routing/routes";
import PropTypes from "prop-types";
import Spinner from "components/spinner";

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;
  const history = useHistory();
  const { isAuthenticated, isLoading } = useAuth();
  const { isLoading: isCompanyLoading } = useCompany();

  if (isLoading || isCompanyLoading) return <Spinner />;

  if (!isAuthenticated) {
    history.push(paths.LOGIN);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
