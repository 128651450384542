import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

import CSSReset from "./cssReset";

interface ProviderProps {
  children: ReactNode;
  theme?: object;
}

function Provider(props: ProviderProps) {
  const { children, theme } = props;
  return (
    <ThemeProvider theme={theme || {}}>
      <CSSReset />
      {children}
    </ThemeProvider>
  );
}

export default Provider;
