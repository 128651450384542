import { patchDialoguePromiseComplete } from "api/valuationDialogues/index.js";
import { format } from "date-fns";
import sv from "date-fns/locale/sv";
import useApi from "hooks/useApi/index.js";
import { useEffect, useState } from "react";

import GreenCheck from "../../assets/svg/icons/GreenCheck.svg.js";

export const Promise = ({
  isGroup,
  promise,
  behavior,
  dialogId,
  prevDialogId,
  created_by,
}) => {
  const { path, method } = patchDialoguePromiseComplete(dialogId, promise.id);
  const {
    path: patchPreviousDialogPromisePath,
    method: patchPreviousDialogMethod,
  } = patchDialoguePromiseComplete(prevDialogId, promise.id);
  const [{ status }, makeRequest] = useApi(path, method);
  const [
    { status: patchPreviousDialogStatus },
    makePatchPreviousPromiseRequest,
  ] = useApi(patchPreviousDialogPromisePath, patchPreviousDialogMethod);
  const [completed, setCompleted] = useState(promise?.completed);
  const [patched, setPatched] = useState(false);

  const patchCompleted = (completed, promise) => {
    if (promise) {
      const isPrev = promise.hasOwnProperty("isPrev");
      if (isPrev && prevDialogId) {
        makePatchPreviousPromiseRequest({
          completed,
        });
      } else {
        makeRequest({
          completed,
        });
      }
      setPatched(true);
    }
  };

  useEffect(() => {
    if (status === "SUCCESS" || patchPreviousDialogStatus === "SUCCESS") {
      setCompleted(!completed);
    }
  }, [status, patchPreviousDialogStatus]);

  const date = format(new Date(promise.created_at), "dd MMMM yyyy", {
    locale: sv,
  });

  let updateDate;
  if (patched) {
    updateDate = format(new Date(), "dd MMMM yyyy", {
      locale: sv,
    });
  } else {
    updateDate = format(new Date(promise.updated_at), "dd MMMM yyyy", {
      locale: sv,
    });
  }
  return (
    <div className="border border-greyFive p-3 rounded-md mb-3">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="border w-3.5 h-3.5 rounded-full mr-1 " />
          <span className="text-lightGrey font-normal text-sm uppercase">
            <p>{date}</p>
          </span>
        </div>
        {completed ? (
          <div className="flex items-center  ">
            <span className="text-lightGrey font-normal text-sm uppercase mr-1">
              {updateDate}
            </span>
            <GreenCheck />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        onClick={() => patchCompleted(!completed, promise)}
        className="flex flex-row items-center mb-2 mt-2"
      >
        <input
          type="checkbox"
          checked={completed}
          className="h-5 w-5 mr-4 ring-offset-0"
        />

        <div className="flex justify-between w-full text-lightGrey font-normal text-sm uppercase">
          <label className={completed ? "line-through text-gray" : ""}>
            {promise.text}
          </label>
          {!isGroup && (
            <span>
              {created_by.first_name
                ? created_by.first_name.toUpperCase()
                : null}{" "}
              {created_by.last_name ? (
                <>
                  {created_by.last_name[0].toUpperCase()} {"."}
                </>
              ) : null}
            </span>
          )}
        </div>
      </div>
      <div className="text-lightGrey font-normal text-sm uppercase flex justify-between">
        <span>{behavior}</span>
      </div>
    </div>
  );
};
