import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "routing/routes";
import Collapsible from "react-collapsible";

import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import useApi, { axiosMethod } from "hooks/useApi";
import { getCompanyOverviewEndpoint } from "api/company";
import { getRolesEndpoint } from "api/role";

import { valueIcon, consequenceIcon, behaviorIcon } from "assets/svg/icons";
import { downChevron } from "assets/svg/global";
import { smartGoalLogo } from "assets/svg/logos";
import constants from "utils/constants";

import Button from "components/button";
import Frame from "components/frame";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";
import OverviewVision from "components/overviewVision";

import { useGroup } from "hooks/api/useGroup";
import { useGetConsequences } from "hooks/useGetConsequences";

import "./overview.css";
import { useSignifications } from "hooks/api/useSignifications";

const Overview = () => {
  const { t } = useTranslation();
  const { significations } = useSignifications();
  const { company: companyId, isLoading, companyLogo } = useCompany();
  const { user } = useAuth();
  const history = useHistory();
  const [companyData, setCompanyData] = useState();
  const { getConsequences } = useGetConsequences(companyId);
  const { group } = useGroup();

  const getCompanyData = async (companyId) => {
    try {
      if (!companyId) return;
      const companyEndpoint = getCompanyOverviewEndpoint(companyId);
      const response = await axiosMethod(companyEndpoint.method)(
        companyEndpoint.path,
      ); // apiParams
      setCompanyData(response.data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (companyId) getCompanyData(companyId);
  }, [companyId]);

  const userId = user.pk;
  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData, status: roleStatus }, makeRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );

  const role = roleData?.find(
    (role) => role?.user === userId && role?.company?.id === companyId,
  );

  const isPreProcess = constants.PRE_PROCESS_ROLES.includes(role?.type);
  const isStakeholder = role?.type === constants.STAKEHOLDER;

  const isTeamleader = group?.manager?.id === user?.pk;
  let isEmployee = role?.type === constants.EMPLOYEE;

  // User has role team lead - but not for this group
  if (!isTeamleader && role?.type === constants.TEAMLEADER) {
    isEmployee = true;
  }

  let behaviorPath = paths.BEHAVIOR;
  if (isStakeholder) {
    behaviorPath = paths.BEHAVIOR_STAKEHOLDER;
  } else if (isTeamleader) {
    behaviorPath = paths.BEHAVIOR_TEAMLEADER;
  }

  let consequencePath = paths.CONSEQUENCE;
  if (isStakeholder) {
    consequencePath = paths.CONSEQUENCE_STAKEHOLDER;
  } else if (isTeamleader) {
    consequencePath = paths.CONSEQUENCE_TEAMLEADER;
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    makeRoleRequest();
  }, [isLoading, makeRoleRequest]);

  const getRelatedBehaviors = (valueId) => {
    const { behaviors = [] } = companyData;
    return behaviors.filter((b) => b.value.id === valueId);
  };

  const getRelatedBehaviorOpinion = (behaviorId) => {
    const { opinions = [] } = companyData;
    return opinions.find(
      (opinion) =>
        opinion?.behavior?.id === behaviorId && opinion?.user?.id === userId,
    );
  };

  const getRelatedBehaviorSignification = (behaviorId) => {
    return significations.find(
      (signification) => signification?.behavior?.id === behaviorId,
    );
  };

  if (roleStatus === "FETCHING" || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {companyLogo && (
        <img
          width="120px"
          height="120px"
          src={companyLogo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = smartGoalLogo;
          }}
          className="z-10 inline absolute top-8 left-8"
          alt="company logo"
        />
      )}

      <OverviewVision
        vision={companyData?.vision}
        isPreProcess={isPreProcess}
      />

      <div className="grid grid-cols-12 gap-6 mt-8 mr-8 ml-8 min-h-screen60">
        <div className="col-span-12 border border-greyFive p-6 mb-4 rounded-md">
          <div className="grid grid-cols-12">
            <div className="col-start-1 col-span-6">
              <span className="text-base leading-100 mb-6 text-darkBlue font-normal text-3xl">
                {t("generic.header_values_behaviors")}
              </span>
            </div>

            <div className="col-span-3 text-right mt-auto">
              {isPreProcess && (
                <Button
                  onClick={() => history.push(paths.VALUE)}
                  variant="flat"
                >
                  {t("overview.edit_values")}{" "}
                </Button>
              )}
            </div>

            <div className="col-span-3 text-right mt-auto">
              {!isEmployee && (
                <Button
                  onClick={() => history.push(behaviorPath)}
                  variant="flat"
                >
                  {isTeamleader
                    ? t("overview.edit_group_behaviors")
                    : t("overview.edit_behaviors")}{" "}
                </Button>
              )}
            </div>
          </div>

          {companyData?.values?.length === 0 ? (
            <div className="flex flex-row h-full">
              <Button
                className="m-auto text-lg"
                onClick={() => history.push(paths.VALUE)}
                variant="flat"
              >
                {t("overview.empty_value")}
              </Button>
            </div>
          ) : (
            <div className="grid grid-cols-4 gap-2 mt-8">
              {companyData?.values?.map((v, i) => {
                return (
                  <div key={i}>
                    <Frame
                      frameClassname="w-11/12"
                      icon={valueIcon}
                      purpose={v.purpose}
                      description={v.description}
                      bottomContent={
                        <>
                          {getRelatedBehaviors(v.id).map((b, i) => {
                            const opinion = getRelatedBehaviorOpinion(b.id);
                            const signification =
                              getRelatedBehaviorSignification(b.id);
                            return (
                              <Collapsible
                                key={i}
                                transitionTime={200}
                                trigger={
                                  <div className="grid grid-cols-12">
                                    <div className="col-start-1 col-span-1">
                                      <img
                                        src={behaviorIcon}
                                        alt="behaviorIcon"
                                      />
                                    </div>

                                    <div className="col-span-10 py-1 px-2 text-darkBlue font-semibold text-base">
                                      {b.description}
                                    </div>

                                    <div className="col-span-1 m-auto">
                                      <img
                                        className="chevron"
                                        src={downChevron}
                                        alt="downChevron"
                                      />
                                    </div>
                                  </div>
                                }
                              >
                                <div className="px-2 mb-4 ml-5 text-sm text-grey font-semibolds">
                                  <p>{b.note}</p>
                                </div>

                                {isStakeholder && opinion && (
                                  <>
                                    <div className="py-1 px-2 ml-5 text-darkBlue font-semibold text-base">
                                      {t("generic.header_opinion")}
                                    </div>
                                    <div className="px-2 mb-4 ml-5 text-sm text-grey font-semibolds">
                                      <p>{opinion.note}</p>
                                    </div>
                                  </>
                                )}

                                {signification && (
                                  <>
                                    <div className="py-1 px-2 ml-5 text-darkBlue font-semibold text-base">
                                      {t("generic.header_signification")}
                                    </div>
                                    <div className="px-2 mb-4 ml-5 text-sm text-grey font-semibolds">
                                      <p>{signification.note}</p>
                                    </div>
                                  </>
                                )}
                              </Collapsible>
                            );
                          })}
                        </>
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 mt-8 mr-8 ml-8">
        <div className="col-span-12 border border-greyFive p-6 mb-4 rounded-md">
          <div className="grid grid-cols-12">
            <div className="col-start-1 col-span-6">
              <span className="text-base leading-100 mb-6 text-darkBlue font-normal text-3xl">
                {t("generic.header_consequences")}
              </span>
            </div>
            <div className="col-span-6 text-right mt-auto">
              {!isEmployee && (
                <Button
                  onClick={() => history.push(consequencePath)}
                  variant="flat"
                >
                  {isTeamleader
                    ? t("overview.edit_group_consequences")
                    : t("overview.edit_consequences")}
                </Button>
              )}
            </div>
          </div>

          {companyData?.consequences?.length === 0 && (
            <div className="flex flex-row h-full">
              <Button
                className="m-auto text-lg"
                onClick={() => history.push(paths.CONSEQUENCE)}
                variant="flat"
              >
                {t("overview.empty_consequence")}
              </Button>
            </div>
          )}

          <div className="grid grid-cols-4 gap-12 mt-8">
            <FrameWrapper
              frames={getConsequences(companyData)}
              icon={consequenceIcon}
              type={
                isStakeholder ? "overviewConsequenceStakeholder" : "consequence"
              }
              useAnyFrameIsSelected={false}
              noFlex={true}
              frameClassname="w-full mx-auto h-40"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
