import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Button from "components/button";
import MenuWrapper from "components/menuWrapper";
import Doughnut from "components/doughnut";
import ChartLegends from "components/chartLegends";
import { paths } from "routing/routes";
import { getDialogueSummary } from "api/valuationDialogues";
import useApi from "hooks/useApi";
import { useEffect } from "react";
import { calculatePercentage } from "utils/numbers";
import { getSignificationEndpoint } from "api/signification";
import { useGroup } from "hooks/api/useGroup";

const MeetingDialogsOverview = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dialogId = location.state?.dialogId || 0;
  const prevDialogId = location.state?.prevDialogId;
  const { group } = useGroup();
  const { path, method } = getDialogueSummary(dialogId);

  const [{ data }, makeRequest] = useApi(path, method);

  const significationEndpoint = getSignificationEndpoint();
  const [{ data: significations }, makeSignificationRequest] = useApi(
    significationEndpoint.path,
    significationEndpoint.method,
  );
  useEffect(() => {
    makeRequest();
    makeSignificationRequest();
  }, [makeRequest, makeSignificationRequest]);

  const setFeedbackBgColorClass = (feedback) => {
    if (!feedback.length > 0) return "bg-greyFive";
    if (feedback.some(({ score }) => score >= 1 && score <= 10)) {
      return "bg-lightGrayishRed";
    }
    if (feedback.some(({ score }) => score >= 11 && score <= 20)) {
      return "bg-lightGrayishOrange";
    }
    return "bg-lightGrayishGreen";
  };

  const getDoughnutDataPoints = (feedbackArray) => {
    let good = 0;
    let shouldImprove = 0;
    let mustBeAddressed = 0;
    const defaultDataPoint = [
      {
        value: 100,
        id: "Inte deltagit",
        color: "#B2B2B2",
      },
    ];
    if (!feedbackArray || !feedbackArray.length > 0 || !group)
      return defaultDataPoint;
    feedbackArray.forEach(({ score }) => {
      // Make a counter of what score each user has left
      if (score >= 1 && score <= 10) mustBeAddressed++;
      if (score >= 11 && score <= 20) shouldImprove++;
      if (score >= 21 && score <= 30) good++;
    });

    const totalParticipants = good + mustBeAddressed + shouldImprove;
    const totalMembers = group.users.length;
    const doughnutDataPoints = [
      {
        value: calculatePercentage(totalMembers, good),
        id: t("meeting_summary.good"),
        color: "#27AE60",
      },
      {
        value: calculatePercentage(totalMembers, shouldImprove),
        id: t("meeting_summary.should_improve"),
        color: "#F2C94C",
      },
      {
        value: calculatePercentage(totalMembers, mustBeAddressed),
        id: t("meeting_summary.must_be_addressed"),
        color: "#EB5757",
      },
      {
        value: 100 - calculatePercentage(totalMembers, totalParticipants),
        id: "Inte deltagit",
        color: "#B2B2B2",
      },
    ];
    const doughnutData = doughnutDataPoints.filter(({ value }) => value > 0);

    return doughnutData;
  };

  const onSeeFeedbackClick = (behaviorId, signification) => {
    history.push({
      pathname: paths.MEETING_SUMMARY,
      state: { dialogId, prevDialogId, behaviorId, signification },
    });
  };

  const feedbackParticipation = (summary) => {
    if (!group || !summary) return 0 + " %";
    const feedbacks = summary.feedback.length;
    const totalUsers = group.users.length;
    return calculatePercentage(totalUsers, feedbacks);
  };

  return (
    <>
      <MenuWrapper />
      <Button
        onClick={() => history.goBack()}
        className="px-7 py-4 mt-12 ml-12  "
        variant="blueWhite"
      >
        {t("generic.back")}
      </Button>
      <div className="flex items-center justify-center mt-20 mb-20">
        <div className="grid grid-cols-12 gap-16 mx-16">
          {data &&
            (significations || []).length > 0 &&
            data.map((item) => {
              const signification = significations.find(
                (s) => s.behavior.description === item.description,
              );
              if (!signification) return null;
              return (
                <div
                  key={item.id}
                  className={
                    "shadow-custom col-span-3 rounded self-start border border-greyFive relative h-639"
                  }
                >
                  <div
                    className={`${setFeedbackBgColorClass(
                      item.feedback,
                    )} px-4 py-4 h-36 border-b border-greyFive
              `}
                  >
                    <p className="mb-3 text-sm font-roboto text-lightGrey leading-4 ">
                      {signification.behavior.value.description}
                    </p>
                    <p className="text-darkBlue mb-2 text-base font-thin leading-4">
                      {item.description}
                    </p>
                    <strong className="text-darkBlue font-bold">
                      {signification.note}
                    </strong>
                  </div>
                  <div className="flex flex-row justify-center mt-10">
                    <p className="text-28 text-darkBlue font-semibold mx-4">
                      {feedbackParticipation(item) + "% "}
                      <span className="text-sm text-lightGrey font-normal">
                        {t("meeting_overview.participants")}
                      </span>
                    </p>
                    <p className="text-28 text-darkBlue font-semibold mx-4">
                      {item.promises_unfulfilled + " "}
                      <span className="text-sm text-lightGrey font-normal">
                        {t("meeting_overview.promises_left")}
                      </span>
                    </p>
                  </div>
                  <ChartLegends />

                  <div className="justify-center items-center flex h-64   ">
                    <Doughnut data={getDoughnutDataPoints(item.feedback)} />
                  </div>
                  <Button
                    disabled={false}
                    className="absolute bottom-4 right-4 pl-8 pr-9 py-3.5 "
                    variant="action"
                    icon="arrow"
                    onClick={() => onSeeFeedbackClick(item.id, signification)}
                  >
                    <p className="font-bold">
                      {t("meeting_overview.see_feedback")}
                    </p>
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MeetingDialogsOverview;
