export const getValuationDialoguesEndpoint = () => ({
  path: "/v1/dialog/",
  method: "GET",
});
export const getValuationDialoguesPromisesEndpoint = (promiseId) => {
  return {
    path: `/v1/dialog/${promiseId}/promise/`,
    method: "GET",
  };
};

export const patchDialoguePromiseComplete = (dialogId, promiseId) => ({
  path: `/v1/dialog/${dialogId}/promise/${promiseId}/`,
  method: "PATCH",
});

export const createValuationDialogEndpoint = () => ({
  path: "/v1/dialog/",
  method: "POST",
});

export const getValuationDialogNotesEndpoint = (dialogId) => ({
  path: `v1/dialog/${dialogId}/note/`,
  method: "GET",
});

export const createValuationDialogNoteEndpoint = (dialogId) => ({
  path: `/v1/dialog/${dialogId}/note/`,
  method: "POST",
});

export const patchValuationDialogNoteEndpoint = (dialogId, noteId) => ({
  path: `/v1/dialog/${dialogId}/note/${noteId}/`,
  method: "PATCH",
});
export const deleteValuationDialogNoteEndpoint = (dialogId, noteId) => ({
  path: `/v1/dialog/${dialogId}/note/${noteId}/`,
  method: "DELETE",
});

export const getDialogueSummary = (dialogId) => ({
  path: `v1/dialog/${dialogId}/summary/`,
  method: "GET",
});

export const getDialogFeedbackList = (dialogId) => ({
  path: `v1/dialog/${dialogId}/feedback/`,
  method: "GET",
});
