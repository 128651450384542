import React, { useState } from "react";
import { useCompany } from "context/company-context";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import Button from "components/button";
import TopBar from "components/topBar";
import PlusIcon from "assets/svg/icons/PlusIcon.svg.js";
import PenIcon from "assets/svg/icons/PenIcon.svg";
import TrashIcon from "assets/svg/icons/TrashIcon.svg";
import PopUp from "components/handleGroupPopup";
import AddGroupMember from "components/addGroupMember";
import { useAuth } from "context/auth-context";
import { useEffect } from "react";
import { getGroupByIdEndpoint } from "api/group";
import { getUsersEndpoint } from "api/user";

import useApi from "hooks/useApi";
import api from "api";

const HandleGroupMembers = () => {
  const { company } = useCompany();
  const { user, selectedGroup } = useAuth();
  const [popupOpen, setPopupOpen] = useState(false);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [editMemberOpen, setEditMemberOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const { t } = useTranslation();

  const { path: groupPath, method: groupMethod } =
    getGroupByIdEndpoint(selectedGroup);

  const [{ data: groupData, status: groupStatus }, makeGroupRequest] = useApi(
    groupPath,
    groupMethod,
  );

  const { path: usersPath, method: usersMethod } = getUsersEndpoint();

  const [{ data: usersData, status: usersStatus }, makeUsersRequest] = useApi(
    usersPath,
    usersMethod,
  );

  const handleClick = (action, user) => {
    if (popupOpen) {
      closePopup();
      return;
    }

    setPopupOpen(true);

    switch (action) {
      case "ADD": {
        setAddMemberOpen(true);
        break;
      }
      default: {
        setEditMemberOpen(true);
        setSelectedUser(user);
        break;
      }
    }
  };

  const onRemoveMember = async (member) => {
    if (!groupData) return;

    if (member.id === user.pk) {
      alert("Du kan inte ta bort dig själv från gruppen!");
      return;
    }

    try {
      const updatedGroup = { ...groupData, manager: groupData.manager.id };
      const users = updatedGroup.users.reduce((state, { id }) => {
        if (!(id === member.id)) state = [...state, id];

        return state;
      }, []);
      updatedGroup.users = users;
      await api.patch(`/v1/group/${groupData.id}/`, updatedGroup);
    } catch {
      console.log(`Failed to remove member with ID ${member.id}`);
    }

    makeGroupRequest();
  };

  const closePopup = () => {
    setAddMemberOpen(false);
    setEditMemberOpen(false);
    setPopupOpen(false);
  };

  useEffect(() => {
    makeGroupRequest();
    makeUsersRequest();
  }, [makeGroupRequest, makeUsersRequest]);

  useEffect(() => {
    if (usersData && groupData && company) {
      if (groupData.users.length > 0) {
        let idSet = new Set(groupData.users.map((obj) => obj["id"]));
        usersData.forEach((user) => {
          if (
            !user.roles.find(
              (role) =>
                (role.type === "employee" && role.company === company) ||
                (role.type === "teamleader" && role.company === company),
            )
          ) {
            idSet.add(user["pk"]);
          }
        });
        setFilteredUsersData(usersData.filter((obj) => !idSet.has(obj.pk)));
      } else {
        setFilteredUsersData(usersData);
      }
    }
  }, [usersData, groupData, setFilteredUsersData, company]);

  if (usersStatus === "FETCHING" || groupStatus === "FETCHING") {
    return <Spinner />;
  }

  return (
    <>
      <TopBar header={t("menu.pre_process.handle_group_members")} />
      <div className="flex justify-center mt-16">
        <div className="px-4 pt-6 border-1-5 border-lighterGrey rounded">
          <Button
            className="flex h-10 px-5 items-center justify-between ml-auto mb-6"
            variant="action"
            onClick={() => setAddMemberOpen(true)}
          >
            <PlusIcon className="mr-4" />
            {t("handle_group_members.new_group_member")}
          </Button>
          {groupData && groupData.users.length > 0 ? (
            groupData.users.map((member, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-center justify-between mb-6"
                >
                  <p className="text-lightGrey w-64 mr-10">
                    {member?.first_name}
                  </p>
                  <p className="text-lightGrey w-80 mr-10">
                    {member?.last_name}
                  </p>
                  {groupData.manager.id === user.pk && (
                    <div className="flex">
                      {member?.first_name !== "x" && member?.last_name !== "x" && (
                        <button onClick={() => handleClick("EDIT", member)}>
                          <PenIcon style={{ marginRight: 16 }} />
                        </button>
                      )}

                      <button onClick={() => onRemoveMember(member)}>
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>

      <PopUp
        onCloseClick={() => closePopup()}
        isOpen={popupOpen}
        isEdit={editMemberOpen}
        user={selectedUser}
        makeGroupRequest={makeGroupRequest}
      />
      <AddGroupMember
        onCloseClick={() => closePopup()}
        isOpen={addMemberOpen}
        refetchMembers={makeGroupRequest}
        members={filteredUsersData}
        groupData={groupData}
      />
    </>
  );
};
export default HandleGroupMembers;
