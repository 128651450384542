import React from "react";
import TopBar from "components/topBar";
import { useCompany } from "context/company-context";
import { paths } from "routing/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/auth-context";
import Button from "components/button";

const ChangeGroupView = () => {
  const { company } = useCompany();
  const { user, selectedGroup, setSelectedGroup } = useAuth();
  const { groups = [] } = user;
  const { t } = useTranslation();
  const history = useHistory();
  const filteredGroups = groups.filter((g) => g.company === company);

  const changeGroup = (groupId) => {
    setSelectedGroup(groupId);
    history.push(paths.OVERVIEW);
  };

  return (
    <>
      <TopBar header={t("generic.choose_group")} />
      <div className="flex justify-center mt-16">
        <div className="px-4 pt-6 border-1-5 border-lighterGrey rounded">
          {filteredGroups.map((group, index) => {
            return (
              <div
                key={index}
                className="flex justify-center justify-between mb-6"
              >
                <p className="text-lightGrey text-lg w-64 mr-10">
                  {group.name}
                </p>

                {group.id === selectedGroup ? (
                  <Button
                    onClick={() => history.push(paths.OVERVIEW)}
                    variant="orangeWhite"
                    className="w-32"
                  >
                    {t("change_group.stay_in_group")}
                  </Button>
                ) : (
                  <Button
                    onClick={() => changeGroup(group.id)}
                    variant="blueWhite"
                    className="w-32"
                  >
                    {t("change_group.choose_group")}
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default ChangeGroupView;
