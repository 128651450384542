import Menu, { Item as MenuItem } from "rc-menu";

const DropDownMenu = ({ data, onSelectMenuItem }) => (
  <Menu onSelect={onSelectMenuItem}>
    {data.map((date, i) => (
      <MenuItem key={i}>{date}</MenuItem>
    ))}
  </Menu>
);

export default DropDownMenu;
