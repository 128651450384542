import { getValuationDialoguesEndpoint } from "api/valuationDialogues";
import { useState, useEffect } from "react";
import { getLastThreeDialogs } from "utils/dateUtils";
import useApi from "./useApi";

export const usePrevDialogs = (count) => {
  const [dialogs, setDialogs] = useState([]);

  const { path, method } = getValuationDialoguesEndpoint();
  const [{ data, status }, makeRequest] = useApi(path, method);

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  useEffect(() => {
    if (status === "SUCCESS") {
      const filteredDialogs = getLastThreeDialogs(data, count);
      setDialogs([...filteredDialogs]);
    }
  }, [status, data, count]);

  return dialogs;
};
