import PlusIcon from "assets/svg/icons/PlusIcon.svg.js";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import { Promise } from "./promise";

export const Promises = ({
  isGroup,
  title,
  data,
  showCreatePromiseBtn,
  onAddGroupPromise,
}) => {
  const { t } = useTranslation();
  const { promises, behaviors, dialogId, prevDialogId } = data;

  const getBehaviorString = (behaviorsId) => {
    if (behaviors.length > 0) {
      return behaviors.find((behaviors) => behaviors.id === behaviorsId)
        .description;
    }
    return "";
  };
  return (
    <div className="border border-greyFive p-6 mb-6 rounded-md max-h-526 flex flex-col overflow-scroll">
      <div className="flex">
        <p className="text-base text-darkBlue font-normal text-3xl mb-5">
          {title}
        </p>
        {showCreatePromiseBtn && (
          <Button
            className="flex h-10 px-5 items-center justify-between  ml-auto"
            variant="action"
            onClick={onAddGroupPromise}
          >
            <PlusIcon className="mr-4" />
            {t("meeting_summary.promise")}
          </Button>
        )}
      </div>
      {promises.map((promise, index) => {
        const behavior = getBehaviorString(promise.behavior);
        return (
          <Promise
            isGroup={isGroup}
            prevDialogId={prevDialogId}
            dialogId={dialogId}
            promise={promise}
            behavior={behavior}
            key={index}
            created_by={promise.created_by}
          />
        );
      })}
    </div>
  );
};
