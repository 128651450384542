import { useTranslation } from "react-i18next";
import { Notes } from "components/notes";
import { Feedback } from "components/feedback";
import { Promises } from "components/promises/promises";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import {
  getValuationDialogNotesEndpoint,
  getDialogFeedbackList,
  getValuationDialoguesPromisesEndpoint,
  getDialogueSummary,
} from "api/valuationDialogues";
import useApi, { axiosMethod } from "hooks/useApi";
import { useState } from "react";
import { getBehaviorsEndpoint } from "api/behavior";
import NotePopup from "components/addNotePopup";
import MenuWrapper from "components/menuWrapper";
import AddGroupPromisePopup from "components/addGroupPromisePopup";
import { getRolesEndpoint } from "api/role";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import constants from "utils/constants";
import { createPromiseEndpoint } from "api/promise";
import StackedBarChart from "components/stackedBarchart";
import ChartLegends from "components/chartLegends";
import Button from "components/button";
import { useHistory } from "react-router-dom";
import { usePrevDialogs } from "hooks/usePrevDialogs";
import { getGroupByIdEndpoint } from "api/group";

const MeetingSummary = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const lastFourDialogs = usePrevDialogs(4);
  const dialogId = location.state?.dialogId || 0;
  const prevDialogId = location.state?.prevDialogId;
  const behaviorId = location.state?.behaviorId;
  const signification = location.state?.signification;
  const [lastFourDialogSummaries, setLastFourDialogSummaries] = useState([]);
  const [promises, setPromises] = useState([]);
  const [prevPromises, setPrevPromises] = useState([]);
  const [behaviors, setBehaviors] = useState([]);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [showGroupPromisePopup, setShowGroupPromisePopup] = useState(false);
  const selectedGroup =
    user && user.groups.length > 0 ? user.groups[0].id : null;

  const { company: companyId } = useCompany();
  const userId = user.pk;
  const { path, method } = getValuationDialoguesPromisesEndpoint(dialogId);
  const {
    path: getPrevDialogPromisesPath,
    method: getPrevDialogPromisesMethod,
  } = getValuationDialoguesPromisesEndpoint(prevDialogId);
  const { path: behaviorPath, method: behaviorMethod } = getBehaviorsEndpoint();
  const { path: dialogNotesPath, method: dialogNotesMethod } =
    getValuationDialogNotesEndpoint(dialogId);
  const { path: dialogFeedbackPath, method: dialogFeedbackMethod } =
    getDialogFeedbackList(dialogId);
  const [{ data, status }, makeRequest] = useApi(path, method);
  const [{ data: behaviorsData, status: behaviorStatus }, makeBehaviorRequest] =
    useApi(behaviorPath, behaviorMethod);
  const [{ data: dialogFeedbackData }, makeDialogFeedbackRequest] = useApi(
    dialogFeedbackPath,
    dialogFeedbackMethod,
  );
  const [
    { data: dialogNotesData, status: dialogNotesStatus },
    makeDialogNotesRequest,
  ] = useApi(dialogNotesPath, dialogNotesMethod);

  const { path: createPromisePath, method: createPromiseMethod } =
    createPromiseEndpoint(dialogId);
  const [{ status: createPromiseStatus }, makeCreatePromiseRequest] = useApi(
    createPromisePath,
    createPromiseMethod,
  );
  const [
    { data: prevPromiseData, status: prevPromisesStatus },
    makePrevPromisesRequest,
  ] = useApi(getPrevDialogPromisesPath, getPrevDialogPromisesMethod);

  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData }, makeRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );
  const { path: groupPath, method: groupMethod } =
    getGroupByIdEndpoint(selectedGroup);

  const [{ data: groupData }, makeGroupRequest] = useApi(
    groupPath,
    groupMethod,
  );

  const role = roleData?.find(
    (role) => role?.user === userId && role?.company?.id === companyId,
  );

  useEffect(() => {
    makeRequest();
    makeBehaviorRequest();
    makeDialogNotesRequest();
    makeDialogFeedbackRequest();
    makeRoleRequest();
    makeGroupRequest();
    if (prevDialogId) {
      makePrevPromisesRequest();
    }
  }, [
    location,
    makeRequest,
    makeBehaviorRequest,
    makeDialogNotesRequest,
    makeDialogFeedbackRequest,
    makeRoleRequest,
    prevDialogId,
    makePrevPromisesRequest,
    makeGroupRequest,
  ]);

  const handleDialogSummaries = useCallback(async () => {
    try {
      const response = await Promise.all(
        lastFourDialogs.map(async (dialog, i) => {
          const { path, method } = getDialogueSummary(dialog.id);
          return axiosMethod(method)(path, method);
        }),
      );
      const filteredResponse = response.map((res) => {
        return res.data.filter(
          (dialogSummary) => dialogSummary.id === behaviorId,
        );
      });
      setLastFourDialogSummaries(filteredResponse);
    } catch (error) {
      console.log({ error });
    }
  }, [lastFourDialogs, behaviorId]);

  useEffect(() => {
    handleDialogSummaries();
  }, [handleDialogSummaries]);

  useEffect(() => {
    if (status === "SUCCESS") {
      setPromises(data);
    }
    if (prevPromisesStatus === "SUCCESS") {
      setPrevPromises(prevPromiseData);
    }

    if (behaviorStatus === "SUCCESS") {
      setBehaviors(behaviorsData);
    }
    if (dialogNotesStatus === "SUCCESS") {
      setNotes(dialogNotesData);
    }
  }, [
    status,
    behaviorStatus,
    dialogNotesStatus,
    dialogNotesData,
    data,
    behaviorsData,
    prevPromisesStatus,
    prevPromiseData,
  ]);

  useEffect(() => {
    if (createPromiseStatus === "SUCCESS") {
      makeRequest();
    }

    return () => {};
  }, [createPromiseStatus, makeRequest]);

  useEffect(() => {
    return () => {};
  }, [promises]);

  const getGroupPromises = (promises) => {
    if (!promises || (promises.length < 1 && prevPromises.length < 1))
      return [];

    if (promises) {
      return promises.filter((promise) => promise.group);
    }
  };

  const individualPromises =
    promises.length > 0
      ? promises.filter(
          (promise) => !promise.group && promise.behavior === behaviorId,
        )
      : [];
  const groupPromises = getGroupPromises(promises);

  const createGroupPromise = (promise, selectedBehavior, nextDialogId) => {
    if (!promise.length > 0) {
      alert("Fältet Löfte får inte vara tomt");
    }
    if (!selectedBehavior.length > 0) {
      alert("Beteende får inte vara tomt");
    }
    if (!nextDialogId) {
      alert(
        "Vänligen planera in en ny värderingsdialog för att kunna skapa nya grupplöften”",
      );
      return;
    }
    const behaviorId = behaviors.find(
      (b) => b.description === selectedBehavior,
    ).id;
    makeCreatePromiseRequest({
      group: true,
      text: promise,
      behavior: behaviorId,
    });
    makeRequest();
    setShowGroupPromisePopup(false);
  };

  return (
    <div>
      <MenuWrapper />
      <Button
        onClick={() => history.goBack()}
        className="px-7	py-4 mt-12 ml-12"
        variant="blueWhite"
      >
        {t("generic.back")}
      </Button>
      <div className="flex justify-center flex-col w-full h-fit">
        <p className="text-base leading-100 text-darkBlue font-normal text-3xl text-center w-full py-2">
          {signification && signification.behavior.description}
        </p>
        <p className="text-base leading-100 text-darkBlue font-normal text-lg text-center w-full">
          {signification && signification.note}
        </p>
      </div>
      <div className="grid grid-cols-12 gap-6 m-8">
        <div className="col-span-3 self-start">
          <div className="border border-greyFive ">
            <div className=" pt-8 mb-8">
              <h2 className="text-2xl text-darkBlue pl-8">
                {t("meeting_summary.tendency")}
              </h2>
              <div className="mt-6">
                <p className="text-center text-3xl text-darkBlue">
                  0%{" "}
                  <span className="text-sm text-lightGrey">
                    {" "}
                    {t("meeting_summary.participation")}
                  </span>
                </p>
                <ChartLegends />
              </div>
            </div>
            <div className="flex flex-row justify-between align-center overflow-x-scroll overflow-y-hidden h-80">
              <StackedBarChart
                groupData={groupData}
                lastFourDialogs={lastFourDialogs}
                lastFourDialogSummaries={lastFourDialogSummaries}
              />
            </div>
          </div>
          <Notes
            onAddNoteClick={() => {
              setSelectedNote(null);
              setShowNotePopup(true);
              window.scrollTo(0, 0);
            }}
            onEditClick={(note) => {
              setShowNotePopup(true);
              setSelectedNote(note);
              window.scrollTo(0, 0);
            }}
            notes={notes}
            dialogId={dialogId}
            refetchNotes={makeDialogNotesRequest}
          />
        </div>
        <div className="col-span-6 self-start">
          <Feedback
            groupFeedback={
              dialogFeedbackData
                ? dialogFeedbackData.filter(
                    (feedback) => feedback.behavior === behaviorId,
                  )
                : []
            }
          />
        </div>
        <div className="col-span-3 self-start">
          <Promises
            isGroup={true}
            onAddGroupPromise={() => setShowGroupPromisePopup(true)}
            data={{
              promises: groupPromises,
              behaviors,
              dialogId,
              prevDialogId,
            }}
            title={t("meeting_summary.group_promises")}
            showCreatePromiseBtn={role && role.type === constants.TEAMLEADER}
          />
          <Promises
            isGroup={false}
            data={{ promises: individualPromises, behaviors, dialogId }}
            title={t("meeting_summary.individual_promises")}
          />
        </div>
      </div>

      <AddGroupPromisePopup
        onCreatePromise={createGroupPromise}
        dialogId={dialogId}
        isChecked={false}
        showPopup={showGroupPromisePopup}
        onCloseClick={() => {
          setShowGroupPromisePopup(false);
        }}
      />
      <NotePopup
        showPopup={showNotePopup}
        onCloseClick={() => {
          setShowNotePopup(false);
        }}
        setShowNotePopup={setShowNotePopup}
        note={selectedNote}
        dialogId={dialogId}
        refetchNotes={makeDialogNotesRequest}
      />
    </div>
  );
};

export default MeetingSummary;
