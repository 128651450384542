import { getFeedbackStats } from "utils/numbers";
import StackedBar from "./bar";

const StackedBarChart = ({
  groupData,
  lastFourDialogs,
  lastFourDialogSummaries,
}) => {
  return (
    <div className="w-9	h-full flex justify-between">
      {groupData &&
        lastFourDialogSummaries &&
        lastFourDialogSummaries.map((dialog, i) => {
          let goodColor = "#27AE60";
          let needsImprovementColor = "#F2C94C";
          let mustBeAddressedColor = "#EB5757";
          let noResponseColor = "#CFD8DC";

          const feedbackStats = getFeedbackStats(
            groupData.users.length,
            dialog,
          );

          return (
            <div className="mx-4 mb-20" key={i}>
              <StackedBar
                topRounded
                bottomRounded={
                  feedbackStats.shouldImprove === 0 &&
                  feedbackStats.mustBeAddressed === 0
                }
                percent={`${feedbackStats.good}%`}
                bgColor={goodColor}
              />
              <StackedBar
                topRounded={feedbackStats.good === 0}
                bottomRounded={feedbackStats.mustBeAddressed === 0}
                percent={`${feedbackStats.shouldImprove}%`}
                bgColor={needsImprovementColor}
              />
              <StackedBar
                topRounded={
                  feedbackStats.shouldImprove === 0 && feedbackStats.good === 0
                }
                bottomRounded={feedbackStats.didntAnswer === 0}
                percent={`${feedbackStats.mustBeAddressed}%`}
                bgColor={mustBeAddressedColor}
              />
              <StackedBar
                topRounded={feedbackStats.didntAnswer === 100}
                bottomRounded
                percent={`${feedbackStats.didntAnswer}%`}
                bgColor={noResponseColor}
              />
              <p className="text-xs w-20 text-center mt-5 text-lightGrey">
                {lastFourDialogs[i].start_date.substring(0, 10)}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default StackedBarChart;
