const StepIndicator = ({ amount, selected }) => {
  const getClassName = (index, selected) => {
    if (index === selected) {
      return "bg-tirkiz h-2 w-2/3 rounded-3xl mx-1";
    } else {
      return `bg-coldBlue h-2 w-1/4 rounded-3xl mx-1`;
    }
  };

  var rows = [];
  for (var i = 0; i < amount; i++) {
    rows.push(<span key={i} className={getClassName(selected, i)}></span>);
  }

  return (
    <div className="w-full">
      <div className="w-40 h-2 flex flex-row mx-auto">{rows}</div>
    </div>
  );
};

export default StepIndicator;
