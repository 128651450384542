export const StorageKeys = {
  COMPANY_LANGUAGE_PATH: "company_language_path",
};

export const getCompanyLanguagePath = () =>
  localStorage.getItem(StorageKeys.COMPANY_LANGUAGE_PATH);
export const setCompanyLanguagePath = (language_path) =>
  localStorage.setItem(StorageKeys.COMPANY_LANGUAGE_PATH, language_path);
export const clearCompanyLanguagePath = () =>
  localStorage.removeItem(StorageKeys.COMPANY_LANGUAGE_PATH);
