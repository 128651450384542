export const createValueEndpoint = () => ({
  path: "/v1/value/",
  method: "POST",
});

export const getValuesEndpoint = () => ({
  path: "/v1/value/",
  method: "GET",
});

export const getValueEndpoint = (valueId) => ({
  path: `/v1/value/${valueId}/`,
  method: "GET",
});

export const deleteValueEndpoint = (valueId) => ({
  path: `/v1/value/${valueId}/`,
  method: "DELETE",
});
