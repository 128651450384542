import { getBehaviorsEndpoint } from "api/behavior";
import { getValuationDialoguesEndpoint } from "api/valuationDialogues";
import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg.js";
import Button from "components/button";
import DropDown from "components/dropDown";
import DropDownMenu from "components/dropDown/dropDownMenu";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AddGroupPromisePopup = ({
  showPopup,
  onCloseClick,
  onCreatePromise,
  dialogId,
}) => {
  const { path, method } = getBehaviorsEndpoint();
  const { path: dialogsPath, method: dialogMethod } =
    getValuationDialoguesEndpoint();

  const [{ data: dialogData, status: dialogStatus }, makeDialogRequest] =
    useApi(dialogsPath, dialogMethod);
  const [{ data, status }, makeBehaviorRequest] = useApi(path, method);
  const [behaviors, setBehaviors] = useState([]);
  const [selectedBehavior, setSelectedBehavior] = useState("");
  const [promise, setPromise] = useState("");
  const [nextDialogId, setNextDialogId] = useState(null);
  useEffect(() => {
    makeBehaviorRequest();
    makeDialogRequest();
  }, [makeBehaviorRequest, makeDialogRequest]);

  useEffect(() => {
    if (
      dialogStatus === "SUCCESS" &&
      Array.isArray(dialogData) &&
      dialogData.length > 0
    ) {
      const sortedDialogData = [...dialogData].sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date),
      );
      const index = sortedDialogData.findIndex(
        (dialog) => dialog.id === dialogId,
      );
      let nextDialogId = null;
      if (typeof sortedDialogData[index + 1] !== "undefined") {
        // does  exist
        nextDialogId = sortedDialogData[index + 1].id;
        setNextDialogId(nextDialogId);
      }
    }
  }, [dialogData, dialogStatus, dialogId]);

  useEffect(() => {
    if (status === "SUCCESS") {
      if (Array.isArray(data) && data.length < 1) return;
      setBehaviors(
        data.map(({ description, id }) => ({
          description,
          id,
        })),
      );
    }
    return () => {};
  }, [data, status]);

  const onChange = (event) => {
    setPromise(event.target.value);
  };

  const { t } = useTranslation();
  if (showPopup)
    return (
      <div className="absolute w-screen h-screen inset-0 flex justify-center items-center">
        <div className="relative flex flex-col w-96 shadow-popUp rounded pt-11 px-4 pb-6 bg-white">
          <RoundedCloseIcon
            onClick={onCloseClick}
            className="absolute top-4 right-2"
          />
          <h2 className="mb-6 text-2xl text-darkBlue">
            {t("meeting_summary.new_group_promise")}
          </h2>
          <label htmlFor="label" className="mb-2 text-base text-grey">
            {t("meeting_summary.behavior")}:
          </label>
          <DropDown
            overlay={
              <DropDownMenu
                data={
                  behaviors.length > 0
                    ? behaviors.map(({ description }) => description)
                    : []
                }
                onSelectMenuItem={(key) =>
                  setSelectedBehavior(key.item.props.children[0])
                }
              />
            }
            hideIcon
            label={
              selectedBehavior.length > 0
                ? selectedBehavior
                : t("meeting_summary.choose_alternative")
            }
          />
          <label htmlFor="label" className="mt-6 mb-2 text-base text-grey">
            {t("meeting_summary.promise")}:
          </label>
          <textarea
            className="border border-greyFive p-4  h-28 text-justify"
            type="text"
            onChange={onChange}
            value={promise}
          />
          <Button
            className="h-12 pl-5 pr-5 self-end mt-4"
            variant="action"
            onClick={() => {
              onCreatePromise(promise, selectedBehavior, nextDialogId);
              setPromise("");
              setSelectedBehavior("");
            }}>
            {t("handle_group_members.save")}
          </Button>
        </div>
      </div>
    );
  return null;
};

export default AddGroupPromisePopup;
