// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { Pie } from "@nivo/pie";
import useWindowDimensions from "utils/dimensions";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const Doughnut = ({ data /* see data tab */ }) => {
  const { width } = useWindowDimensions();
  let pieDimensions = width < 1700 ? 350 : 510;
  let pieMargins =
    width < 1700
      ? { top: 100, right: 0, bottom: 100, left: 0 }
      : { top: 170, right: 10, bottom: 150, left: 10 };

  return (
    <Pie
      data={data}
      margin={pieMargins}
      width={pieDimensions}
      height={pieDimensions}
      innerRadius={0.5}
      arcLinkLabelsStraightLength={10}
      enableArcLabels={false}
      arcLinkLabelsTextColor="#193557"
      arcLinkLabelsThickness={2}
      startAngle={45}
      endAngle={405}
      arcLinkLabelsColor={"#CFD8DC"}
      arcLinkLabel={function (e) {
        return e.value + "%";
      }}
      colors={{ datum: "data.color" }}
    />
  );
};

export default Doughnut;
