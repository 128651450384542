import HTTP from "api";

export const getGroupByIdEndpoint = (groupId) => ({
  path: `/v1/group/${groupId}`,
  method: "GET",
});

export const addMemberEndpoint = (groupId) => ({
  path: `/v1/group/${groupId}`,
  method: "PUT",
});

export const removeMemberEndpoint = (groupId) => ({
  path: `/v1/group/${groupId}`,
  method: "PATCH",
});

export const getGroupConsequencesEndpoint = () => ({
  path: "/v1/group-consequence/",
  method: "GET",
});

export const createGroupConsequencesEndpoint = () => ({
  path: "/v1/group-consequence/",
  method: "POST",
});

export const getGroup = (groupId) => HTTP.get(`/v1/group/${groupId}/`);

export const getGroupsEndpoint = () => ({
  path: "/v1/group/",
  method: "GET",
});
