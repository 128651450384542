import Signup from "views/signup";
import Login from "views/login";
import Logout from "views/logout";
import { ForgotPassword, ForgotPasswordConfirm } from "views/forgotPassword";
import Home from "views/home";
import Overview from "views/overview";
import OrganizationOverview from "views/organizationOverview";
import Vision from "views/vision";
import Value from "views/value";
import Behavior from "views/behavior";
import Consequence from "views/consequence";
import BehaviorStakeholder from "views/behaviorStakeholder";
import ConsequenceStakeholder from "views/consequenceStakeholder";
import BehaviorTeamleader from "views/behaviorTeamleader";
import ConsequenceTeamleader from "views/consequenceTeamleader";
import OrganizationPrintoutLandscape from "views/printouts/organizationPrintoutLandscape";
import OrganizationPrintout from "views/printouts/organizationPrintout";
import StakeholderPrintout from "views/printouts/stakeholderPrintout";
import GroupPrintout from "views/printouts/groupPrintout";
import StakeholderIndividualPrintout from "views/printouts/stakeholderIndividualPrintout";
import GroupIndividualPrintout from "views/printouts/groupIndividualPrintout";
import ValuationDialogue from "views/valuationDialogue";
import MeetingSummary from "views/meetingSummary";
import HandleGroupMembers from "views/handleGroupMembers";
import MeetingDialogsOverview from "views/meetingDialogsOverview";
import ChangeGroupView from "views/changeGroup";
import HandleGroups from "views/handleGroups";
import HandleUsers from "views/handleUsers";
import UserSettings from "views/userSettings";

const SIGNUP = "/signup";
const LOGIN = "/login";
const LOGOUT = "/logout";
const FORGOT_PASSWORD = "/forgot-password";
const FORGOT_PASSWORD_CONFIRM = "/forgot-password/:token";
const HOME = "/home";
const OVERVIEW = "/overview";
const ORGANIZATION_OVERVIEW = "/organization-overview";
const VISION = "/vision";
const VALUE = "/values";
const BEHAVIOR = "/behaviors";
const CONSEQUENCE = "/consequences";
const BEHAVIOR_STAKEHOLDER = "/behaviors-stakeholder";
const CONSEQUENCE_STAKEHOLDER = "/consequences-stakeholder";
const BEHAVIOR_TEAMLEADER = "/behaviors-teamleader";
const CONSEQUENCE_TEAMLEADER = "/consequences-teamleader";
const ORGANIZATION_PRINTOUT_LANDSCAPE = "/organization-printout-landscape";
const ORGANIZATION_PRINTOUT = "/organization-printout";
const STAKEHOLDER_PRINTOUT = "/stakeholder-printout";
const GROUP_PRINTOUT = "/group-printout";
const STAKEHOLDER_INDIVIDUAL_PRINTOUT = "/stakeholder-individual-printout";
const GROUP_INDIVIDUAL_PRINTOUT = "/group-individual-printout";
const VALUATION_DIALOGUE = "/valuation-dialogue";
const MEETING_SUMMARY = "/meeting-summary";
const HANDLE_GROUP_MEMBERS = "/handle-group-members";
const HANDLE_GROUPS = "/handle-groups";
const HANDLE_USERS = "/handle-users";
const USER_SETTINGS = "/user-settings";
const CHANGE_GROUP = "/change-group";
const MEETING_DIALOGS_OVERVIEW = "/meeting-dialogs-overview";

const routes = [
  {
    path: SIGNUP,
    exact: true,
    main: Signup,
    isPrivate: false,
  },
  {
    path: LOGIN,
    exact: true,
    main: Login,
    isPrivate: false,
  },
  {
    path: LOGOUT,
    exact: true,
    main: Logout,
    isPrivate: true,
  },
  {
    path: FORGOT_PASSWORD,
    exact: true,
    main: ForgotPassword,
    isPrivate: false,
  },
  {
    path: FORGOT_PASSWORD_CONFIRM,
    exact: true,
    main: ForgotPasswordConfirm,
    isPrivate: false,
  },
  {
    path: HOME,
    exact: true,
    main: Home,
    isPrivate: true,
  },
  {
    path: OVERVIEW,
    exact: true,
    main: Overview,
    isPrivate: true,
  },
  {
    path: ORGANIZATION_OVERVIEW,
    exact: true,
    main: OrganizationOverview,
    isPrivate: true,
  },
  {
    path: VISION,
    exact: true,
    main: Vision,
    isPrivate: true,
  },
  {
    path: VALUE,
    exact: true,
    main: Value,
    isPrivate: true,
  },
  {
    path: BEHAVIOR,
    exact: true,
    main: Behavior,
    isPrivate: true,
  },
  {
    path: CONSEQUENCE,
    exact: true,
    main: Consequence,
    isPrivate: true,
  },
  {
    path: BEHAVIOR_STAKEHOLDER,
    exact: true,
    main: BehaviorStakeholder,
    isPrivate: true,
  },
  {
    path: CONSEQUENCE_STAKEHOLDER,
    exact: true,
    main: ConsequenceStakeholder,
    isPrivate: true,
  },
  {
    path: BEHAVIOR_TEAMLEADER,
    exact: true,
    main: BehaviorTeamleader,
    isPrivate: true,
  },
  {
    path: CONSEQUENCE_TEAMLEADER,
    exact: true,
    main: ConsequenceTeamleader,
    isPrivate: true,
  },
  {
    path: ORGANIZATION_PRINTOUT_LANDSCAPE,
    exact: true,
    main: OrganizationPrintoutLandscape,
    isPrivate: true,
  },
  {
    path: ORGANIZATION_PRINTOUT,
    exact: true,
    main: OrganizationPrintout,
    isPrivate: true,
  },
  {
    path: STAKEHOLDER_PRINTOUT,
    exact: true,
    main: StakeholderPrintout,
    isPrivate: true,
  },
  {
    path: GROUP_PRINTOUT,
    exact: true,
    main: GroupPrintout,
    isPrivate: true,
  },
  {
    path: STAKEHOLDER_INDIVIDUAL_PRINTOUT,
    exact: true,
    main: StakeholderIndividualPrintout,
    isPrivate: true,
  },
  {
    path: GROUP_INDIVIDUAL_PRINTOUT,
    exact: true,
    main: GroupIndividualPrintout,
    isPrivate: true,
  },
  {
    path: VALUATION_DIALOGUE,
    exact: true,
    main: ValuationDialogue,
    isPrivate: true,
  },
  {
    path: MEETING_SUMMARY,
    exact: true,
    main: MeetingSummary,
    isPrivate: true,
  },
  {
    path: HANDLE_GROUP_MEMBERS,
    exact: true,
    main: HandleGroupMembers,
    isPrivate: true,
  },
  {
    path: MEETING_DIALOGS_OVERVIEW,
    exact: true,
    main: MeetingDialogsOverview,
    isPrivate: true,
  },
  {
    path: CHANGE_GROUP,
    exact: true,
    main: ChangeGroupView,
    isPrivate: true,
  },
  {
    path: HANDLE_GROUPS,
    exact: true,
    main: HandleGroups,
    isPrivate: true,
  },
  {
    path: HANDLE_USERS,
    exact: true,
    main: HandleUsers,
    isPrivate: true,
  },
  {
    path: USER_SETTINGS,
    exact: true,
    main: UserSettings,
    isPrivate: true,
  },
];

export const paths = {
  LOGIN,
  HOME,
  LOGOUT,
  VISION,
  VALUE,
  BEHAVIOR,
  CONSEQUENCE,
  OVERVIEW,
  ORGANIZATION_OVERVIEW,
  SIGNUP,
  BEHAVIOR_STAKEHOLDER,
  CONSEQUENCE_STAKEHOLDER,
  BEHAVIOR_TEAMLEADER,
  CONSEQUENCE_TEAMLEADER,
  VALUATION_DIALOGUE,
  MEETING_SUMMARY,
  HANDLE_GROUP_MEMBERS,
  MEETING_DIALOGS_OVERVIEW,
  CHANGE_GROUP,
  HANDLE_GROUPS,
  HANDLE_USERS,
  USER_SETTINGS,
};

export default routes;
