import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import routes, { paths } from "routing/routes";
import PrivateRoute from "routing/privateRoutes";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ path, exact, main, isPrivate }) => {
          if (isPrivate) {
            return (
              <PrivateRoute
                key={path}
                path={path}
                exact={exact}
                component={main}
              />
            );
          }
          return (
            <Route key={path} path={path} exact={exact} component={main} />
          );
        })}

        <Route path="*">
          <Redirect to={paths.OVERVIEW} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
