import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { createValueEndpoint, getValuesEndpoint } from "api/value";
import { transitionCompanyToBehaviors } from "api/company";
import useApi from "hooks/useApi";
import api from "api";
import { paths } from "routing/routes";
import { useCompany } from "context/company-context";
import Spinner from "components/spinner";
import Button from "components/button";
import Header from "components/header";
import FrameWrapper from "components/frameWrapper";
import { Grid, GridLeftContent } from "components/grid";
import visionBgImage from "assets/img/backgrounds/vision.jpg";
import { valueIcon } from "assets/svg/icons";
import { valueBg } from "assets/svg/backgrounds";
// import veidekkeLogo from "assets/img/logos/veidekke.jpg";
import { changeLocalCompanyState } from "utils/changeLocalCompanyState";
import { smartGoalLogo } from "assets/svg/logos";

const DESCRIPTION_FIELD_MAXLENGTH = 40;
const PURPOSE_FIELD_MAXLENGTH = 350;

const Value = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { company, isLoading, setCompanyState, companyLogo } = useCompany();
  const { path, method } = getValuesEndpoint();
  const { path: createPath, method: createMethod } = createValueEndpoint();
  const [{ status: createStatus }, createMakeRequest] = useApi(
    createPath,
    createMethod,
  );
  const [{ data, status }, makeRequest] = useApi(path, method);

  const { path: transPath, method: transMethod } =
    transitionCompanyToBehaviors(company);

  const [{ data: companyData, status: stateStatus }, transitionToBehaviors] =
    useApi(transPath, transMethod);

  const [formValues, setFormValues] = useState({});
  const [selectedFrame, setSelectedFrame] = useState(null);

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  useEffect(() => {
    if (createStatus === "SUCCESS") {
      makeRequest();
    }
  }, [createStatus, makeRequest]);

  const onSubmit = async ({ description, purpose, id }) => {
    // If ID we are editing existing resource
    if (id) {
      await api.patch(`/v1/value/${id}/`, { description, purpose });
      setSelectedFrame(null);
      makeRequest();
    } else {
      createMakeRequest({ description, purpose, company });
    }
    setFormValues({});
  };

  const onDelete = async (id) => {
    try {
      await api.delete(`/v1/value/${id}/`);
    } catch {
      // TODO: Implement proper error handling here.
      console.log(`Failed to delete value with ID ${id}`);
    }
    setSelectedFrame(null);
    setFormValues({});
    makeRequest();
  };

  const onEdit = ({ id, description, purpose }) => {
    setFormValues({ id, description, purpose });
    setSelectedFrame(id);
  };

  useEffect(() => {
    changeLocalCompanyState(stateStatus, companyData, setCompanyState);
  }, [stateStatus, companyData, setCompanyState]);

  const nextStep = () => {
    if (selectedFrame !== null) return;

    transitionToBehaviors();
    history.push(paths.BEHAVIOR);
  };

  if (isLoading || status === "FETCHING") return <Spinner />;

  const fieldClassName =
    "w-full flex border p-4 border-ligtherGrey resize-none";
  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={valueIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("value.header")}
          text={t("value.description_text")}
          example_title={t("value.example.title")}
          example_header={t("value.example.header")}
          example_text={t("value.example.text")}
        ></GridLeftContent>
      }
      right={
        <div className="w-full flex flex-col">
          <Header
            amountStep={4}
            currentStep={1}
            backOnClick={() => {
              history.push(paths.VISION);
            }}
          />
          <div className="flex flex-row h-full  mt-10">
            <div className="flex flex-col ml-8 w-2/5 p-8 h-5/6 border border-greyFive">
              <Form
                initialValues={formValues}
                validate={(values) => {
                  const errors = {};
                  if (!values.description) {
                    errors.description = "Required";
                  }
                  if (!values.purpose) {
                    errors.purpose = "Required";
                  }
                  return errors;
                }}
                onSubmit={onSubmit}
                render={({
                  handleSubmit,
                  submitting,
                  hasValidationErrors,
                  values,
                }) => (
                  <form
                    className="flex flex-col h-full w-full"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex-col py-4">
                      <label className="flex text-xl pb-4">
                        {t("value.form.description")}
                      </label>
                      <Field
                        name="description"
                        type="text"
                        component="input"
                        placeholder={t("value.form.write_here")}
                      >
                        {({ input }) => (
                          <div className="relative">
                            <input
                              maxLength={DESCRIPTION_FIELD_MAXLENGTH}
                              className={fieldClassName}
                              disabled={selectedFrame !== null}
                              {...input}
                            />
                            <span className="absolute right-2 top-1 text-xs">
                              {(values.description || "").length} /
                              {DESCRIPTION_FIELD_MAXLENGTH}
                            </span>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex flex-col h-full py-4">
                      <label className="flex text-xl pb-4">
                        {t("value.form.purpose")}
                      </label>
                      <Field
                        name="purpose"
                        type="textarea"
                        component="textarea"
                        placeholder={t("value.form.write_here")}
                        className={`${fieldClassName} h-5/6`}
                        maxLength={PURPOSE_FIELD_MAXLENGTH}
                      />
                      <div className="flex justify-end pt-1">
                        {(values.purpose || "").length}/{" "}
                        {PURPOSE_FIELD_MAXLENGTH}
                      </div>
                    </div>
                    <Field name="id" component="input" type="hidden" />
                    <div className="flex mt-10">
                      <Button
                        disabled={submitting || hasValidationErrors}
                        className="h-10 w-2/6 mr-auto"
                        type="submit"
                        variant="default"
                      >
                        {selectedFrame
                          ? t("generic.save")
                          : t("value.form.submit")}
                      </Button>

                      {selectedFrame && (
                        <Button
                          className="h-10 w-2/6 ml-auto"
                          variant="blueWhite"
                          onClick={() => {
                            setSelectedFrame(null);
                            setFormValues({});
                          }}
                        >
                          {t("generic.cancel")}
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              />
            </div>
            <div className="flex flex-col w-2/3 ml-10 mr-2">
              <FrameWrapper
                frames={data}
                selectedFrame={selectedFrame}
                bgImage={valueBg}
                icon={valueIcon}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <Button
              disabled={
                data ? data.length === 0 || selectedFrame !== null : true
              }
              className="h-12 mb-6 w-1/3 mx-auto"
              variant="default"
              icon="arrow"
              iconColor="white"
              onClick={nextStep}
            >
              {t("value.navigate_next")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default Value;
