import React from "react";
import TopBar from "components/topBar";

import { useTranslation } from "react-i18next";

const MenuWrapper = ({ children }) => {
  const { t } = useTranslation();

  return <TopBar header={t("handle_group.value_dialogue")} />;
};

export default MenuWrapper;
