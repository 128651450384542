import * as React from "react";

const GreenCheck = (props) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 14A7 7 0 1 1 7-.001 7 7 0 0 1 7 14ZM7 1a6 6 0 1 0 0 12A6 6 0 0 0 7 1Z"
      fill="#55CF77"
    />
    <path
      d="M5.69 10.5h-.025a.5.5 0 0 1-.39-.22l-2.19-3.23a.5.5 0 0 1 .83-.55L5.72 9.17l4.39-5.5a.5.5 0 1 1 .78.63l-4.81 6a.501.501 0 0 1-.39.2Z"
      fill="#55CF77"
    />
  </svg>
);

export default GreenCheck;
