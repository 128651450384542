import styled from "styled-components";
import { MenuButton } from "assets/svg/buttons";
import { CloseButton } from "assets/svg/buttons";

export const StyledMenuButton = styled(MenuButton)`
  position: absolute;
  width: 55px;
  height: 55px;
  top: 32px;
  right: 20px;
  cursor: pointer;
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  width: 55px;
  height: 55px;
  top: 32px;
  right: 20px;
  cursor: pointer;
`;

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;

  width: 343px;
  height: 100%;
  padding: 50px 50px;
  background-color: white;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.25);
`;

export const StyledMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const StyledMenuHeader = styled.p`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #61666c;
  margin-top: 36px;
  margin-bottom: 8px;
`;

export const StyledMenuItem = styled.a`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  margin: 4px 0;
  cursor: pointer;
  color: #193557;
`;

export const StyledMenuItemInactive = styled.a`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  margin: 4px 0;
  color: #cfd8dc;
  cursor: not-allowed;
`;

export const StyledMenuFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17.5px 16px 16px;

  width: 343px;
  height: 57.5px;
  box-shadow: inset 0px 1.52px 0px #f2f2f2;
  position: absolute;
  bottom: 0;
`;

export const StyledCopyrightSymbol = styled.div`
  font-weight: 900;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  text-align: center;
  color: #193557;
  border: 3px solid #193557;
  border-radius: 50%;
`;

export const StyledPrintoutIframe = styled.iframe`
  display: none;
`;
