import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "routing/routes";

import Button from "components/button";
import StepIndicator from "components/stepIndicator";
import Menu from "components/menu";

const Header = ({
  amountStep,
  currentStep,
  backOnClick,
  showOnlyMenu = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="w-full h-12 mt-8 flex flex-row">
      {!showOnlyMenu && (
        <>
          {backOnClick && (
            <Button
              onClick={backOnClick}
              className="h-12 w-32 ml-8"
              variant="orangeWhite"
            >
              {t("generic.back")}
            </Button>
          )}
          <StepIndicator amount={amountStep} selected={currentStep} />
          <Button
            onClick={() => {
              history.push(paths.OVERVIEW);
            }}
            className="h-12 w-48 mr-24"
            variant="blueWhite"
          >
            {t("generic.to_overview")}
          </Button>
        </>
      )}

      <Menu />
    </div>
  );
};

export default Header;
