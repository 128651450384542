import { useEffect, useState, useCallback } from "react";
import { getGroup } from "api/group";
import { useAuth } from "context/auth-context";

export const useGroup = () => {
  const [group, setGroup] = useState({});
  const { selectedGroup } = useAuth();

  const fetch = useCallback(async (selectedGroup) => {
    try {
      const { data } = await getGroup(selectedGroup);
      setGroup(data);
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  useEffect(() => {
    fetch(selectedGroup);
  }, [fetch, selectedGroup]);

  return {
    group,
  };
};
