import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg";
import Button from "components/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import Select from "react-select";
import api from "api";

const AddGroupMember = ({
  isOpen = false,
  onCloseClick,
  refetchMembers,
  members,
  groupData,
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const options = members.map((member) => {
    return { value: member.pk, label: member.email };
  });

  const addUser = async () => {
    if (!groupData) return;
    try {
      const updatedGroup = { ...groupData, manager: groupData.manager.id };
      let memberIds = [];
      memberIds = groupData.users.map((user) => user.id);
      const newIds = selectedOptions.map((option) => option.value);
      memberIds = memberIds.concat(newIds);
      updatedGroup.users = memberIds;
      await api.patch(`/v1/group/${groupData.id}/`, updatedGroup);
    } catch {
      console.log(`Failed to add members with ID ${selectedOptions}`);
    }
    onCloseClick();
    refetchMembers();
  };

  if (!isOpen) return <></>;

  return (
    <div className="absolute w-screen top-0 flex mt-56 justify-center">
      <div className="relative flex flex-col  bg-white shadow-popUp rounded px-4 pt-12 w-4/12">
        <RoundedCloseIcon
          onClick={() => {
            onCloseClick();
          }}
          className="absolute top-4 right-2 "
        />
        <h3 className="text-2xl text-darkBlue mb-6">
          {t("handle_group_members.new_group_member")}
        </h3>
        <Select
          options={options}
          placeholder={t("handle_group_members.choose_member")}
          isMulti={true}
          onChange={setSelectedOptions}
        />

        <Button
          className="h-12 pl-5 pr-5 self-end mt-4 mb-6"
          variant="action"
          onClick={() => {
            addUser();
          }}
          disabled={selectedOptions.length === 0}
        >
          {t("handle_group_members.save")}
        </Button>
      </div>
    </div>
  );
};

export default AddGroupMember;
