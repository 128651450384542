import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import api from "api";
import {
  getGroupConsequencesEndpoint,
  createGroupConsequencesEndpoint,
} from "api/group";

import { getRolesEndpoint } from "api/role";
import useApi from "hooks/useApi";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import { paths } from "routing/routes";
import ConsequenceForm from "./consequenceForm";
import Button from "components/button";
import Header from "components/header";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";
import { Grid, GridLeftContent } from "components/grid";
import visionBgImage from "assets/img/backgrounds/vision.jpg";
import { consequenceIcon } from "assets/svg/icons";
import { consequenceBg } from "assets/svg/backgrounds";
import { smartGoalLogo } from "assets/svg/logos";

const ConsequenceTeamleader = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // Context for user / company
  const { user } = useAuth();
  const { company, isLoading, companyLogo } = useCompany();

  // Get consequences endpoints
  const { path: getConsequencePath, method: getConsequenceMethod } =
    getGroupConsequencesEndpoint();
  const [
    { data: consequences, status: getConsequenceStatus },
    makeGetConsequenceRequest,
  ] = useApi(getConsequencePath, getConsequenceMethod);

  // Create consequence endpoints
  const { path: postConsequencePath, method: postConsequenceMethod } =
    createGroupConsequencesEndpoint();
  const [{ status: postConsequenceStatus }, makePostConsequenceRequest] =
    useApi(postConsequencePath, postConsequenceMethod);

  // Get role data
  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData, status: roleStatus }, makeRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );

  const userId = user.pk;
  const role = roleData?.find((role) => role?.user === userId);

  const [formValues, setFormValues] = useState({});
  const [selectedFrame, setSelectedFrame] = useState(null);

  useEffect(() => {
    makeGetConsequenceRequest();
    makeRoleRequest();
  }, [makeGetConsequenceRequest, makeRoleRequest]);

  useEffect(() => {
    if (postConsequenceStatus === "SUCCESS") {
      makeGetConsequenceRequest();
    }
  }, [postConsequenceStatus, makeGetConsequenceRequest]);

  const onDelete = async (id) => {
    try {
      await api.delete(`/v1/group-consequence/${id}/`);
    } catch {
      // TODO: Implement proper error handling here.
      console.log(`Failed to delete consequence with ID ${id}`);
    }
    reFetch();
  };

  const onEdit = (consequence) => {
    const { id, description, purpose, type } = consequence;
    setFormValues({ id, description, purpose, type });
    setSelectedFrame(consequence);
  };

  const reFetch = () => {
    makeGetConsequenceRequest();
  };

  if (
    isLoading ||
    getConsequenceStatus === "FETCHING" ||
    roleStatus === "FETCHING"
  ) {
    return <Spinner />;
  }

  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={consequenceIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("groupconsequence.header")}
          // Should this be teamleader_description_text?
          text={t("groupconsequence.description_text")}
          example_title={t("groupconsequence.example.title")}
          example_header={t("groupconsequence.example.header")}
        ></GridLeftContent>
      }
      right={
        <div className="w-full flex flex-col">
          <Header showOnlyMenu />
          <div className="flex flex-row h-4/5  mt-10 overflow-auto">
            <div className="flex flex-col ml-10 w-35/100 p-8 h-full border border-greyFive">
              <ConsequenceForm
                makeRequest={makeGetConsequenceRequest}
                createMakeRequest={makePostConsequenceRequest}
                formValues={formValues}
                setFormValues={setFormValues}
                selectedFrame={selectedFrame}
                setSelectedFrame={setSelectedFrame}
                company={company}
                userId={userId}
                roleType={role?.type}
                consequenceType="group"
              />
            </div>
            <div className="flex flex-col w-2/3 ml-10 mr-2">
              <FrameWrapper
                frames={consequences}
                selectedFrame={selectedFrame?.id}
                bgImage={consequenceBg}
                icon={consequenceIcon}
                onEdit={onEdit}
                onDelete={onDelete}
                useAnyFrameIsSelected={selectedFrame}
                type="consequence"
              />
            </div>
          </div>
          <div className="flex flex-row w-full mt-10">
            <Button
              disabled={false}
              className="h-12 w-1/3 mx-auto"
              variant="default"
              icon="arrow"
              iconColor="white"
              onClick={() => history.push(paths.OVERVIEW)}
            >
              {t("consequence.navigate_next")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default ConsequenceTeamleader;
