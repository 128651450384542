import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import useApi from "hooks/useApi";
import { getRolesEndpoint } from "api/role";
import { paths } from "routing/routes";
import constants from "utils/constants";
import Button from "components/button";
import Spinner from "components/spinner";
import {
  StyledMenuButton,
  StyledCloseButton,
  StyledMenu,
  StyledMenuItems,
  StyledMenuHeader,
  StyledMenuItem,
  StyledMenuFooter,
  StyledCopyrightSymbol,
  StyledPrintoutIframe,
  StyledMenuItemInactive,
} from "./menu.style";
import { useGroup } from "hooks/api/useGroup";

const Menu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, isLoading } = useAuth();
  const { group } = useGroup();
  const { companyName } = useCompany();

  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData, status: roleStatus }, makeRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    makeRoleRequest();
  }, [makeRoleRequest]);

  if (isLoading) {
    return null;
  }

  const userId = user.pk;
  const role = roleData?.find((role) => role?.user === userId);
  const isManagement = constants.PRE_PROCESS_ROLES.includes(role?.type);
  const isStakeholder = role?.type === constants.STAKEHOLDER;
  const isManager = group?.manager?.id === user?.pk;
  let isEmployee = role?.type === constants.EMPLOYEE;

  // User has role team lead - but not for this group
  if (!isManager && role?.type === constants.TEAMLEADER) {
    isEmployee = true;
  }

  const isMemberInMultipleGroups = user && user.groups.length > 1;

  const getRoleNamePretty = () => {
    if (!isManager && role?.type === constants.TEAMLEADER) {
      return t("menu.employee");
    }

    return t(`menu.${role?.type}`);
  };

  const printOrganizationLandscape = () => {
    window.frames["organization-printout-landscape"].print();
  };

  const printOrganization = () => {
    window.frames["organization-printout"].print();
  };

  const printIndividualStakeholder = () => {
    window.frames["stakeholder-individual-printout"].print();
  };

  const printIndividualGroup = () => {
    window.frames["group-individual-printout"].print();
  };

  const printStakeholder = () => {
    window.frames["stakeholder-printout"].print();
  };

  const printGroup = () => {
    window.frames["group-printout"].print();
  };
  if (roleStatus === "FETCHING") {
    return <Spinner />;
  }

  return (
    <>
      {!showMenu ? (
        <StyledMenuButton onClick={() => setShowMenu(!showMenu)} />
      ) : (
        <StyledMenu>
          <StyledCloseButton onClick={() => setShowMenu(false)} />
          <div>
            <Button
              className="h-12 w-32 mb-2"
              variant="blueWhite"
              onClick={() => history.push(paths.LOGOUT)}>
              {t("generic.logout")}
            </Button>
            <p>
              {t("menu.your_role")}: {getRoleNamePretty()}
            </p>

            <p>
              {t("menu.your_group")}:{" "}
              {group?.name || t("generic.missing_group")}
            </p>
            <p>
              {t("menu.your_organization")}: {companyName}
            </p>
          </div>

          <StyledMenuItems>
            {isManagement ? (
              <>
                <StyledMenuHeader>
                  {t("menu.teamleaders.overviews")}
                </StyledMenuHeader>
                <StyledMenuItem onClick={() => history.push(paths.OVERVIEW)}>
                  {t("menu.teamleaders.organisation")}
                </StyledMenuItem>
                <StyledMenuHeader>
                  {t("menu.teamleaders.tools")}
                </StyledMenuHeader>
                <StyledMenuItem
                  onClick={() => history.push(paths.HANDLE_GROUPS)}>
                  {t("menu.management.manage_groups")}
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => history.push(paths.HANDLE_USERS)}>
                  {t("menu.management.manage_users")}
                </StyledMenuItem>
                <StyledMenuHeader>
                  {t("menu.pre_process.printouts")}
                </StyledMenuHeader>
                <StyledMenuItem onClick={() => printOrganizationLandscape()}>
                  {t("menu.pre_process.printout_full_width")}
                </StyledMenuItem>
                <StyledMenuItemInactive>
                  {t("menu.pre_process.report_opinions_stakeholders")}
                </StyledMenuItemInactive>
                <StyledMenuItemInactive>
                  {t("menu.pre_process.report_significations_teamleaders")}
                </StyledMenuItemInactive>

                <StyledMenuHeader>{t("menu.pre_process.kpi")}</StyledMenuHeader>
                <StyledMenuItemInactive>
                  {t("menu.pre_process.kpi_panel")}
                </StyledMenuItemInactive>
                <StyledMenuItemInactive
                  onClick={() => history.push(paths.VALUATION_DIALOGUE)}>
                  {t("menu.pre_process.valuation_dialogue")}
                </StyledMenuItemInactive>
              </>
            ) : isStakeholder ? (
              <>
                <StyledMenuHeader>
                  {t("menu.stakeholders.reports")}
                </StyledMenuHeader>
                <StyledMenuItem onClick={() => printOrganization()}>
                  {t("menu.stakeholders.printout_organisation_overview")}
                </StyledMenuItem>
                <StyledMenuItem onClick={() => printIndividualStakeholder()}>
                  {t("menu.stakeholders.printout_stakeholder_overview")}
                </StyledMenuItem>
              </>
            ) : (
              (isManager || isEmployee) && (
                <>
                  <StyledMenuHeader>
                    {t("menu.teamleaders.overviews")}
                  </StyledMenuHeader>
                  <StyledMenuItem onClick={() => history.push(paths.OVERVIEW)}>
                    {t("menu.teamleaders.group")}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => history.push(paths.ORGANIZATION_OVERVIEW)}>
                    {t("menu.teamleaders.organisation")}
                  </StyledMenuItem>
                  {isManager && (
                    <>
                      <StyledMenuHeader>
                        {t("menu.teamleaders.tools")}
                      </StyledMenuHeader>
                      <StyledMenuItem
                        onClick={() => history.push(paths.VALUATION_DIALOGUE)}>
                        {t("menu.teamleaders.value_dialogues")}
                      </StyledMenuItem>

                      <StyledMenuItem
                        onClick={() =>
                          history.push(paths.HANDLE_GROUP_MEMBERS)
                        }>
                        {t("menu.teamleaders.handle_group_members")}
                      </StyledMenuItem>

                      <StyledMenuHeader>
                        {t("menu.teamleaders.other")}
                      </StyledMenuHeader>
                      <StyledMenuItemInactive>
                        {t("menu.teamleaders.kpi_panel")}
                      </StyledMenuItemInactive>
                      <StyledMenuItemInactive>
                        {t("menu.teamleaders.cheat_sheets")}
                      </StyledMenuItemInactive>

                      <StyledMenuHeader>
                        {t("menu.teamleaders.reports")}
                      </StyledMenuHeader>
                      <StyledMenuItem onClick={() => printOrganization()}>
                        {t("menu.teamleaders.printout_organisation_overview")}
                      </StyledMenuItem>
                      <StyledMenuItem onClick={() => printIndividualGroup()}>
                        {t("menu.teamleaders.printout_group_overview")}
                      </StyledMenuItem>
                    </>
                  )}
                </>
              )
            )}

            <>
              <StyledMenuHeader>{t("generic.user")}</StyledMenuHeader>
              <StyledMenuItem onClick={() => history.push(paths.USER_SETTINGS)}>
                {t("generic.settings")}
              </StyledMenuItem>
              {isMemberInMultipleGroups && (
                <StyledMenuItem
                  onClick={() => history.push(paths.CHANGE_GROUP)}>
                  {t("generic.change_group")}
                </StyledMenuItem>
              )}
            </>
          </StyledMenuItems>

          <StyledMenuFooter>
            <StyledCopyrightSymbol>C</StyledCopyrightSymbol>
            {t("menu.trademark")}
          </StyledMenuFooter>
        </StyledMenu>
      )}
      <StyledPrintoutIframe
        title="organization-printout-landscape"
        id="organization-printout-landscape"
        name="organization-printout-landscape"
        src="organization-printout-landscape"></StyledPrintoutIframe>

      <StyledPrintoutIframe
        title="organization-printout"
        id="organization-printout"
        name="organization-printout"
        src="organization-printout"></StyledPrintoutIframe>

      <StyledPrintoutIframe
        title="stakeholder-printout"
        id="stakeholder-printout"
        name="stakeholder-printout"
        src="stakeholder-printout"></StyledPrintoutIframe>

      <StyledPrintoutIframe
        title="group-printout"
        id="group-printout"
        name="group-printout"
        src="group-printout"></StyledPrintoutIframe>

      <StyledPrintoutIframe
        title="stakeholder-individual-printout"
        id="stakeholder-individual-printout"
        name="stakeholder-individual-printout"
        src="stakeholder-individual-printout"></StyledPrintoutIframe>

      <StyledPrintoutIframe
        title="group-individual-printout"
        id="group-individual-printout"
        name="group-individual-printout"
        src="group-individual-printout"></StyledPrintoutIframe>
    </>
  );
};

export default Menu;
