import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./app";
import config from "./config";
import "./services/i18n";

const dsn = {
  staging:
    "https://fed2ecc99a734d95ba69965096e40699@o1057996.ingest.sentry.io/6046022",
  production:
    "https://001d870dd633427ba013eeb00b76235a@o1057996.ingest.sentry.io/6045234",
};

if (["production", "staging"].includes(config.server_environment)) {
  Sentry.init({
    dsn: dsn[config.server_environment],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.7,
  });
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);
