import CaretIcon from "assets/svg/icons/CaretIcon.svg";
import PenIcon from "assets/svg/icons/PenIcon.svg";
import ValuationDialogPopup from "components/valuationDialogPopup";
import { format } from "date-fns";
import sv from "date-fns/locale/sv";
import { useState } from "react";

import { useHistory } from "react-router-dom";
import { paths } from "routing/routes";

export const MeetingCard = ({ meeting, prevDialogId }) => {
  const history = useHistory();
  const date = new Date();
  const [popupOpen, setPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const today = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
    -2,
  )}-${("0" + date.getDate()).slice(-2)}`;

  const onMeetingCardClick = () => {
    history.push({
      pathname: paths.MEETING_DIALOGS_OVERVIEW,
      state: { dialogId: meeting.id, prevDialogId },
    });
  };

  const onMeetingEdit = () => {
    setPopupOpen(true);
    setIsEditing(true);
  };

  return (
    <div className="border border-greyFive p-3 meetingCard rounded-md">
      <div>
        <p className="text-base text-darkBlue text-1xl">
          {format(new Date(meeting.date), "d LLLL", { locale: sv })}
        </p>
        <p className="text-lightGrey font-normal text-xs font-bold">
          {meeting.startTime}-{meeting.endTime}
        </p>
      </div>
      <div className="flex">
        {new Date(meeting.date) >= new Date(today) && (
          <button
            onClick={(e) => {
              onMeetingEdit(e);
            }}
          >
            <PenIcon style={{ marginRight: 16 }} />
          </button>
        )}

        <button
          onClick={(e) => {
            onMeetingCardClick();
          }}
        >
          <CaretIcon alt="next" />
        </button>
      </div>

      <ValuationDialogPopup
        hidePopup={() => setPopupOpen(false)}
        disableEditing={() => setIsEditing(false)}
        popupOpen={popupOpen}
        isEditing={isEditing}
        meeting={meeting}
      />
    </div>
  );
};
