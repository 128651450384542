import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import useApi from "hooks/useApi";
import { paths } from "routing/routes";
import { isEmpty } from "lodash";
import { signupEndpoint } from "api/auth";
import { Grid, GridLeftContent } from "components/grid";
import loginBgImage from "assets/img/backgrounds/login.png";
import { copyRight } from "assets/svg/global";
import Button from "components/button";
import { ErrorAlert } from "components/alert";
import { smartGoalLogo } from "assets/svg/logos";
import { hiddenIcon, viewIcon } from "assets/img/icons";
import { useCallback } from "react";

const Signup = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAuthenticated, login } = useAuth();
  const { fetchCompany } = useCompany();

  const [credentials, setCredentials] = useState({});
  const [authSent, setAuthSent] = useState(false);
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const parameters = new URLSearchParams(location.search);
  const token = parameters.get("token");

  const { path, method } = signupEndpoint();
  const [{ data, status }, makeRequest] = useApi(path, method);

  const parseError = useCallback(
    (errors) => {
      let message = "";
      Object.keys(errors).forEach((key) => {
        message += errors[key].map((_error) => {
          return `${t(`signup.errors.${_error}`)}\n`;
        });
      });
      return message;
    },
    [t],
  );

  useEffect(() => {
    if (isAuthenticated) {
      fetchCompany();
      history.push(paths.OVERVIEW);
    }
  }, [isAuthenticated, history, fetchCompany]);

  useEffect(() => {
    if (status === "SUCCESS" && !isEmpty(credentials) && !authSent) {
      // Login after signup
      setAuthSent(true);
      login(credentials.email, credentials.password);
    } else if (status === "ERROR") {
      setError(parseError(data));
    }
  }, [status, credentials, history, login, data, authSent, parseError]);

  const onSubmit = ({ email, password }) => {
    makeRequest({ email, token, password1: password, password2: password });
    setCredentials({ email, password });
  };

  const fieldClassName =
    "flex rounded-md border-2 border-lighterGrey w-full h-12 pl-4";
  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={loginBgImage}
          contentImage={smartGoalLogo}
          text={t("login.description_text")}
          text_alignment="center">
          <div className="absolute bottom-8 text-darkBlue">
            <img
              src={copyRight}
              alt="copy right icon"
              className="inline pr-2"
            />
            <span className="text-xs">{"2021. Powered by: CediTech"}</span>
          </div>
        </GridLeftContent>
      }
      right={
        <div className="m-auto items-center">
          <ErrorAlert show={error} text={error} />
          <Form
            initialValues={{ email: parameters.get("email") }}
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = "Required";
              }
              if (!values.terms) {
                errors.terms = "Required";
              }
              return errors;
            }}
            render={({ handleSubmit, hasValidationErrors }) => (
              <form className="flex flex-col w-500" onSubmit={handleSubmit}>
                <div className="flex-col py-4 text-28 text-darkBlue">
                  <span>{t("signup.signup")}</span>
                </div>
                <div className="flex-col py-4">
                  <label className="flex text-2xl pb-4">
                    {t("signup.email")}
                  </label>
                  <Field
                    disabled={true}
                    name="email"
                    type="email"
                    component="input"
                    className={`${fieldClassName} cursor-not-allowed`}
                  />
                </div>
                <div className="flex-col py-4">
                  <label className="flex text-2xl pb-4">
                    {t("signup.password")}
                  </label>
                  <Field
                    name="password"
                    className={fieldClassName}
                    render={({ input, meta }) => (
                      <div className="relative">
                        <input
                          className={fieldClassName}
                          type={showPassword ? "text" : "password"}
                          {...input}
                          placeholder="*****"
                          autocomplete={token ? "off" : null}
                        />
                        <div
                          className="absolute top-3 right-3 cursor-pointer"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}>
                          <img
                            src={showPassword ? hiddenIcon : viewIcon}
                            width="25px"
                            height="25px"
                            alt="showPasswordIcon"
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="flex flex-row">
                  <Field
                    name="terms"
                    type="checkbox"
                    component="input"
                    className="my-auto"
                  />
                  <span className="text-grey text-sm ml-4">
                    {t("signup.terms")}
                  </span>
                </div>
                <div className="flex flex-row mt-4">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 hover:underline"
                    href="https://appinfo.smartgoal.se/terms/">
                    {t("signup.terms_link")}
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ml-4 text-blue-600 hover:underline"
                    href="https://appinfo.smartgoal.se/privacy-policy/">
                    {t("signup.data_management_link")}
                  </a>
                </div>
                <Button
                  disabled={hasValidationErrors}
                  className="h-12 mt-auto absolute bottom-8 w-500"
                  type="submit"
                  variant="action"
                  icon="arrow">
                  {t("signup.next")}
                </Button>
              </form>
            )}
          />
        </div>
      }
    />
  );
};

export default Signup;
