import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import { paths } from "routing/routes";
import { Grid, GridLeftContent } from "components/grid";
import Button from "components/button";
import loginBgImage from "assets/img/backgrounds/login.png";
import { smartGoalLogo } from "assets/svg/logos";
import { copyRight } from "assets/svg/global";
import { ErrorAlert } from "components/alert";
import { useParams } from "react-router-dom";
import { deleteAuthCookie } from "utils/auth";
import useApi from "hooks/useApi";
import { resetPasswordEndpoint, resetPasswordConfirmEndpoint } from "api/auth";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { fetchCompany } = useCompany();

  const [hasError, setHasError] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const { path: resetPath, method: resetMethod } = resetPasswordEndpoint();
  const [{ status }, makeResetRequest] = useApi(resetPath, resetMethod);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCompany();
      history.push(paths.VISION);
    }
  }, [isAuthenticated, history, fetchCompany]);

  useEffect(() => {
    if (status === "SUCCESS") {
      setHasError(false);
      setSuccessState(true);
    } else if (status === "ERROR") {
      setHasError(true);
    }
  }, [status, history]);

  const onSubmit = ({ email }) => {
    deleteAuthCookie();
    makeResetRequest({ email });
  };

  const fieldClassName =
    "flex rounded-md border-2 border-lighterGrey w-full h-12 pl-4";

  return (
    <>
      <Grid
        left={
          <GridLeftContent
            backgroundImage={loginBgImage}
            contentImage={smartGoalLogo}
            text={t("login.description_text")}
            text_alignment="center"
          >
            <div className="absolute bottom-8 text-darkBlue">
              <img
                src={copyRight}
                alt="copy right icon"
                className="inline pr-2"
              />
              <span className="text-xs">{"2021. Powered by: CediTech"}</span>
            </div>
          </GridLeftContent>
        }
        right={
          <div className="m-auto items-center">
            {successState ? (
              <div className="flex flex-col w-500">
                <div className="flex-col py-4 text-28 text-darkBlue">
                  <span>{t("forgot_password.reset_password")}</span>
                </div>
                <div className="m-auto text-base my-6">
                  {t("forgot_password.email_sent")}
                </div>
                <div className="my-4 text-center">
                  <Button variant="flat" onClick={() => history.push("/login")}>
                    {t("forgot_password.log_in")}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="py-6">
                  <ErrorAlert
                    show={hasError}
                    text={t("forgot_password.no_account_error")}
                  />
                </div>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form
                      className="flex flex-col w-500"
                      onSubmit={handleSubmit}
                    >
                      <div className="flex-col py-4 text-28 text-darkBlue">
                        <span>{t("forgot_password.reset_password")}</span>
                      </div>
                      <div className="m-auto text-base my-6">
                        {t("forgot_password.description")}
                      </div>
                      <div className="flex-col py-4">
                        <label className="flex text-2xl pb-4">
                          {t("login.email")}
                        </label>
                        <Field
                          name="email"
                          type="email"
                          component="input"
                          placeholder="email@email.com"
                          className={fieldClassName}
                        />
                      </div>
                      <Button
                        className="h-12 mt-auto absolute bottom-8 w-500"
                        type="submit"
                        variant="action"
                        icon="arrow"
                      >
                        {t("generic.reset")}
                      </Button>
                    </form>
                  )}
                />
                <div className="my-4 text-center">
                  <Button variant="flat" onClick={() => history.push("/login")}>
                    {t("forgot_password.log_in")}
                  </Button>
                </div>
              </>
            )}
          </div>
        }
      />
    </>
  );
};

const ForgotPasswordConfirm = () => {
  const { isAuthenticated } = useAuth();
  const { fetchCompany } = useCompany();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated) {
      fetchCompany();
      history.push(paths.VISION);
    }
  }, [isAuthenticated, history, fetchCompany]);

  let { token } = useParams();

  const onSubmit = ({ password }) => {
    makeResetRequest({ password, token });
  };

  const { path: resetPath, method: resetMethod } =
    resetPasswordConfirmEndpoint();

  const [{ status }, makeResetRequest] = useApi(resetPath, resetMethod);

  const [hasError, setHasError] = useState();

  useEffect(() => {
    if (status === "SUCCESS") {
      setHasError(false);
      history.push(paths.LOGIN);
    } else if (status === "ERROR") {
      setHasError(true);
    }
  }, [status, history]);

  const fieldClassName =
    "flex rounded-md border-2 border-lighterGrey w-full h-12 pl-4";

  return (
    <>
      <Grid
        left={
          <GridLeftContent
            backgroundImage={loginBgImage}
            contentImage={smartGoalLogo}
            text={t("login.description_text")}
            text_alignment="center"
          >
            <div className="absolute bottom-8 text-darkBlue">
              <img
                src={copyRight}
                alt="copy right icon"
                className="inline pr-2"
              />
              <span className="text-xs">{"2021. Powered by: CediTech"}</span>
            </div>
          </GridLeftContent>
        }
        right={
          <div className="m-auto items-center">
            <ErrorAlert show={hasError} text={t("forgot_password.error")} />
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="flex flex-col w-500" onSubmit={handleSubmit}>
                  <div className="flex-col py-4 text-28 text-darkBlue">
                    <span>{t("forgot_password.reset_password")}</span>
                  </div>
                  <div className="m-auto text-base my-6">
                    {t("forgot_password.description_confirm")}
                  </div>

                  <div className="flex-col py-4">
                    <label className="flex text-2xl pb-4">
                      {t("forgot_password.new_password")}
                    </label>
                    <Field
                      name="password"
                      type="password"
                      component="input"
                      placeholder="*****"
                      className={fieldClassName}
                    />
                  </div>

                  <div className="flex-col py-4">
                    <label className="flex text-2xl pb-4">
                      {t("forgot_password.new_password_repeat")}
                    </label>
                    <Field
                      name="repeat_password"
                      type="password"
                      component="input"
                      placeholder="*****"
                      className={fieldClassName}
                    />
                  </div>

                  <Button
                    className="h-12 mt-auto absolute bottom-8 w-500"
                    type="submit"
                    variant="action"
                    icon="arrow"
                  >
                    {t("generic.reset")}
                  </Button>
                </form>
              )}
            />
            <div className="my-4 text-center">
              <Button variant="flat" onClick={() => history.push("/login")}>
                {t("forgot_password.log_in")}
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
};

export { ForgotPassword, ForgotPasswordConfirm };
