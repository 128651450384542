const TrashIcon = ({ ...props }) => (
  <svg
    width={14}
    height={18}
    fill="none"
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12ZM3 6h8v10H3V6Zm7.5-5-1-1h-5l-1 1H0v2h14V1h-3.5Z"
      fill="#316DF0"
    />
  </svg>
);

export default TrashIcon;
