import Frame, {
  BehaviorFrame,
  BehaviorStakeholderFrame,
  BehaviorTeamleaderFrame,
  ConsequenceFrame,
  ConsequenceStakeholderFrame,
  OverviewConsequenceStakeholderFrame,
  PrintoutConsequenceFrame,
} from "components/frame";
import { Fragment } from "react";
import constants from "utils/constants";

const FrameWrapper = ({
  frames = [],
  selectedFrame,
  onEdit,
  onDelete,
  bgImage,
  icon,
  frameClassname,
  frameOnClick,
  frameBg,
  useAnyFrameIsSelected = true,
  type = "default",
  noFlex = false,
}) => {
  if (!frames || frames.length === 0) {
    if (!bgImage) {
      return null;
    }
    return <img className="m-auto" src={bgImage} alt="value background" />;
  }

  const renderContent = () => {
    // Render consequences differently
    if (
      ["overviewConsequenceStakeholder", "consequence"].includes(type) &&
      noFlex
    ) {
      const positives = frames.filter((cons) => cons.type === "positive");
      const negatives = frames.filter((cons) => cons.type === "negative");

      const renderConsequenceFrame = (frame, index) => {
        return (
          <ConsequenceFrame
            key={index}
            purpose={frame.purpose}
            description={frame.description}
            type={frame.type}
            id={frame.id}
            bgImage={frameBg}
            isSelected={frame.id === selectedFrame}
            anyFrameIsSelected={
              useAnyFrameIsSelected
                ? selectedFrame !== null
                : useAnyFrameIsSelected
            }
            icon={icon}
            onEdit={onEdit}
            onDelete={onDelete}
            frameClassname={frameClassname}
          />
        );
      };

      const renderSHConsequenceFrame = (frame, index) => {
        return (
          <OverviewConsequenceStakeholderFrame
            key={index}
            isProposal={frame.role_type === constants.STAKEHOLDER}
            consequence={frame}
            bgImage={frameBg}
            isSelected={frame.id === selectedFrame}
            anyFrameIsSelected={
              useAnyFrameIsSelected
                ? selectedFrame !== null
                : useAnyFrameIsSelected
            }
            icon={icon}
            frameClassname={frameClassname}
          />
        );
      };

      return (
        <div className="col-span-12 grid grid-cols-2 gap-6">
          <div className="grid grid-cols-2 gap-6">
            {positives.map((frame, index) => {
              if (type === "consequence") {
                return renderConsequenceFrame(frame, index);
              } else if (type === "overviewConsequenceStakeholder") {
                return renderSHConsequenceFrame(frame, index);
              }
              return <Fragment key={index} />;
            })}
          </div>
          <div className="grid grid-cols-2 gap-6">
            {negatives.map((frame, index) => {
              if (type === "consequence") {
                return renderConsequenceFrame(frame, index);
              } else if (type === "overviewConsequenceStakeholder") {
                return renderSHConsequenceFrame(frame, index);
              }
              return <Fragment key={index} />;
            })}
          </div>
        </div>
      );
    }

    return (
      <>
        {frames.map((frame, index) => {
          if (type === "behavior") {
            return (
              <BehaviorFrame
                key={index}
                {...frame}
                bgImage={frameBg}
                isSelected={frame.id === selectedFrame?.id}
                anyFrameIsSelected={selectedFrame !== null}
                icon={icon}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
          } else if (type === "behaviorStakeholder") {
            return (
              <BehaviorStakeholderFrame
                key={index}
                behaviorNote={frame.note}
                behaviorDescription={frame.description}
                behaviorId={frame.id}
                value={frame.value}
                opinion={frame.opinion}
                bgImage={frameBg}
                isSelected={frame.id === selectedFrame?.id}
                anyFrameIsSelected={selectedFrame !== null}
                icon={icon}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
          } else if (type === "behaviorTeamleader") {
            return (
              <BehaviorTeamleaderFrame
                key={index}
                behaviorNote={frame.note}
                behaviorDescription={frame.description}
                behaviorId={frame.id}
                value={frame.value}
                signification={frame.signification}
                bgImage={frameBg}
                isSelected={frame.id === selectedFrame?.id}
                anyFrameIsSelected={selectedFrame !== null}
                icon={icon}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
          } else if (type === "consequence") {
            return (
              <ConsequenceFrame
                key={index}
                purpose={frame.purpose}
                description={frame.description}
                type={frame.type}
                id={frame.id}
                bgImage={frameBg}
                isSelected={frame.id === selectedFrame}
                anyFrameIsSelected={
                  useAnyFrameIsSelected
                    ? selectedFrame !== null
                    : useAnyFrameIsSelected
                }
                icon={icon}
                onEdit={onEdit}
                onDelete={onDelete}
                frameClassname={frameClassname}
              />
            );
          } else if (type === "consequenceStakeholder") {
            return (
              <ConsequenceStakeholderFrame
                key={index}
                isProposal={frame.role_type === constants.STAKEHOLDER}
                consequence={frame}
                bgImage={frameBg}
                isSelected={frame.id === selectedFrame}
                anyFrameIsSelected={
                  useAnyFrameIsSelected
                    ? selectedFrame !== null
                    : useAnyFrameIsSelected
                }
                icon={icon}
                onEdit={onEdit}
                onDelete={onDelete}
                frameClassname={frameClassname}
              />
            );
          } else if (type === "overviewConsequenceStakeholder") {
            return (
              <OverviewConsequenceStakeholderFrame
                key={index}
                isProposal={frame.role_type === constants.STAKEHOLDER}
                consequence={frame}
                bgImage={frameBg}
                isSelected={frame.id === selectedFrame}
                anyFrameIsSelected={
                  useAnyFrameIsSelected
                    ? selectedFrame !== null
                    : useAnyFrameIsSelected
                }
                icon={icon}
                frameClassname={frameClassname}
              />
            );
          } else if (type === "printoutConsequence") {
            return (
              <PrintoutConsequenceFrame
                key={index}
                isProposal={frame.role_type === constants.STAKEHOLDER}
                consequence={frame}
                icon={icon}
                frameClassname={frameClassname}
              />
            );
          } else {
            return (
              <Frame
                {...frame}
                key={frame}
                isSelected={frame.id === selectedFrame}
                anyFrameIsSelected={
                  useAnyFrameIsSelected
                    ? selectedFrame !== null
                    : useAnyFrameIsSelected
                }
                icon={icon}
                onEdit={onEdit}
                onDelete={onDelete}
                frameClassname={frameClassname}
                frameOnClick={frameOnClick}
              />
            );
          }
        })}
      </>
    );
  };

  return noFlex ? (
    <> {renderContent()} </>
  ) : (
    <div className="flex flex-row flex-wrap w-full">{renderContent()}</div>
  );
};

export default FrameWrapper;
