import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import "rc-dropdown/assets/index.css";
import { useEffect, useState } from "react";
import { isAfter } from "date-fns";
import { createValuationDialogEndpoint } from "api/valuationDialogues";
import useApi from "hooks/useApi";
import api from "api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "assets/svg/icons/CalendarIcon.svg.js";
import ClockIcon from "assets/svg/icons/ClockIcon.svg.js";

const ValuationDialogPopup = ({
  popupOpen,
  hidePopup,
  isEditing = false,
  meeting,
}) => {
  const { t } = useTranslation();

  const { path, method } = createValuationDialogEndpoint();
  const [{ status }, makeRequest] = useApi(path, method);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const onSaveDialogClick = async (meeting) => {
    const parsedStartTime = new Date(startDate);
    parsedStartTime.setHours(
      startTime.toLocaleTimeString().split(":")[0],
      startTime.toLocaleTimeString().split(":")[1],
      startTime.toLocaleTimeString().split(":")[2],
    );
    const parsedEndTime = new Date(startDate);
    parsedEndTime.setHours(
      endTime.toLocaleTimeString().split(":")[0],
      endTime.toLocaleTimeString().split(":")[1],
      endTime.toLocaleTimeString().split(":")[2],
    );

    const endTimeIsAfterStart = isAfter(parsedEndTime, parsedStartTime);

    if (!endTimeIsAfterStart) {
      alert("Sluttid kan inte vara före starttid");
      return;
    }

    if (meeting && isEditing) {
      try {
        await api.patch(`/v1/dialog/${meeting.id}/`, {
          start_date: parsedStartTime,
          end_date: parsedEndTime,
          createdBy: meeting.created_by,
        });
      } catch {
        // TODO: Implement proper error handling here.
        console.log(`Failed to edit dialog with ID ${meeting.id}`);
      }
    } else if (!isEditing) {
      await makeRequest({
        start_date: parsedStartTime,
        end_date: parsedEndTime,
      });
    }
    hidePopup();
    document.location.reload();
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      hidePopup();
    }
  }, [status, hidePopup]);

  if (!popupOpen) return <></>;

  return (
    <div className="absolute w-screen top-0 right-0 left-0 flex mt-56 justify-center content-center">
      <div className="relative w-96 flex flex-col  bg-white shadow-popUp rounded px-4 pt-12 ">
        <RoundedCloseIcon
          onClick={() => {
            hidePopup();
          }}
          className="absolute top-4 right-2 "
        />
        <h3 className="text-2xl text-darkBlue mb-6">
          {isEditing
            ? t("valuation_dialog.change_value_dialog")
            : t("valuation_dialog.new_value_dialog")}
        </h3>
        <form className="flex flex-col">
          <label className="mb-2 text-grey mt-6" htmlFor="">
            {t("valuation_dialog.meeting_date")}
          </label>
          <DatePicker
            showIcon={true}
            icon={<CalendarIcon />}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className="border-1-5 rounded border-lighterGrey w-full"
          />
          <label className="mb-2 text-grey mt-6" htmlFor="">
            {t("valuation_dialog.start_time")}
          </label>
          <DatePicker
            showIcon={true}
            icon={
              <div>
                <ClockIcon />
              </div>
            }
            selected={startTime}
            onChange={(date) => setStartTime(date)}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            timeIntervals={15}
            showTimeSelectOnly
            showTimeSelect
            timeCaption="Time"
            className="border-1-5 rounded border-lighterGrey w-full"
          />

          <label className="mb-2 text-grey mt-6" htmlFor="">
            {t("valuation_dialog.end_time")}
          </label>
          <DatePicker
            showIcon={true}
            icon={
              <div>
                <ClockIcon />
              </div>
            }
            selected={endTime}
            onChange={(date) => setEndTime(date)}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            timeIntervals={15}
            showTimeSelectOnly
            showTimeSelect
            timeCaption="Time"
            className="border-1-5 rounded border-lighterGrey w-full"
          />
        </form>
        <Button
          className="h-12 pl-5 pr-5 self-end mt-4 mb-6"
          variant="action"
          onClick={() => onSaveDialogClick(meeting)}
          disabled={!endTime || !startTime || !startDate}
        >
          {t("handle_group_members.save")}
        </Button>
      </div>
    </div>
  );
};

export default ValuationDialogPopup;
