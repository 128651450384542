import { useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useCompany } from "context/company-context";
import useApi from "hooks/useApi";
import api from "api";
import { createSignificationEndpoint } from "api/signification";
import Button from "components/button";

const NOTE_FIELD_MAXLENGTH = 350;

const OpinionForm = ({
  id,
  description,
  userId,
  setSelected,
  setSelectedBehavior,
  behavior,
  onComplete,
  groupId = null,
}) => {
  const { t } = useTranslation();
  const { company, isLoading } = useCompany();
  const { path, method } = createSignificationEndpoint();
  const [{ status }, makeRequest] = useApi(path, method);

  useEffect(() => {
    if (status === "SUCCESS") {
      setSelectedBehavior(null);
      onComplete();
    }
  }, [status, setSelected, setSelectedBehavior, onComplete]);

  const onSubmit = async ({ signification, significationId }) => {
    const significationNote = signification?.note;
    if (significationId) {
      try {
        await api.patch(`/v1/signification/${significationId}/`, {
          note: significationNote,
        });
      } catch {
        // TODO: Implement proper error handling here.
        console.log(
          `Failed to submit signification with ID ${significationId}`,
        );
      }
      setSelectedBehavior(null);
      onComplete();
    } else {
      const payload = {
        signification: significationId,
        note: significationNote,
        behavior: behavior.id,
        company,
        user: userId,
      };

      if (groupId != null) {
        payload["group"] = groupId;
      }

      makeRequest(payload);
    }
  };

  const serializeInitialData = (behavior) => {
    if (!behavior) {
      return null;
    }
    const significationId = behavior?.signification?.id;
    return { significationId, ...behavior };
  };

  if (!id || isLoading) {
    return null;
  }

  const fieldClassName =
    "w-full flex border p-4 border-ligtherGrey resize-none";

  return (
    <>
      <span className="text-grey font-bold mb-4">
        {`${t("value.value")}: ${description}`}
      </span>
      <div className="w-full flex flex-col text-left border-l-2 border-lightBlue">
        <span className="w-full pl-2 text-xl my-2 text-darkBlue italic">
          {behavior.description}
        </span>
        <span className="pl-2 text-xs text-darkBlue italic">
          {behavior.note}
        </span>
      </div>
      <Form
        initialValues={serializeInitialData(behavior)}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form
              className="flex flex-col h-full w-full"
              onSubmit={handleSubmit}
            >
              <div className="flex-col h-full py-4">
                <label className="flex text-2xl pb-4">
                  {t("signification.form.note")}
                </label>
                <Field
                  name="signification.note"
                  type="textarea"
                  component="textarea"
                  placeholder={t("signification.form.write_here")}
                  className={`${fieldClassName} h-5/6`}
                  maxLength={NOTE_FIELD_MAXLENGTH}
                />
                <div className="flex justify-end pt-1">
                  {(values.signification?.note || "").length}/{" "}
                  {NOTE_FIELD_MAXLENGTH}
                </div>
              </div>
              <Field name="opinion_id" component="input" type="hidden" />
              <div className="flex mt-10">
                <Button
                  disabled={submitting}
                  className="h-10 w-2/6 mr-auto"
                  type="submit"
                  variant="default"
                >
                  {t("signification.form.submit")}
                </Button>

                <Button
                  className="h-10 w-2/6 ml-auto"
                  variant="blueWhite"
                  onClick={() => {
                    setSelected(null);
                    setSelectedBehavior(null);
                  }}
                >
                  {t("generic.cancel")}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default OpinionForm;
