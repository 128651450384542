/** changeLocalCompanyState
 *
 * @param {Integer} statusCode
 * @param {Object} companyData
 * @param {Function} setCompanyState
 */
export const changeLocalCompanyState = (
  statusCode,
  companyData,
  setCompanyState
) => {
  if (statusCode === 200) {
    setCompanyState(companyData["state"]);
  }
};
