import useApi from "hooks/useApi";
import { useGroup } from "hooks/api/useGroup";
import { useAuth } from "context/auth-context";
import { getRolesEndpoint } from "api/role";
import constants from "utils/constants";
import { getGroupConsequencesEndpoint } from "api/group";
import { useEffect } from "react";

export const useGetConsequences = ({ companyId }) => {
  const { user } = useAuth();
  const { group } = useGroup();
  const userId = user.pk;
  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData }, makeRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );
  // Get group consequences
  const { path: getConsequencePath, method: getConsequenceMethod } =
    getGroupConsequencesEndpoint();
  const [{ data: groupConsequences }, makeGetConsequenceRequest] = useApi(
    getConsequencePath,
    getConsequenceMethod,
  );

  const role = roleData?.find(
    (role) => role?.user === userId && role?.company?.id === companyId,
  );
  const isStakeholder = role?.type === constants.STAKEHOLDER;
  const isTeamleader = group?.manager?.id === user?.pk;
  let isEmployee = role?.type === constants.EMPLOYEE;
  const isPreProcess = constants.PRE_PROCESS_ROLES.includes(role?.type);

  // User has role team lead - but not for this group
  if (!isTeamleader && role?.type === constants.TEAMLEADER) {
    isEmployee = true;
  }

  const sortStakeholderConsequences = (consequences) => {
    return consequences?.sort(function (a, b) {
      if (a.role_type === constants.STAKEHOLDER) {
        return -1;
      }
      if (b.role_type === constants.STAKEHOLDER) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (isTeamleader || isEmployee) {
      makeGetConsequenceRequest();
    }
  }, [isTeamleader, isEmployee, makeGetConsequenceRequest]);

  useEffect(() => {
    makeRoleRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this logic can probably be written better
  const getConsequences = (companyData) => {
    if (isTeamleader || isEmployee) return groupConsequences;

    const consequences = companyData?.consequences?.filter((consequence) => {
      if (!isStakeholder && consequence.role_type === constants.STAKEHOLDER) {
        return false;
      }
      if (
        isStakeholder &&
        consequence.role_type === constants.STAKEHOLDER &&
        consequence?.user?.id !== userId
      ) {
        return false;
      }
      if (isStakeholder && consequence.role_type === constants.EMPLOYEE) {
        return false;
      }
      if (!isTeamleader && consequence.role_type === constants.TEAMLEADER) {
        return false;
      }
      if (isTeamleader && consequence?.user?.id !== userId) {
        return false;
      }
      if (
        isPreProcess &&
        !constants.PRE_PROCESS_ROLES.includes(consequence.role_type)
      ) {
        return false;
      }
      if (isStakeholder) {
        const opinion = companyData.opinions.find(
          (opinion) =>
            opinion?.consequence?.id === consequence.id &&
            opinion?.user?.id === userId,
        );
        if (opinion) {
          consequence.opinion = opinion;
        }
      }
      return true;
    });

    if (isStakeholder) {
      return sortStakeholderConsequences(consequences);
    } else {
      return consequences;
    }
  };

  return { getConsequences };
};
