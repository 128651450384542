export const getCompaniesEndpoint = () => ({
  path: "/v1/company/",
  method: "GET",
});

export const getCompanyOverviewEndpoint = (companyId) => ({
  path: `/v1/company/${companyId}/overview/`,
  method: "GET",
});

// Process State Transitions for Company Flow //

export const transitionCompanyToValues = (companyId) => ({
  path: `/v1/company/${companyId}/to-state-values/`,
  method: "POST",
});

export const transitionCompanyToBehaviors = (companyId) => ({
  path: `/v1/company/${companyId}/to-state-behaviors/`,
  method: "POST",
});

export const transitionCompanyToConsequences = (companyId) => ({
  path: `/v1/company/${companyId}/to-state-consequences/`,
  method: "POST",
});

export const transitionCompanyToOverview = (companyId) => ({
  path: `/v1/company/${companyId}/to-state-overview/`,
  method: "POST",
});
