import {
  add,
  compareDesc,
  format,
  getHours,
  getYear,
  isBefore,
  isSameDay,
  setHours,
} from "date-fns";
import { sv } from "date-fns/locale";

export const MEETING_ENUM = {
  MEETING_DATE: "MEETING_DATE",
  START_TIME: "START_TIME",
  END_TIME: "END_TIME",
};

const localizedDate = (date) => {
  if (!date) return "";
  return format(date, "PPP", { locale: sv });
};

export const getTodaysDateStringSE = () => {
  const date = new Date();
  const formattedDate = localizedDate(date); // Example: 3 november 2022
  return formattedDate.toString();
};

export const getComingDaysFromToday = (days) => {
  const today = new Date();
  let counter = 1;
  let comingDays = [];
  if (!days) return [];

  while (counter < days) {
    const nextDay = add(today, { days: counter });
    const formattedDate = localizedDate(nextDay);
    comingDays.push(formattedDate.toString());
    counter++;
  }
  return comingDays;
};
export const getMeetingBookingHours = (date) => {
  if (!date instanceof Date) return [];
  const meetingDate = new Date(date);
  const today = new Date();
  let currentHour = today.getHours();
  const bookingHours = [];
  if (isSameDay(meetingDate, today)) {
    for (var i = currentHour + 1; i < 25; i++) {
      const nextHour = setHours(today, i);
      const bookingHourNumber = getHours(nextHour);
      const hourString =
        bookingHourNumber === 0
          ? bookingHourNumber + "0:00"
          : bookingHourNumber + ":00";
      bookingHours.push(hourString);
      currentHour++;
    }
  } else {
    // If not the same day, we want to display all hours of the day
    for (var hour = 0; hour < 24; hour++) {
      const hourString = `${bookingHours.length < 10 ? "0" + hour : hour}:00`;
      bookingHours.push(hourString);
    }
  }
  return bookingHours;
};

// Should return following format -> 2022-12-27T12:38:56.390912Z
export const parseDate = (dateTime) => {
  if (!dateTime || !dateTime instanceof Date) return "";
  const year = format(dateTime, "yyyy");
  const month = format(dateTime, "MM");
  const day = format(dateTime, "dd");
  const hour = format(dateTime, "HH");

  return `${year}-${month}-${day}T${hour}:00:00.000000Z`;
};

export const getYearsFromDateArray = (dates) => {
  if (!dates || !dates.length > 0) return [];
  return [...new Set(dates.map((date) => getYear(new Date(date.date))))];
};

export const calendarMonths = Array.from({ length: 12 }, (item, i) => {
  return new Date(0, i - 1).toLocaleString("en-US", { month: "numeric" });
});

export const getLastThreeDialogs = (dialogs, count) => {
  if (dialogs.length <= 0) return [];

  const sortedDialogsEndDate = [...dialogs].sort(
    (firstDialog, secondDialog) => {
      return compareDesc(
        new Date(secondDialog.end_date),
        new Date(firstDialog.end_date),
      );
    },
  );

  const prevDialogs = sortedDialogsEndDate.filter((dialog) =>
    isBefore(new Date(dialog.start_date), new Date()),
  );

  return prevDialogs.slice(-count);
};
