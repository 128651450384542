import { useTranslation } from "react-i18next";
import Pill from "components/pill";
import constants from "utils/constants";
import { Fragment } from "react";
import { isEmpty } from "lodash";

const getOpacity = (anyFrameIsSelected, isSelected) => {
  if (anyFrameIsSelected && !isSelected) {
    return "opacity-50";
  }
  return "";
};

const getBorder = (isSelected) => {
  if (isSelected) {
    return "border-safflowerDark";
  }

  return "border-greyFive";
};

const Frame = ({
  purpose,
  description,
  id,
  icon,
  onEdit,
  onDelete,
  isSelected,
  anyFrameIsSelected,
  frameClassname = "w-46/100 mr-4",
  frameOnClick = () => {},
  bottomContent,
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={() => {
        frameOnClick({ purpose, description, id });
      }}
      className={`flex flex-col border ${getBorder(
        isSelected,
      )} p-4 mb-4 ${getOpacity(
        anyFrameIsSelected,
        isSelected,
      )} ${frameClassname}`}
    >
      <div className="flex flex-row mb-6 z-10 break-words">
        <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        <span className="text-darkBlue text-2xl ml-2">{description}</span>
      </div>
      <span className="text-grey text-base leading-100 mb-4 break-words">
        {purpose}
      </span>
      {onEdit && onDelete && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({ purpose, description, id });
            }}
            className="cursor-pointer"
          >
            {t("generic.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(id);
            }}
            className="cursor-pointer ml-4"
          >
            {t("generic.remove")}
          </span>
        </div>
      )}
      <div className="w-full">{bottomContent}</div>
    </div>
  );
};

export const BehaviorFrame = ({
  note,
  description,
  id,
  value,
  icon,
  onEdit,
  onDelete,
  isSelected,
  anyFrameIsSelected,
  bgImage,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)), url(${bgImage})`,
        backgroundSize: "cover",
      }}
      className={`flex flex-col border-1-5 ${getBorder(
        isSelected,
      )} ${getOpacity(
        anyFrameIsSelected,
        isSelected,
      )} boxShadow-custom p-4 mb-4 w-46/100 mr-4`}
    >
      <div className="flex flex-col mb-6 z-10 break-words">
        <div className="flex flex-row">
          <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        </div>
        <span className="w-full text-lightGrey my-4">{value.description}</span>
        <span className="text-darkBlue text-2xl">{description}</span>
      </div>
      <span className="text-grey text-base leading-100 mb-4">{note}</span>

      {onEdit && onDelete && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({ description, id, note, value });
            }}
            className="cursor-pointer"
          >
            {t("generic.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(id);
            }}
            className="cursor-pointer ml-4"
          >
            {t("generic.remove")}
          </span>
        </div>
      )}
    </div>
  );
};

// On this frame the user can only control the opinion related to the behavior
export const BehaviorStakeholderFrame = ({
  behaviorNote,
  behaviorDescription,
  behaviorId,
  value,
  opinion,
  icon,
  onEdit,
  onDelete,
  isSelected,
  anyFrameIsSelected,
  bgImage,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)), url(${bgImage})`,
        backgroundSize: "cover",
      }}
      className={`flex flex-col border-1-5 ${getBorder(
        isSelected,
      )} ${getOpacity(
        anyFrameIsSelected,
        isSelected,
      )} boxShadow-custom p-4 mb-4 w-46/100 mr-4`}
    >
      <div className="flex flex-col mb-2 z-10 break-words">
        <div className="flex flex-row">
          <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        </div>
        <span className="w-full text-lightGrey my-4">{value.description}</span>
        <span className="text-darkBlue text-2xl">{behaviorDescription}</span>
      </div>
      <span className="text-darkBlue text-sm leading-100 mb-4">
        {behaviorNote}
      </span>

      {opinion && (
        <>
          <span className="w-full text-darkBlue my-4">
            {t("generic.header_opinion")}
          </span>
          <span className="text-lightGrey text-base leading-100 mb-4">
            {opinion.note}
          </span>
        </>
      )}

      {!opinion && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({
                id: behaviorId,
                description: behaviorDescription,
                note: behaviorNote,
                value,
              });
            }}
            className="cursor-pointer"
          >
            {t("opinion.add")}
          </span>
        </div>
      )}

      {opinion && onEdit && onDelete && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({
                id: behaviorId,
                description: behaviorDescription,
                note: behaviorNote,
                value,
                opinion,
              });
            }}
            className="cursor-pointer"
          >
            {t("generic.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(opinion.id);
            }}
            className="cursor-pointer ml-4"
          >
            {t("generic.remove")}
          </span>
        </div>
      )}
    </div>
  );
};

export const BehaviorTeamleaderFrame = ({
  behaviorNote,
  behaviorDescription,
  behaviorId,
  value,
  signification,
  icon,
  onEdit,
  onDelete,
  isSelected,
  anyFrameIsSelected,
  bgImage,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)), url(${bgImage})`,
        backgroundSize: "cover",
      }}
      className={`flex flex-col border-1-5 ${getBorder(
        isSelected,
      )} ${getOpacity(
        anyFrameIsSelected,
        isSelected,
      )} boxShadow-custom p-4 mb-4 w-46/100 mr-4`}
    >
      <div className="flex flex-col mb-2 z-10 break-words">
        <div className="flex flex-row">
          <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        </div>
        <span className="w-full text-lightGrey my-4">{value.description}</span>
        <span className="text-darkBlue text-2xl">{behaviorDescription}</span>
      </div>
      <span className="text-darkBlue text-sm leading-100 mb-4">
        {behaviorNote}
      </span>

      {signification && (
        <>
          <span className="w-full text-darkBlue my-4">
            {t("signification.frame_header")}
          </span>
          <span className="text-lightGrey text-base leading-100 mb-4">
            {signification.note}
          </span>
        </>
      )}

      {isEmpty(signification) && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({
                id: behaviorId,
                description: behaviorDescription,
                note: behaviorNote,
                value,
              });
            }}
            className="cursor-pointer"
          >
            {t("signification.add")}
          </span>
        </div>
      )}

      {!isEmpty(signification) && onEdit && onDelete && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({
                id: behaviorId,
                description: behaviorDescription,
                note: behaviorNote,
                value,
                signification,
              });
            }}
            className="cursor-pointer"
          >
            {t("generic.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(signification.id);
            }}
            className="cursor-pointer ml-4"
          >
            {t("generic.remove")}
          </span>
        </div>
      )}
    </div>
  );
};

export const ConsequenceFrame = ({
  purpose,
  description,
  type,
  id,
  icon,
  onEdit,
  onDelete,
  isSelected,
  anyFrameIsSelected,
  frameClassname = "w-46/100 mr-4",
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col border ${getBorder(
        isSelected,
      )} p-4 mb-4 ${getOpacity(
        anyFrameIsSelected,
        isSelected,
      )} ${frameClassname}`}
    >
      <div className="flex flex-row mb-2 z-10">
        <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        <Pill className="ml-2" text={t(`generic.${type}`)} type={type} />
      </div>
      <div className="flex flex-col break-words">
        <span className="text-darkBlue text-2xl">{description}</span>
        <span className="text-grey text-base leading-100 mb-4">{purpose}</span>
      </div>
      {onEdit && onDelete && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit({ purpose, description, type, id });
            }}
            className="cursor-pointer"
          >
            {t("generic.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(id);
            }}
            className="cursor-pointer ml-4"
          >
            {t("generic.remove")}
          </span>
        </div>
      )}
    </div>
  );
};

// This frame is used for stakeholders to either create proposal consequences or add opinions on already existing consequences
export const ConsequenceStakeholderFrame = ({
  isProposal,
  icon,
  consequence,
  onEdit,
  onDelete,
  isSelected,
  frameClassname = "w-46/100 mr-4",
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col border ${getBorder(
        isSelected,
      )} p-4 mb-4 ${frameClassname}`}
    >
      <div className="flex flex-row mb-2 z-10">
        <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        <Pill
          className="ml-2"
          text={t(`generic.${consequence.type}`)}
          type={consequence.type}
        />
        {isProposal && (
          <Pill
            className="ml-2"
            text={t("generic.proposal")}
            type="isProposal"
          />
        )}
      </div>
      <div className="flex flex-col break-words">
        <span className="text-darkBlue text-2xl">
          {consequence.description}
        </span>
        <span className="text-grey text-base leading-100 mb-4">
          {consequence.purpose}
        </span>
      </div>

      {consequence.opinion && (
        <>
          <span className="w-full text-darkBlue my-1">
            {t("generic.header_opinion")}
          </span>
          <span className="text-lightGrey text-base leading-100 mb-4 break-words">
            {consequence.opinion.note}
          </span>
        </>
      )}

      {!isProposal && !consequence.opinion && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit(consequence);
              document.getElementById("scrollTop")?.scrollIntoView();
            }}
            className="cursor-pointer"
          >
            {t("opinion.add")}
          </span>
        </div>
      )}

      {!isProposal && consequence.opinion && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit(consequence);
              document.getElementById("scrollTop")?.scrollIntoView();
            }}
            className="cursor-pointer"
          >
            {t("opinion.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(consequence.opinion.id, "opinion");
            }}
            className="cursor-pointer ml-4"
          >
            {t("opinion.remove")}
          </span>
        </div>
      )}

      {isProposal && (
        <div className="flex flex-row mt-auto text-safflowerDark text-sm font-bold">
          <span
            onClick={() => {
              onEdit(consequence);
              document.getElementById("scrollTop")?.scrollIntoView();
            }}
            className="cursor-pointer"
          >
            {t("generic.edit")}
          </span>
          <span
            onClick={() => {
              onDelete(consequence.id, "consequence");
            }}
            className="cursor-pointer ml-4"
          >
            {t("generic.remove")}
          </span>
        </div>
      )}
    </div>
  );
};

export const OverviewConsequenceStakeholderFrame = ({
  isProposal,
  icon,
  consequence,
  isSelected,
  frameClassname = "w-46/100 mr-4",
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col border ${getBorder(
        isSelected,
      )} p-4 mb-4 ${frameClassname}`}
    >
      <div className="flex flex-row mb-2 z-10">
        <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        <Pill
          className="ml-2"
          text={t(`generic.${consequence.type}`)}
          type={consequence.type}
        />
        {isProposal && (
          <Pill
            className="ml-2"
            text={t("generic.proposal")}
            type="isProposal"
          />
        )}
      </div>
      <div className="flex flex-col break-words">
        <span className="text-darkBlue text-2xl">
          {consequence.description}
        </span>
        <span className="text-grey text-base leading-100 mb-4">
          {consequence.purpose}
        </span>
      </div>

      {consequence.opinion && (
        <>
          <span className="w-full text-darkBlue my-1">
            {t("generic.header_opinion")}
          </span>
          <span className="text-lightGrey text-base leading-100 mb-4">
            {consequence.opinion.note}
          </span>
        </>
      )}
    </div>
  );
};

export const PrintoutConsequenceFrame = ({
  isProposal,
  icon,
  consequence,
  frameClassname = "w-46/100 mr-4",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col border p-4 mb-4 ${frameClassname}`}>
      <div className="flex flex-row mb-2 z-10">
        <img style={{ width: 28, height: 28 }} src={icon} alt="value icon" />
        <Pill
          className="ml-2"
          text={t(`generic.${consequence?.type}`)}
          type={consequence?.type}
        />
        {isProposal && (
          <Pill
            className="ml-2"
            text={t("generic.proposal")}
            type="isProposal"
          />
        )}
      </div>
      {(consequence.role_type === constants.STAKEHOLDER ||
        consequence.role_type === constants.TEAMLEADER) && (
        <div className="text-darkBlue mb-2">
          {consequence?.user?.first_name} {consequence?.user?.last_name}
        </div>
      )}
      <div className="flex flex-col break-words">
        <span className="text-darkBlue text-2xl">
          {consequence.description}
        </span>
        <span className="text-grey text-base leading-100 mb-4">
          {consequence.purpose}
        </span>
      </div>

      {consequence.opinions && (
        <>
          <span className="w-full text-darkBlue my-1">
            {t("generic.header_opinions")}
          </span>
          {consequence.opinions.map((opinion, index) => {
            return (
              <Fragment key={index}>
                <span>
                  {opinion?.user?.first_name} {opinion?.user?.last_name}:
                </span>
                <span className="text-lightGrey text-base leading-100 mb-4">
                  {opinion?.note}
                </span>
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Frame;
