import { useEffect, useState } from "react";
import "./calendar.css";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import PlusIcon from "assets/svg/icons/PlusIcon.svg";
import ValuationDialogPopup from "components/valuationDialogPopup";
import { useContext } from "react";
import { DialogsContext } from "views/valuationDialogue";
import useApi from "hooks/useApi";
import { getValuationDialoguesEndpoint } from "api/valuationDialogues";
import { Calendar } from "./calendar";

const CalendarContainer = () => {
  const { dialogs, setDialogs } = useContext(DialogsContext);
  const { path, method } = getValuationDialoguesEndpoint();
  const [{ data, status }, makeRequest] = useApi(path, method);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isFirstHalfYear, setIsisFirstHalfYear] = useState(
    new Date().getMonth() <= 5,
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const { t } = useTranslation();

  const refetchDialogs = () => {
    makeRequest();
  };

  const onClickNext = () => {
    if (isFirstHalfYear) {
      setIsisFirstHalfYear(false);
    }
    if (!isFirstHalfYear) {
      setIsisFirstHalfYear(true);
      setCurrentYear((storedYear) => storedYear + 1);
    }
  };

  const onClickPrev = () => {
    if (isFirstHalfYear) {
      setIsisFirstHalfYear(false);
      setCurrentYear((storedYear) => storedYear - 1);
    }
    if (!isFirstHalfYear) {
      setIsisFirstHalfYear(true);
    }
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setDialogs(data);
    }
  }, [status, data, setDialogs]);

  return (
    <>
      <Calendar
        meetings={dialogs}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        year={currentYear}
        isFirstHalfYear={isFirstHalfYear}
      />

      <Button
        disabled={false}
        className="absolute bottom-4 right-4 pl-8 pr-9 py-3.5	"
        variant="action"
        onClick={() => setPopupOpen(true)}
      >
        <div className="flex items-center">
          <PlusIcon />
          <p className="font-bold ml-4">
            {t("valuation_dialog.value_dialogue")}
          </p>
        </div>
      </Button>
      <ValuationDialogPopup
        updateDialogs={refetchDialogs}
        hidePopup={() => setPopupOpen(false)}
        popupOpen={popupOpen}
      />
    </>
  );
};

export default CalendarContainer;
