import React, { useState } from "react";
import TopBar from "components/topBar";
import { useCompany } from "context/company-context";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import Button from "components/button";
import PlusIcon from "assets/svg/icons/PlusIcon.svg.js";
import AddUser from "components/addUser";
import TrashIcon from "assets/svg/icons/TrashIcon.svg";
import { useEffect } from "react";
import { getUsersEndpoint } from "api/user";
import { useAuth } from "context/auth-context";

import useApi from "hooks/useApi";
import api from "api";

const HandleUsers = () => {
  const { company } = useCompany();
  const { user: me } = useAuth();
  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const { t } = useTranslation();
  const { path: usersPath, method: usersMethod } = getUsersEndpoint();
  const [{ data: usersData, status: usersStatus }, makeUsersRequest] = useApi(
    usersPath,
    usersMethod,
  );
  const closePopup = () => {
    setAddGroupOpen(false);
  };

  const onRemoveUser = async (user) => {
    try {
      await api.delete(`/v1/user/${user.pk}/`);
    } catch {
      console.log(`Failed to remove user with ID ${user.id}`);
    }
    makeUsersRequest();
  };
  useEffect(() => {
    makeUsersRequest();
  }, [makeUsersRequest]);

  if (usersStatus === "FETCHING") {
    return <Spinner />;
  }

  return (
    <>
      <TopBar header={t("handle_users.handle_users")} />
      <div className="flex justify-center mt-16">
        <div className="px-4 pt-6 border-1-5 border-lighterGrey rounded">
          <Button
            className="flex h-10 px-5 items-center justify-between ml-auto mb-6"
            variant="action"
            onClick={() => setAddGroupOpen(true)}
          >
            <PlusIcon className="mr-4" />
            {t("handle_users.new_user")}
          </Button>
          {usersData &&
            usersData.map((user, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-center justify-between mb-6"
                >
                  <p className="text-lightGrey w-64 mr-10">
                    {user?.first_name} {user?.last_name}
                  </p>
                  <p className="text-lightGrey w-80 mr-10">{user?.email}</p>
                  {me?.pk !== user.pk && (
                    <button onClick={() => onRemoveUser(user)}>
                      <TrashIcon />
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <AddUser
        company={company}
        onCloseClick={() => closePopup()}
        isOpen={addGroupOpen}
        refetchUsers={makeUsersRequest}
      />
    </>
  );
};
export default HandleUsers;
