import visionBgLargeImage from "assets/img/backgrounds/vision_lg.png";
import { smartGoalLogo } from "assets/svg/logos";
import { useCompany } from "context/company-context";
import Menu from "components/menu";

const TopBar = ({ children, header, menu = true }) => {
  const { companyLogo, companyBackground } = useCompany();

  return (
    <>
      <div
        className="bg-center bg-no-repeat bg-cover relative z-0"
        style={{
          backgroundImage: `url(${
            companyBackground ? companyBackground : visionBgLargeImage
          })`,
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(180.03deg, rgba(255, 255, 255, 0.9) 0.02%, #FFFFFF 48.86%)",
          }}
          className="w-full pt-10 pb-10 opacity-80 z-1"
        >
          {header ? (
            <p className="leading-100 text-darkBlue font-normal text-3xl text-center w-full">
              {header}
            </p>
          ) : null}
          {children}
        </div>
        {companyLogo && (
          <img
            width="120px"
            height="120px"
            src={companyLogo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = smartGoalLogo;
            }}
            className="z-10 inline absolute top-8 left-8"
            alt="company logo"
          />
        )}
      </div>
      {menu && <Menu />}
    </>
  );
};

export default TopBar;
