import { smartGoalLogo } from "assets/svg/logos";

const Grid = ({ left, right }) => {
  return (
    <div className="flex h-screen">
      <div className="w-1/3 flex">{left}</div>
      <div className="w-2/3 flex">{right}</div>
    </div>
  );
};

const GridLeftContent = ({
  backgroundImage,
  children,
  contentImage,
  topLeftImage,
  imageAlt = "company logo",
  text,
  text_alignment = "left",
  example_text,
  example_header,
  example_title,
  header,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="h-screen w-full relative"
    >
      <div
        style={{
          background:
            "linear-gradient(180.03deg, rgba(255, 255, 255, 0.9) 0.02%, #FFFFFF 48.86%)",
        }}
        className="h-screen w-full opacity-90 absolute z-0"
      ></div>
      {topLeftImage && (
        <img
          width="50px"
          height="50px"
          src={topLeftImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = smartGoalLogo;
          }}
          className="z-10 inline absolute top-8 left-8"
          alt={imageAlt}
        />
      )}
      <div className="flex h-screen text-grey">
        <div className="m-auto z-10">
          <div className="flex flex-col m-auto w-500 items-center p-8">
            {contentImage && (
              <img src={contentImage} alt="content" className="pb-20 w-24" />
            )}
            {header && (
              <div className="text-darkBlue text-4xl text-left w-full pb-4">
                {header}
              </div>
            )}
            {text && (
              <div
                className={`text-${text_alignment} pb-8 text-base flex flex-col whitespace-pre-line`}
              >
                {text}
              </div>
            )}
            {(example_text || example_header) && (
              <div
                className={`text-center mr-auto pt-8 text-lg flex flex-col whitespace-pre-line`}
              >
                <span className="w-full text-left">{example_header}</span>
                <div className="w-full flex flex-col text-left border-l-2 border-lightBlue">
                  <span className="w-full pl-2 text-xl my-2 text-darkBlue italic">
                    {example_title}
                  </span>
                  <span className="pl-2">{example_text}</span>
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Grid, GridLeftContent };
