import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import { Grid, GridLeftContent } from "components/grid";
import Spinner from "components/spinner";
import Button from "components/button";
import Header from "components/header";
import visionBgImage from "assets/img/backgrounds/vision.jpg";
import { visionIcon } from "assets/svg/icons";
import { createVisionEndpoint, getVisionEndpoint } from "api/vision";
import { transitionCompanyToValues } from "api/company";
import useApi from "hooks/useApi";
import api from "api";
import { paths } from "routing/routes";
import { useCompany } from "context/company-context";

import { first, isEmpty } from "lodash";
import { changeLocalCompanyState } from "utils/changeLocalCompanyState";
import { smartGoalLogo } from "assets/svg/logos";

const Vision = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { company, isLoading, setCompanyState, companyLogo } = useCompany();
  const [initValues, setInitValues] = useState({});

  const { path, method } = createVisionEndpoint();
  const { path: getPath, method: getMethod } = getVisionEndpoint();

  const [{ data: visionData }, makeGetRequest] = useApi(getPath, getMethod);
  const [{ status }, makePostRequest] = useApi(path, method);
  const { path: transPath, method: transMethod } =
    transitionCompanyToValues(company);

  const [{ data: companyData, status: stateStatus }, transitionToValues] =
    useApi(transPath, transMethod);

  useEffect(() => {
    changeLocalCompanyState(stateStatus, companyData, setCompanyState);
  }, [stateStatus, companyData, setCompanyState]);

  useEffect(() => {
    makeGetRequest();
  }, [makeGetRequest]);

  useEffect(() => {
    if (!isEmpty(visionData)) {
      const vision = first(visionData);
      setInitValues(vision);
    }
  }, [visionData]);

  const onSubmit = async ({ description, purpose, id }) => {
    if (id) {
      await api.patch(`/v1/vision/${id}/`, { description, purpose });
      makeGetRequest();
    } else {
      makePostRequest({ description, purpose, company });
    }

    transitionToValues();
    history.push(paths.VALUE);
  };

  if (isLoading || status === "FETCHING") return <Spinner />;

  const fieldClassName =
    "w-full flex border p-4 border-ligtherGrey rounded h-full resize-none";

  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={visionIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("vision.header")}
          text={t("vision.description_text")}
        ></GridLeftContent>
      }
      right={
        <div className="w-full flex flex-col">
          <Header amountStep={4} currentStep={0} />
          <div className="flex flex-col items-center mx-auto mt-8 w-7/12 px-8 h-4/6 border border-greyFive">
            <Form
              initialValues={initValues}
              validate={(values) => {
                const errors = {};
                if (!values.description) {
                  errors.description = "Required";
                }
                if (!values.purpose) {
                  errors.purpose = "Required";
                }
                return errors;
              }}
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, hasValidationErrors }) => (
                <form
                  className="flex flex-col h-full w-full"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col h-2/4 pt-8">
                    <label className="flex text-2xl text-grey pb-8">
                      {t("vision.form.description")}
                    </label>
                    <Field
                      name="description"
                      type="textarea"
                      component="textarea"
                      placeholder={t("vision.form.write_here")}
                      className={fieldClassName}
                    />
                  </div>
                  <div className="flex flex-col h-2/4 pb-8">
                    <label className="flex text-2xl text-grey py-8">
                      {t("vision.form.purpose")}
                    </label>
                    <Field
                      name="purpose"
                      type="textarea"
                      component="textarea"
                      placeholder={t("vision.form.write_here")}
                      className={fieldClassName}
                    />
                  </div>
                  <Button
                    disabled={submitting || hasValidationErrors}
                    className="h-12 mt-auto absolute bottom-8 w-1/4 ml-14"
                    variant="default"
                    icon="arrow"
                    iconColor="white"
                    type="submit"
                  >
                    {t("vision.form.submit")}
                  </Button>
                </form>
              )}
            />
          </div>
        </div>
      }
    />
  );
};

export default Vision;
