import * as React from "react";

function Logo() {
  return (
    <svg
      width={140}
      height={153}
      viewBox="0 0 140 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="1"
        d="M15.601 139.339l11.697-32.506h-4.062l-7.413 20.9a58.864 58.864 0 00-2.086 7.182 59.12 59.12 0 00-2.042-7.049l-7.458-21.033H.242l11.63 32.506h3.729zm19.642 0v-18.476c0-2.668-.118-5.603-.355-8.805h.178l17.733 27.281h4.306v-32.506h-3.529v18.654c-.03.934.007 2.487.111 4.658.104 2.172.17 3.458.2 3.858h-.178l-17.689-27.17H31.76v32.506h3.484zm40.772 0V110.19H86.29v-3.357H61.966v3.357h10.276v29.149h3.773zm18.932 0v-13.518h6.769l7.857 13.518h4.461l-8.811-14.608c4.35-1.512 6.525-4.417 6.525-8.715 0-3.069-.951-5.366-2.852-6.893-1.901-1.527-4.842-2.29-8.822-2.29h-8.9v32.506h3.773zm5.171-16.764h-5.171v-12.429h4.905c2.796 0 4.827.474 6.092 1.423 1.265.948 1.898 2.497 1.898 4.647 0 2.119-.622 3.709-1.865 4.769-1.242 1.06-3.196 1.59-5.859 1.59zm25.923 17.209c3.552 0 6.363-.8 8.434-2.401 2.072-1.601 3.108-3.832 3.108-6.693 0-2.223-.64-4.032-1.92-5.425-1.28-1.393-3.622-2.698-7.025-3.913-2.027-.727-3.533-1.39-4.516-1.99-.984-.601-1.684-1.26-2.098-1.979-.414-.719-.621-1.642-.621-2.768 0-1.527.562-2.72 1.686-3.58 1.125-.86 2.686-1.289 4.684-1.289 2.53 0 5.134.563 7.812 1.689l1.176-3.29c-2.707-1.186-5.674-1.779-8.9-1.779-3.092 0-5.578.741-7.457 2.223-1.879 1.483-2.819 3.476-2.819 5.981 0 2.387.648 4.321 1.942 5.803 1.295 1.483 3.452 2.765 6.47 3.847 2.16.771 3.769 1.46 4.827 2.068 1.058.608 1.813 1.271 2.264 1.99.451.719.677 1.641.677 2.768 0 1.705-.636 3.035-1.909 3.991-1.272.956-3.166 1.434-5.681 1.434-1.539 0-3.093-.163-4.661-.489a23.324 23.324 0 01-4.35-1.334v3.646c2.071.993 5.03 1.49 8.877 1.49z"
        fill="#fff"
      />
      <path
        id="2"
        d="M15.601 139.339l11.697-32.506h-4.062l-7.413 20.9a58.864 58.864 0 00-2.086 7.182 59.12 59.12 0 00-2.042-7.049l-7.458-21.033H.242l11.63 32.506h3.729zm19.642 0v-18.476c0-2.668-.118-5.603-.355-8.805h.178l17.733 27.281h4.306v-32.506h-3.529v18.654c-.03.934.007 2.487.111 4.658.104 2.172.17 3.458.2 3.858h-.178l-17.689-27.17H31.76v32.506h3.484zm40.772 0V110.19H86.29v-3.357H61.966v3.357h10.276v29.149h3.773zm18.932 0v-13.518h6.769l7.857 13.518h4.461l-8.811-14.608c4.35-1.512 6.525-4.417 6.525-8.715 0-3.069-.951-5.366-2.852-6.893-1.901-1.527-4.842-2.29-8.822-2.29h-8.9v32.506h3.773zm5.171-16.764h-5.171v-12.429h4.905c2.796 0 4.827.474 6.092 1.423 1.265.948 1.898 2.497 1.898 4.647 0 2.119-.622 3.709-1.865 4.769-1.242 1.06-3.196 1.59-5.859 1.59zm25.923 17.209c3.552 0 6.363-.8 8.434-2.401 2.072-1.601 3.108-3.832 3.108-6.693 0-2.223-.64-4.032-1.92-5.425-1.28-1.393-3.622-2.698-7.025-3.913-2.027-.727-3.533-1.39-4.516-1.99-.984-.601-1.684-1.26-2.098-1.979-.414-.719-.621-1.642-.621-2.768 0-1.527.562-2.72 1.686-3.58 1.125-.86 2.686-1.289 4.684-1.289 2.53 0 5.134.563 7.812 1.689l1.176-3.29c-2.707-1.186-5.674-1.779-8.9-1.779-3.092 0-5.578.741-7.457 2.223-1.879 1.483-2.819 3.476-2.819 5.981 0 2.387.648 4.321 1.942 5.803 1.295 1.483 3.452 2.765 6.47 3.847 2.16.771 3.769 1.46 4.827 2.068 1.058.608 1.813 1.271 2.264 1.99.451.719.677 1.641.677 2.768 0 1.705-.636 3.035-1.909 3.991-1.272.956-3.166 1.434-5.681 1.434-1.539 0-3.093-.163-4.661-.489a23.324 23.324 0 01-4.35-1.334v3.646c2.071.993 5.03 1.49 8.877 1.49zM82.727 51.59L69.592 74.68 27.272 0h26.456l29 51.59z"
        fill="#fff"
      />
      <path
        d="M71.818 24.115l13.483 23.242L112.727 0h-27.15L71.819 24.115z"
        fill="#139938"
      />
    </svg>
  );
}

export default Logo;
