import styled from "styled-components";
import { background } from "styled-system";

import Logo from "components/logo";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  ${background}
`;

const Home = () => (
  <Main background="rgba(0,0,0,0.9)">
    <Logo />
  </Main>
);

export default Home;
