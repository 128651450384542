const StackedBar = ({ bgColor, percent, topRounded, bottomRounded }) => {
  return (
    <div
      style={{
        width: 30,
        height: percent,
        backgroundColor: bgColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderTopLeftRadius: topRounded ? 4 : 0,
        borderTopRightRadius: topRounded ? 4 : 0,
        borderBottomLeftRadius: bottomRounded ? 4 : 0,
        borderBottomRightRadius: bottomRounded ? 4 : 0,
      }}
    >
      <span className="absolute text-sm	text-darkBlue left-10">
        {percent === "0%" ? "" : percent}
      </span>
    </div>
  );
};

export default StackedBar;
