import { isEmpty } from "lodash";
import behaviorFrameBg from "assets/img/backgrounds/behavior.jpg";
import { behaviorBg } from "assets/svg/backgrounds";
import { behaviorIcon } from "assets/svg/icons";
import FrameWrapper from "components/frameWrapper";

const BehaviorStakeholderWrapper = ({
  userId,
  behaviorsData,
  selectedFrame,
  selectedValueDescription,
  onEdit,
  onDelete,
  opinionsData,
}) => {
  const groupedBehavior = {};
  behaviorsData?.forEach((behavior) => {
    if (
      selectedValueDescription &&
      selectedValueDescription !== behavior.value.description
    ) {
      return;
    }
    if (!groupedBehavior[behavior.value.description]) {
      groupedBehavior[behavior.value.description] = [];
    }

    const behaviorOpinion = opinionsData.find(
      (opinion) =>
        opinion?.behavior?.id === behavior.id && opinion?.user?.id === userId
    );
    if (behaviorOpinion) {
      behavior.opinion = behaviorOpinion;
    }

    groupedBehavior[behavior.value.description].push(behavior);
  });

  if (isEmpty(groupedBehavior)) {
    return <img className="m-auto" src={behaviorBg} alt="value background" />;
  }

  return (
    <>
      {Object.keys(groupedBehavior).map((value, index) => {
        return (
          <div key={index}>
            <span className="text-darkBlue text-2xl mb-2">{value}</span>
            <FrameWrapper
              frames={groupedBehavior[value]}
              frameBg={behaviorFrameBg}
              selectedFrame={selectedFrame}
              useAnyFrameIsSelected={true}
              bgImage={behaviorBg}
              icon={behaviorIcon}
              onEdit={onEdit}
              onDelete={onDelete}
              type="behaviorStakeholder"
            />
          </div>
        );
      })}
    </>
  );
};

export default BehaviorStakeholderWrapper;
