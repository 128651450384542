import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";

import {
  setAuthCookie,
  deleteAuthCookie,
  getRefreshCookie,
  getAccessCookie,
  setXGroupId,
  getXGroupId,
  setGroupId,
  deleteGroupCookie,
} from "utils/auth";

import useApi from "hooks/useApi";
import { userEndpoint, loginEndpoint, logoutEndpoint } from "api/auth";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [user, setUser] = useState({});
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [selectedGroup, setSelectedGroupState] = useState(getXGroupId());

  const setSelectedGroup = (groupId) => {
    setGroupId(groupId);
    setSelectedGroupState(groupId);
  };

  const { path, method } = userEndpoint();
  const { path: loginPath, method: loginMethod } = loginEndpoint();
  const { path: logoutPath, method: logoutMethod } = logoutEndpoint();

  const [{ data, status }, makeRequest] = useApi(path, method);
  const [{ data: loginData, status: loginStatus }, makeRequestLogin] = useApi(
    loginPath,
    loginMethod,
  );
  const [, makeRequestLogout] = useApi(logoutPath, logoutMethod);
  const fetchUser = useCallback(() => {
    const refresh_token = getRefreshCookie();
    const access_token = getAccessCookie();
    if (refresh_token && access_token) {
      makeRequest();
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
  }, [makeRequest]);

  const login = (email, password) => {
    makeRequestLogin({ email: email, password: password });
  };

  const logout = () => {
    makeRequestLogout();
    deleteAuthCookie();
    deleteGroupCookie();
    setAuthenticated(false);
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (status === "SUCCESS" && data) {
      setXGroupId(data.groups);
      setUser(data);
      setAuthenticated(true);
      setLoading(false);
    } else if (status === "ERROR") {
      setAuthenticated(false);
      setLoading(false);
    }
  }, [status, data]);

  useEffect(() => {
    if (loginStatus === "SUCCESS" && loginData) {
      setAuthCookie(loginData.access, loginData.refresh);
      fetchUser();
    } else if (loginStatus === "ERROR") {
      setAuthenticated(false);
      setLoading(false);
      setAuthError(true);
    }
  }, [loginData, loginStatus, fetchUser]);

  const sharedProps = {
    login,
    logout,
    user,
    isAuthenticated,
    isLoading,
    authError,
    selectedGroup,
    setSelectedGroup,
  };

  return <AuthContext.Provider value={{ ...sharedProps }} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
