import Cookies from "universal-cookie";

const cookies = new Cookies();
const AUTH_COOKIE = "Authorization";
const ACCESS_COOKIE = "access_token";
const REFRESH_COOKIE = "refresh_token";
const X_GROUP_ID = "x_group_id";

const setCookie = (name, value) => {
  cookies.set(name, value, { maxAge: 86400, path: "/" });
};

export const setAuthCookie = (access_token, refresh_token) => {
  setCookie(
    AUTH_COOKIE,
    JSON.stringify({
      [ACCESS_COOKIE]: access_token,
      [REFRESH_COOKIE]: refresh_token,
    }),
  );
};
export const deleteAuthCookie = () => {
  cookies.remove(AUTH_COOKIE);
};

export const deleteGroupCookie = () => {
  cookies.remove(X_GROUP_ID);
};

export const setXGroupId = (userGroups) => {
  const currentGroup = getXGroupId();

  // Current group is already set
  if (Number.isInteger(currentGroup)) {
    return;
  }

  if (userGroups && Array.isArray(userGroups) && userGroups.length > 0) {
    const groupId = userGroups[0]?.id;
    setCookie(X_GROUP_ID, groupId);
  }
};

export const setGroupId = (groupId) => {
  setCookie(X_GROUP_ID, groupId);
};

export const getXGroupId = () => {
  return parseInt(cookies.get(X_GROUP_ID));
};

export const getAuthCookie = () => {
  return cookies.get(AUTH_COOKIE);
};

export const getAccessCookie = () => {
  const authCookie = getAuthCookie();
  return authCookie?.[ACCESS_COOKIE];
};

export const getRefreshCookie = () => {
  const authCookie = getAuthCookie();
  return authCookie?.[REFRESH_COOKIE];
};
