import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import { Form, Field } from "react-final-form";
import api from "api";

const EditGroup = ({
  isOpen = false,
  onCloseClick,
  refetchGroups,
  managers,
  group,
}) => {
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    let users = group.users.map((user) => user.id);
    if (!users.find((user) => user === Number(values.manager))) {
      users = [Number(values.manager), ...users];
    }

    try {
      await api.patch(`/v1/group/${group.id}/`, { users, ...values });
    } catch {
      console.log(`Failed to edit group ${group?.id} with values ${values}`);
    }
    onCloseClick();
    refetchGroups();
  };

  if (!isOpen) return <></>;

  return (
    <div className="absolute w-screen top-0 flex mt-56 justify-center">
      <div className="relative flex flex-col  bg-white shadow-popUp rounded px-4 pt-12 w-4/12">
        <RoundedCloseIcon
          onClick={() => {
            onCloseClick();
          }}
          className="absolute top-4 right-2 "
        />
        <h3 className="text-2xl text-darkBlue mb-6">
          {t("handle_groups.change_teamleader")}
        </h3>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit} className="flex flex-col mb-12">
                <Field
                  className="flex rounded-md border-2 border-lighterGrey w-full h-8 pl-2 mt-4"
                  name="manager"
                  component="select"
                >
                  {group.manager ? (
                    <option value={group.manager.id}>
                      {group.manager.email}
                    </option>
                  ) : (
                    <option>{t("handle_groups.choose_teamleader")}</option>
                  )}

                  {managers.map((manager) => {
                    if (group?.manager?.id !== manager.pk) {
                      return (
                        <option value={manager.pk}>{manager.email}</option>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Field>
                <Button
                  disabled={submitting}
                  className="h-10 w-2/6 ml-auto mt-4"
                  type="submit"
                  variant="default"
                >
                  {t("opinion.form.submit")}
                </Button>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default EditGroup;
