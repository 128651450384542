import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg";
import Button from "components/button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "api";
import { validEmailExp } from "utils/regularExpressions";

const PopUp = ({
  isOpen = false,
  isEdit = false,
  isAdd = false,
  user,
  onCloseClick,
  makeGroupRequest,
}) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const editUser = async () => {
    if (isEdit) {
      try {
        await api.patch(`/v1/user/${user.id}/`, {
          first_name: firstName || "x",
          last_name: lastName || "x",
        });
      } catch {
        // TODO: Implement proper error handling here.
        console.log(`Failed to edit user with ID ${user.id}`);
      }
    }
    onCloseClick();
    makeGroupRequest();
  };

  useEffect(() => {
    setFirstName(user ? user?.first_name : "");
    setLastName(user ? user?.last_name : "");
  }, [user]);

  if (!isOpen) return <></>;

  return (
    <div className="absolute w-screen top-0 flex mt-56 justify-center">
      <div className="relative flex flex-col  bg-white shadow-popUp rounded px-4 pt-12">
        <RoundedCloseIcon
          onClick={() => {
            onCloseClick();
          }}
          className="absolute top-4 right-2 "
        />
        <h3 className="text-2xl text-darkBlue mb-6">
          {isEdit && t("handle_group_members.edit_group_member")}
          {isAdd && t("handle_group_members.new_group_member")}
        </h3>
        {(isEdit || isAdd) && (
          <form className="flex flex-col">
            <label className="mb-2 text-grey" htmlFor="">
              {t("handle_group_members.first_name")}
            </label>
            <input
              className="border-1-5 mb-6 h-12 px-4 text-base text-grey rounded border-lighterGrey w-96"
              type="text"
              value={isEdit ? firstName : ""}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label className="mb-2 text-grey" htmlFor="">
              {t("handle_group_members.last_name")}
            </label>
            <input
              className="border-1-5 mb-6 h-12 px-4 text-base text-grey rounded border-lighterGrey w-96"
              type="text"
              value={isEdit ? lastName : ""}
              onChange={(e) => setLastName(e.target.value)}
            />
          </form>
        )}

        <Button
          className="h-12 pl-5 pr-5 self-end mt-4 mb-6"
          variant="action"
          onClick={() => {
            editUser();
          }}
        >
          {t("handle_group_members.save")}
        </Button>
      </div>
    </div>
  );
};

export default PopUp;
