import { format, getMonth, getYear } from "date-fns";
import { MeetingCard } from "./meetingCard";
import { sv } from "date-fns/locale";
export const MonthCard = ({ month, meetings, year }) => {
  const date = new Date(year, month);
  const monthSE = format(date, "LLLL", { locale: sv });
  return (
    <div className="p-5 monthCard">
      <p className="text-base text-grey font-normal text-2xl capitalize">
        {monthSE}
      </p>
      <div className=" grid grid-cols-2 gap-2">
        {meetings
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((meeting, index) => {
            const meetingMonth = getMonth(new Date(meeting.date));
            const meetingYear = getYear(new Date(meeting.date));
            const currentMonth = new Date(year, month).getMonth();
            let prevDialogId = null;
            if (typeof meetings[index - 1] !== "undefined") {
              prevDialogId = meetings[index - 1].id;
            }

            if (meetingMonth === currentMonth && meetingYear === year)
              return (
                <MeetingCard
                  prevDialogId={prevDialogId}
                  meeting={meeting}
                  key={index}
                />
              );
            else return null;
          })}
      </div>
    </div>
  );
};
