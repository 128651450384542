import { editIcon, removeIcon } from "assets/svg/icons";

import { format } from "date-fns";
import sv from "date-fns/locale/sv";
import useApi from "hooks/useApi";
import { deleteValuationDialogNoteEndpoint } from "api/valuationDialogues";
import { useEffect } from "react";

export const Note = ({ dialogId, note, index, onEditClick, refetchNotes }) => {
  const { path, method } = deleteValuationDialogNoteEndpoint(
    dialogId,
    note?.id,
  );
  const [{ status }, makeDeleteRequest] = useApi(path, method);
  const date = format(new Date(note.created_at), "dd MMMM yyyy", {
    locale: sv,
  });

  useEffect(() => {
    if (status === "SUCCESS") {
      refetchNotes();
    }
  }, [status, refetchNotes]);

  return (
    <div className="border border-greyFive p-3 rounded-md mb-3" key={index}>
      <p className="text-lightGrey font-normal text-sm uppercase mb-2">
        {date}
      </p>
      <p className="text-grey">{note.text}</p>
      <div className="flex flex-row justify-end mt-2">
        <button onClick={() => onEditClick(note)}>
          <img src={editIcon} alt="edit" className="mr-3" />
        </button>
        <button onClick={makeDeleteRequest}>
          <img src={removeIcon} alt="delete" />
        </button>
      </div>
    </div>
  );
};
