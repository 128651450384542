import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import Button from "components/button";
import TopBar from "components/topBar";
import { useEffect } from "react";
import { getMeEndpoint } from "api/user";
import { Form, Field } from "react-final-form";
import api from "api";
import useApi from "hooks/useApi";

const UserSettings = () => {
  const { t } = useTranslation();
  const { path: usersPath, method: usersMethod } = getMeEndpoint();
  const [{ data: userData, status: usersStatus }, makeUsersRequest] = useApi(
    usersPath,
    usersMethod,
  );

  const onSubmit = async (values) => {
    if (values.first_name === "") {
      values.first_name = "x";
    }
    if (values.last_name === "") {
      values.last_name = "x";
    }

    try {
      await api.patch("/v1/me/", { ...values });
    } catch {
      console.log(`Failed to create invite with data ${values}`);
    }
    document.location.reload();
  };

  useEffect(() => {
    makeUsersRequest();
  }, [makeUsersRequest]);

  if (usersStatus === "FETCHING") {
    return <Spinner />;
  }
  const identity = (value) => value;

  return (
    <>
      <TopBar header={t("generic.settings")} />
      <div className="flex justify-center mt-16">
        <div className="px-4 pt-6 border-1-5 w-1/4 border-lighterGrey rounded">
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, values }) => {
              return (
                <form onSubmit={handleSubmit} className="flex flex-col mb-12">
                  <div className="flex flex-col">
                    <label htmlFor="label" className="mb-2 text-base text-grey">
                      {t("user_settings.first_name")}:
                    </label>
                    <Field
                      className="flex rounded-md border-2 border-lighterGrey w-full h-8 pl-2 mb-4"
                      name="first_name"
                      type="text"
                      component="input"
                      placeholder="Förnamn"
                      defaultValue={userData?.first_name}
                      parse={identity}
                    />
                    <label htmlFor="label" className="mb-2 text-base text-grey">
                      {t("user_settings.last_name")}:
                    </label>
                    <Field
                      className="flex rounded-md border-2 border-lighterGrey w-full h-8 pl-2"
                      name="last_name"
                      type="text"
                      component="input"
                      placeholder="Efternamn"
                      defaultValue={userData?.last_name}
                      parse={identity}
                    />
                  </div>

                  <Button
                    disabled={submitting}
                    className="h-10 w-2/6 ml-auto mt-4"
                    type="submit"
                    variant="default"
                  >
                    {t("opinion.form.submit")}
                  </Button>
                </form>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
export default UserSettings;
