import { getSignificationsRequest } from "api/signification";
import { useEffect, useState, useCallback } from "react";

export const useSignifications = () => {
  const [significations, setSignificantions] = useState([]);

  const fetch = useCallback(async () => {
    try {
      const { data } = await getSignificationsRequest();
      setSignificantions(data);
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    significations,
  };
};
