import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import api from "api";
import {
  getConsequenceEndpoint,
  createConsequenceEndpoint,
} from "api/consequence";
import { getOpinionsEndpoint } from "api/opinion";
import { getRolesEndpoint } from "api/role";
import useApi from "hooks/useApi";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import { paths } from "routing/routes";
import visionBgImage from "assets/img/backgrounds/vision.jpg";
import { consequenceIcon } from "assets/svg/icons";
import { consequenceBg } from "assets/svg/backgrounds";
import { smartGoalLogo } from "assets/svg/logos";
import constants from "utils/constants";
import OpinionForm from "./opinionForm";
import ConsequenceForm from "./consequenceForm";
import { Grid, GridLeftContent } from "components/grid";
import Button from "components/button";
import Header from "components/header";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";

const ConsequenceStakeholder = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useAuth();
  const { company: companyId, isLoading, companyLogo } = useCompany();
  const { path: getConsequencePath, method: getConsequenceMethod } =
    getConsequenceEndpoint();
  const [
    { data: consequenceData, status: getConsequenceStatus },
    makeGetConsequenceRequest,
  ] = useApi(getConsequencePath, getConsequenceMethod);
  const { path: postConsequencePath, method: postConsequenceMethod } =
    createConsequenceEndpoint();
  const [{ status: postConsequenceStatus }, makePostConsequenceRequest] =
    useApi(postConsequencePath, postConsequenceMethod);
  const { path: opinionsPath, method: opinionsMethod } = getOpinionsEndpoint();
  const [{ data: opinionsData, status: opinionsStatus }, makeOpinionsRequest] =
    useApi(opinionsPath, opinionsMethod);
  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData, status: roleStatus }, makeRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );

  const userId = user.pk;
  const role = roleData?.find(
    (role) => role?.user === userId && role?.company?.id === companyId,
  );

  const [formValues, setFormValues] = useState({});
  const [selectedFrame, setSelectedFrame] = useState(null);

  useEffect(() => {
    makeGetConsequenceRequest();
    makeOpinionsRequest();
    makeRoleRequest();
  }, [makeGetConsequenceRequest, makeOpinionsRequest, makeRoleRequest]);

  useEffect(() => {
    if (postConsequenceStatus === "SUCCESS") {
      makeGetConsequenceRequest();
      makeOpinionsRequest();
    }
  }, [postConsequenceStatus, makeGetConsequenceRequest, makeOpinionsRequest]);

  const onDelete = async (id, objectType) => {
    try {
      await api.delete(`/v1/${objectType}/${id}/`);
    } catch {
      // TODO: Implement proper error handling here.
      console.log(`Failed to delete ${objectType} with ID ${id}`);
    }
    reFetch();
  };

  const onEdit = (consequence) => {
    const { id, description, purpose, type, opinion } = consequence;
    setFormValues({ id, description, purpose, type, opinion });
    setSelectedFrame(consequence);
  };

  const reFetch = () => {
    makeGetConsequenceRequest();
    makeOpinionsRequest();
    setFormValues({});
    setSelectedFrame(null);
  };

  const sortConsequences = (consequences) => {
    return consequences?.sort(function (a, b) {
      if (a.role_type === constants.STAKEHOLDER) {
        return -1;
      }
      if (b.role_type === constants.STAKEHOLDER) {
        return 1;
      }
      return 0;
    });
  };

  const getConsequences = () => {
    const filteredConsequences = consequenceData?.filter(
      (consequence) =>
        (consequence.role_type === constants.STAKEHOLDER &&
          consequence?.user?.id === userId) ||
        constants.PRE_PROCESS_ROLES.includes(consequence.role_type),
    );
    const consequencesWithOpinions = filteredConsequences?.map(
      (consequence) => {
        const consequenceOpinion = opinionsData?.find(
          (opinion) =>
            opinion?.consequence?.id === consequence.id &&
            opinion?.user?.id === userId,
        );

        if (consequenceOpinion) {
          consequence.opinion = consequenceOpinion;
        }
        return consequence;
      },
    );
    return sortConsequences(consequencesWithOpinions);
  };

  if (
    isLoading ||
    getConsequenceStatus === "FETCHING" ||
    opinionsStatus === "FETCHING" ||
    roleStatus === "FETCHING"
  ) {
    return <Spinner />;
  }

  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={consequenceIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("consequence.stakeholder_header")}
          text={t("consequence.stakeholder_description_text")}
          example_title={t("consequence.example.title")}
          example_header={t("consequence.example.header")}
        ></GridLeftContent>
      }
      right={
        <div className="w-full flex flex-col">
          <Header showOnlyMenu />
          <div className="flex flex-row h-4/5 mt-10 overflow-auto">
            <div
              className="flex flex-col ml-10 w-35/100 p-8 h-full border border-greyFive"
              id="scrollTop"
            >
              {selectedFrame &&
              selectedFrame?.role_type !== constants.STAKEHOLDER ? (
                <OpinionForm
                  {...selectedFrame}
                  userId={userId}
                  setSelectedFrame={setSelectedFrame}
                  consequence={selectedFrame}
                  setFormValues={setFormValues}
                  onComplete={reFetch}
                />
              ) : (
                <ConsequenceForm
                  makeRequest={makeGetConsequenceRequest}
                  createMakeRequest={makePostConsequenceRequest}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  selectedFrame={selectedFrame}
                  setSelectedFrame={setSelectedFrame}
                  company={companyId}
                  userId={userId}
                  roleType={role?.type}
                />
              )}
            </div>
            <div className="flex flex-col w-2/3 ml-10 mr-2">
              <FrameWrapper
                frames={getConsequences()}
                selectedFrame={selectedFrame?.id}
                bgImage={consequenceBg}
                icon={consequenceIcon}
                onEdit={onEdit}
                onDelete={onDelete}
                useAnyFrameIsSelected
                type="consequenceStakeholder"
              />
            </div>
          </div>
          <div className="flex flex-row w-full mt-10">
            <Button
              disabled={false}
              className="h-12 w-1/3 mx-auto"
              variant="default"
              icon="arrow"
              iconColor="white"
              onClick={() => history.push(paths.OVERVIEW)}
            >
              {t("opinion.navigate_next")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default ConsequenceStakeholder;
