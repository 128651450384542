import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "routing/routes";

const Logout = () => {
  const { logout } = useAuth();
  const { resetCompany } = useCompany();
  const history = useHistory();

  useEffect(() => {
    logout();
    resetCompany();
    history.push(paths.LOGIN);
  });

  return null;
};

export default Logout;
