const constants = {
  EXECUTIVE: "executive",
  CONSULTANT: "consultant",
  EMPLOYEE: "employee",
  STAKEHOLDER: "stakeholder",
  TEAMLEADER: "teamleader",
  PRE_PROCESS_ROLES: ["executive", "consultant"],
};

export default constants;
