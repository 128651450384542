import React, { useState } from "react";
import TopBar from "components/topBar";
import { useCompany } from "context/company-context";
import { useTranslation } from "react-i18next";
import Menu from "components/menu";
import Spinner from "components/spinner";
import Button from "components/button";
import PlusIcon from "assets/svg/icons/PlusIcon.svg.js";
import PenIcon from "assets/svg/icons/PenIcon.svg";
import AddGroup from "components/addGroup";
import EditGroup from "components/editGroup";
import { useEffect } from "react";
import { getGroupsEndpoint } from "api/group";
import { getUsersEndpoint } from "api/user";

import useApi from "hooks/useApi";

const HandleGroups = () => {
  const { company } = useCompany();
  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const { t } = useTranslation();

  const { path: groupPath, method: groupMethod } = getGroupsEndpoint();

  const [{ data: groupData, status: groupStatus }, makeGroupRequest] = useApi(
    groupPath,
    groupMethod,
  );

  const { path: usersPath, method: usersMethod } = getUsersEndpoint();

  const [{ data: usersData, status: usersStatus }, makeUsersRequest] = useApi(
    usersPath,
    usersMethod,
  );

  const closePopup = () => {
    setAddGroupOpen(false);
    setEditGroupOpen(false);
  };

  useEffect(() => {
    makeGroupRequest();
    makeUsersRequest();
  }, [makeGroupRequest, makeUsersRequest]);

  useEffect(() => {
    if (usersData && company) {
      let idSet = new Set();
      usersData.forEach((user) => {
        if (
          !user.roles.find(
            (role) =>
              (role.type === "employee" && role.company === company) ||
              (role.type === "teamleader" && role.company === company),
          )
        ) {
          idSet.add(user["pk"]);
        }
      });
      setFilteredUsersData(usersData.filter((obj) => !idSet.has(obj.pk)));
    }
  }, [usersData, setFilteredUsersData, company]);

  if (groupStatus === "FETCHING" || usersStatus === "FETCHING") {
    return <Spinner />;
  }
  return (
    <>
      <TopBar header={t("menu.pre_process.handle_groups")} />
      <div className="flex justify-center mt-16">
        <div className="px-4 pt-6 border-1-5 border-lighterGrey rounded">
          <Button
            className="flex h-10 px-5 items-center justify-between ml-auto mb-6"
            variant="action"
            onClick={() => setAddGroupOpen(true)}
          >
            <PlusIcon className="mr-4" />
            {t("handle_groups.new_group")}
          </Button>
          {groupData &&
            groupData.map((group, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-center justify-between mb-6"
                >
                  <p className="text-lightGrey w-64 mr-10">{group?.name}</p>
                  <p className="text-lightGrey w-80 mr-10">
                    {group?.manager?.email}
                  </p>
                  <div className="flex">
                    <button
                      onClick={() => {
                        setSelectedGroup(group);
                        setEditGroupOpen(true);
                      }}
                    >
                      <PenIcon style={{ marginRight: 16 }} />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <EditGroup
        onCloseClick={() => closePopup()}
        managers={filteredUsersData}
        isOpen={editGroupOpen}
        group={selectedGroup}
        refetchGroups={makeGroupRequest}
      />
      <AddGroup
        company={company}
        onCloseClick={() => closePopup()}
        isOpen={addGroupOpen}
        refetchGroups={makeGroupRequest}
        managers={filteredUsersData}
      />
    </>
  );
};
export default HandleGroups;
