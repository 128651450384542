const PenIcon = ({ ...props }) => (
  <svg
    width={15}
    height={16}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.562 2.524 1.418-1.418a.469.469 0 0 1 .663 0l1.75 1.75a.469.469 0 0 1 0 .663l-1.418 1.419-2.413-2.414Zm-.663.663L1.48 11.606a.468.468 0 0 0-.113.183l-.874 2.625a.469.469 0 0 0 .592.593l2.625-.875a.474.474 0 0 0 .184-.113L12.312 5.6 9.899 3.187Z"
      fill="#316DF0"
    />
  </svg>
);

export default PenIcon;
