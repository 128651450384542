import React from "react";
import { useTranslation } from "react-i18next";

const ChartLegends = () => {
  const FEEDBACK_LEVELS = [
    { translateKey: "good", color: "bg-limeGreen" },
    { translateKey: "needs_improvement", color: "bg-softYellow" },
    { translateKey: "must_be_addressed", color: "bg-softRed" },
    { translateKey: "did_not_answer", color: "bg-gray" },
  ];
  const { t } = useTranslation();

  return (
    <div className="flex flex-row px-2 mt-8 justify-center">
      {FEEDBACK_LEVELS.map(({ translateKey, color }) => {
        return (
          <div key={translateKey} className="flex flex-row items-center mx-2">
            <div className={`w-2 h-2 ${color} rounded mr-2`} />
            <p className="text-xs text-darkBlue">
              {t(`meeting_overview.${translateKey}`)}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ChartLegends;
