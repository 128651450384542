import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";
import useApi from "hooks/useApi";
import { getCompanyOverviewEndpoint } from "api/company";

import { valueIcon, consequenceIcon, behaviorIcon } from "assets/svg/icons";
import { smartGoalLogo } from "assets/svg/logos";
import constants from "utils/constants";

import Frame from "components/frame";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";
import OverviewVision from "components/overviewVision";

import styled from "styled-components";

const StyledBehavior = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 2px;
  height: min-content;
  padding: 0.5rem;
  margin-top: 1rem;
`;

const StyledBehaviorTitle = styled.span`
  color: rgba(25, 53, 87);
`;

const StyledPageBreak = styled.div`
  page-break-after: always;
`;

const StakeholderIndividualPrintout = () => {
  const { t } = useTranslation();
  const { company, isLoading, companyLogo } = useCompany();
  const { user } = useAuth();

  const { path, method } = getCompanyOverviewEndpoint(company);
  const [{ data, status }, makeRequest] = useApi(path, method);
  const userId = user.pk;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    makeRequest();
  }, [isLoading, makeRequest]);

  const getRelatedBehaviors = (valueId) => {
    return data?.behaviors.filter((b) => {
      const opinion = data.opinions?.find(
        (opinion) =>
          opinion?.behavior?.id === b.id && opinion?.user?.id === userId,
      );
      b.opinion = opinion;
      return b.value.id === valueId;
    });
  };

  const stakeholderConsequences = data?.consequences?.filter(
    (consequence) =>
      constants.STAKEHOLDER === consequence.role_type &&
      consequence?.user?.id === userId,
  );

  const consequences = data?.consequences?.filter((consequence) => {
    const opinion = data.opinions?.find(
      (opinion) =>
        opinion?.consequence?.id === consequence.id &&
        opinion?.user?.id === userId,
    );
    if (opinion) {
      consequence.opinion = opinion;
    }
    return constants.PRE_PROCESS_ROLES.includes(consequence.role_type);
  });

  if (status === "FETCHING" || isLoading) return <Spinner />;

  return (
    <>
      {companyLogo && (
        <img
          width="50px"
          height="50px"
          src={companyLogo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = smartGoalLogo;
          }}
          className="z-10 inline absolute top-8 left-8"
          alt="company logo"
        />
      )}

      <OverviewVision vision={data?.vision} isPrintout />

      <StyledPageBreak />

      <div className="mt-8 mr-8 ml-8">
        <div className="col-span-7 p-6 mb-4 rounded-md ">
          <span className="text-base leading-100 mb-6 text-darkBlue font-normal text-3xl">
            {t("generic.header_values_behaviors")}
          </span>

          <div className="flex flex-wrap w-full mt-8">
            {data?.values?.map((v, index) => {
              return (
                <div key={index}>
                  <Frame
                    frameClassname="w-46/100 ml-2 mr-2"
                    icon={valueIcon}
                    purpose={v.purpose}
                    description={v.description}
                    bottomContent={
                      <>
                        {getRelatedBehaviors(v.id).map((b, i) => {
                          return (
                            <StyledBehavior key={i}>
                              <img
                                src={behaviorIcon}
                                alt="behaviorIcon"
                                className="inline"
                              />

                              <StyledBehaviorTitle>
                                {b.description}
                              </StyledBehaviorTitle>

                              <div className="pt-4 px-2 mb-4 text-base text-grey font-semibolds">
                                <p>{b.note}</p>
                              </div>
                              {b?.opinion && (
                                <>
                                  <div className="py-1 px-2 ml-5 text-darkBlue font-semibold text-base">
                                    {t("generic.header_opinion")}
                                  </div>
                                  <div className="px-2 mb-4 ml-5 text-sm text-grey font-semibolds">
                                    {b?.opinion?.note}
                                  </div>
                                </>
                              )}
                            </StyledBehavior>
                          );
                        })}
                      </>
                    }
                  />
                  {/* Not quite working */}
                  {index % 2 === 1 && <StyledPageBreak />}
                </div>
              );
            })}
          </div>
        </div>

        <StyledPageBreak />

        <div className="col-span-5 p-6 mb-4 rounded-md ">
          <div className="grid grid-cols-12">
            <div className="col-start-1 col-span-8">
              <span className="text-base leading-100 mb-6 text-darkBlue font-normal text-3xl">
                {t("generic.header_consequences")}
              </span>
            </div>
          </div>

          <div className="flex flex-wrap w-full mt-8">
            <>
              <FrameWrapper
                frames={stakeholderConsequences}
                icon={consequenceIcon}
                type="overviewConsequenceStakeholder"
                useAnyFrameIsSelected={false}
                frameClassname="w-46/100 mx-2"
              />
              <FrameWrapper
                frames={consequences}
                icon={consequenceIcon}
                type="overviewConsequenceStakeholder"
                useAnyFrameIsSelected={false}
                frameClassname="w-46/100 mx-2"
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default StakeholderIndividualPrintout;
