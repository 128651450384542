import React, { Suspense } from "react";
import ThemeProvider from "components/theme";
import { AuthProvider } from "context/auth-context";
import { CompanyProvider } from "context/company-context";
import Router from "routing";

function App() {
  return (
    <Suspense fallback="Loading...">
      <ThemeProvider>
        <AuthProvider>
          <CompanyProvider>
            <Router />
          </CompanyProvider>
        </AuthProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
