import { useReducer, useCallback } from "react";
import api from "api/index";
import reducer, { initialState } from "hooks/useApi/reducer";
import { fetching, success, error } from "hooks/useApi/actions";

export const axiosMethod = (method) => {
  switch (method) {
    case "POST":
      return api.post;
    case "PUT":
      return api.put;
    case "DELETE":
      return api.delete;
    case "PATCH":
      return api.patch;
    case "GET":
      return api.get;
    default:
      return api.get;
  }
};
const useApi = (endpoint, method) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const makeRequest = useCallback(
    async (options) => {
      dispatch(fetching());
      try {
        const response = await axiosMethod(method)(endpoint, options); // apiParams
        dispatch(success(response));
        return response;
      } catch ({ response }) {
        dispatch(error(response));
      }
    },
    [endpoint, method],
  );

  return [
    { state, data: state.response?.data, status: state.status },
    makeRequest,
  ];
};

export default useApi;
