import React from "react";
import CalendarIcon from "assets/svg/icons/CalendarIcon.svg.js";
import ClockIcon from "assets/svg/icons/ClockIcon.svg.js";
import DropDownArrow from "assets/svg/icons/DropDownArrow.svg.js";
import Dropdown from "rc-dropdown";
const DropDown = ({ hideIcon, showClock, overlay, label, onVisibleChange }) => {
  return (
    <div className="border-1-5 rounded border-lighterGrey px-5 pt-5 pb-3">
      <div>
        <Dropdown
          trigger={["click"]}
          overlay={overlay}
          animation="slide-up"
          onVisibleChange={onVisibleChange}>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              {hideIcon ? null : showClock ? <ClockIcon /> : <CalendarIcon />}
              <span className={hideIcon ? "text-lightGrey" : "ml-3"}>
                {label}
              </span>
            </div>
            <DropDownArrow />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default DropDown;
