import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { isEmpty } from "lodash";
import { useCompany } from "context/company-context";
import { paths } from "routing/routes";
import useApi from "hooks/useApi";
import api from "api";
import { getValuesEndpoint } from "api/value";
import { createBehaviorEndpoint, getBehaviorsEndpoint } from "api/behavior";
import { Grid, GridLeftContent } from "components/grid";
import Button from "components/button";
import Header from "components/header";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";
import { behaviorIcon } from "assets/svg/icons";
import { valueIcon } from "assets/svg/icons";
import { behaviorBg } from "assets/svg/backgrounds";
import visionBgImage from "assets/img/backgrounds/vision.jpg";
import behaviorFrameBg from "assets/img/backgrounds/behavior.jpg";
import { transitionCompanyToConsequences } from "api/company";
import { changeLocalCompanyState } from "utils/changeLocalCompanyState";
import { smartGoalLogo } from "assets/svg/logos";

const NOTE_FIELD_MAXLENGTH = 350;

const BehaviorForm = ({
  id,
  description,
  setSelected,
  setSelectedBehavior,
  behavior,
  onComplete,
}) => {
  const { t } = useTranslation();
  const { company, isLoading } = useCompany();
  const { path, method } = createBehaviorEndpoint();
  const [{ status }, makeRequest] = useApi(path, method);

  useEffect(() => {
    if (status === "SUCCESS") {
      setSelectedBehavior(null);
      onComplete();
    }
    // TODO: Implement error handling here.
  }, [status, setSelected, setSelectedBehavior, onComplete]);

  const onSubmit = async ({ description, note, behavior_id }) => {
    if (behavior_id) {
      try {
        await api.patch(`/v1/behavior/${behavior_id}/`, { description, note });
      } catch {
        // TODO: Implement proper error handling here.
        console.log(`Failed to delete behavior with ID ${id}`);
      }
      setSelectedBehavior(null);
      onComplete();
    } else {
      makeRequest({ description, note, value: id, company });
    }
  };

  const serializeInitialData = (behavior) => {
    if (!behavior) {
      return null;
    }
    const { id, ...rest } = behavior;
    return { behavior_id: id, ...rest };
  };

  if (!id || isLoading) {
    return null;
  }

  const fieldClassName =
    "w-full flex border p-4 border-ligtherGrey resize-none";

  return (
    <>
      <span className="text-grey font-bold mb-4">
        {`${t("value.value")}: ${description}`}
      </span>
      <Form
        initialValues={serializeInitialData(behavior)}
        validate={(values) => {
          const errors = {};
          if (!values.description) {
            errors.description = "Required";
          }
          return errors;
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, hasValidationErrors, values }) => (
          <form className="flex flex-col h-full w-full" onSubmit={handleSubmit}>
            <div className="flex-col py-4">
              <label className="flex text-2xl pb-4">
                {t("behavior.form.description")}
              </label>
              <Field
                name="description"
                type="text"
                component="input"
                placeholder={t("value.form.write_here")}
              >
                {({ input }) => (
                  <div className="relative">
                    <input className={fieldClassName} {...input} />
                  </div>
                )}
              </Field>
            </div>
            <div className="flex-col h-full py-4">
              <label className="flex text-2xl pb-4">
                {t("behavior.form.note")}
              </label>
              <Field
                name="note"
                type="textarea"
                component="textarea"
                placeholder={t("value.form.write_here")}
                className={`${fieldClassName} h-5/6`}
                maxLength={NOTE_FIELD_MAXLENGTH}
              />
              <div className="flex justify-end pt-1">
                {(values.note || "").length}/ {NOTE_FIELD_MAXLENGTH}
              </div>
            </div>
            <Field name="behavior_id" component="input" type="hidden" />
            <div className="flex mt-10">
              <Button
                disabled={submitting || hasValidationErrors}
                className="h-10 w-2/6 mr-auto"
                type="submit"
                variant="default"
              >
                {behavior ? t("generic.save") : t("behavior.form.submit")}
              </Button>

              <Button
                className="h-10 w-2/6 ml-auto"
                variant="blueWhite"
                onClick={() => {
                  setSelected(null);
                  setSelectedBehavior(null);
                }}
              >
                {t("generic.cancel")}
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

const BehaviorWrapper = ({
  data,
  selectedFrame,
  selectedValueDescription,
  onEdit,
  onDelete,
}) => {
  const groupedBehavior = {};
  data?.forEach((behavior) => {
    if (
      selectedValueDescription &&
      selectedValueDescription !== behavior.value.description
    ) {
      return;
    }
    if (!groupedBehavior[behavior.value.description]) {
      groupedBehavior[behavior.value.description] = [];
    }
    groupedBehavior[behavior.value.description].push(behavior);
  });

  if (isEmpty(groupedBehavior)) {
    return <img className="m-auto" src={behaviorBg} alt="value background" />;
  }

  return (
    <>
      {Object.keys(groupedBehavior).map((key, index) => {
        return (
          <Fragment key={index}>
            <span className="text-darkBlue text-2xl mb-2">{key}</span>
            <FrameWrapper
              frames={groupedBehavior[key]}
              frameBg={behaviorFrameBg}
              selectedFrame={selectedFrame}
              useAnyFrameIsSelected={true}
              bgImage={behaviorBg}
              icon={behaviorIcon}
              onEdit={onEdit}
              onDelete={onDelete}
              type="behavior"
            />
          </Fragment>
        );
      })}
    </>
  );
};

const Behavior = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState();
  const [selectedBehavior, setSelectedBehavior] = useState(null);
  const { path, method } = getValuesEndpoint();
  const [{ data, status }, makeRequest] = useApi(path, method);
  const { company, setCompanyState, companyLogo } = useCompany();

  const { path: behaviorsPath, behaviorsMethod } = getBehaviorsEndpoint();
  const [
    { data: behaviorsData, status: behaviorsStatus },
    behaviorMakeRequest,
  ] = useApi(behaviorsPath, behaviorsMethod);

  const { path: transPath, method: transMethod } =
    transitionCompanyToConsequences(company);

  const [{ status: stateStatus, data: companyData }, transitionToConsequences] =
    useApi(transPath, transMethod);

  useEffect(() => {
    changeLocalCompanyState(stateStatus, companyData, setCompanyState);
  }, [stateStatus, companyData, setCompanyState]);

  useEffect(() => {
    makeRequest();
    behaviorMakeRequest();
  }, [behaviorMakeRequest, makeRequest]);

  const onDelete = async (id) => {
    try {
      await api.delete(`/v1/behavior/${id}/`);
    } catch {
      // TODO: Implement proper error handling here.
      console.log(`Failed to delete value with ID ${id}`);
    }
    reFetch();
  };

  const onEdit = ({ id, description, note, value }) => {
    setSelectedBehavior({ id, description, note, value });
    setSelectedValue(value);
  };

  const nextStep = () => {
    transitionToConsequences();
    history.push(paths.CONSEQUENCE);
  };

  const reFetch = () => {
    makeRequest();
    behaviorMakeRequest();
  };
  if (status === "FETCHING" || behaviorsStatus === "FETCHING") {
    return <Spinner />;
  }

  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={behaviorIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("behavior.header")}
          text={t("behavior.description_text")}
          example_title={t("behavior.example.title")}
          example_header={t("behavior.example.header")}
          example_text={t("behavior.example.text")}
        />
      }
      right={
        <div className="w-full flex flex-col">
          <Header
            amountStep={4}
            currentStep={2}
            backOnClick={() => {
              history.push(paths.VALUE);
            }}
          />
          <div className="flex flex-row h-4/5 mt-10">
            <div className="flex flex-col ml-10 w-35/100 h-full p-4 border border-greyFive overflow-auto">
              {selectedValue || selectedBehavior ? (
                <BehaviorForm
                  {...selectedValue}
                  setSelected={setSelectedValue}
                  setSelectedBehavior={setSelectedBehavior}
                  behavior={selectedBehavior}
                  onComplete={reFetch}
                />
              ) : (
                <>
                  <span className="text-grey font-bold mb-4">
                    {t("behavior.choose_behavior")}
                  </span>
                  <FrameWrapper
                    frames={data}
                    bgImage={behaviorBg}
                    icon={valueIcon}
                    frameClassname={"w-full cursor-pointer"}
                    selectedFrame={null}
                    frameOnClick={setSelectedValue}
                  />
                </>
              )}
            </div>
            <div className="flex flex-col w-2/3 ml-10 mr-2 overflow-auto">
              <BehaviorWrapper
                data={behaviorsData}
                selectedFrame={selectedBehavior}
                selectedValueDescription={selectedValue?.description}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <Button
              disabled={behaviorsData ? behaviorsData.length === 0 : true}
              className="h-12 mb-6 mt-8 w-1/3 mx-auto"
              variant="default"
              icon="arrow"
              iconColor="white"
              onClick={nextStep}
            >
              {t("behavior.navigate_next")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default Behavior;
