import { format } from "date-fns";
import sv from "date-fns/locale/sv";
import { useTranslation } from "react-i18next";

const FeedbackSection = ({ title, feedbacks }) => {
  return (
    <div className="mb-6">
      <p className="text-base text-darkBlue font-normal text-2xl mb-3">
        {title}
      </p>
      <div className="grid grid-cols-2 gap-3">
        {feedbacks.map((feedback, index) => {
          const date = format(new Date(feedback.created_at), "dd MMMM yyyy", {
            locale: sv,
          });
          const { created_by } = feedback;
          return (
            <div className="border border-greyFive p-3 rounded-md" key={index}>
              <div className="flex flex-row justify-between mb-2">
                <p className="text-lightGrey font-normal text-sm uppercase">
                  {date}
                </p>
                <p className="text-lightGrey font-normal text-sm uppercase">
                  {created_by.first_name
                    ? created_by.first_name.toUpperCase()
                    : null}{" "}
                  {created_by.last_name ? (
                    <>
                      {created_by.last_name[0].toUpperCase()} {"."}
                    </>
                  ) : null}
                </p>
              </div>
              <p className="text-grey">{feedback.comment}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Feedback = ({ groupFeedback }) => {
  const { t } = useTranslation();

  const lowValue = groupFeedback.filter(
    (feedback) => feedback.score >= 1 && feedback.score <= 10,
  );
  const mediumValue = groupFeedback.filter(
    (feedback) => feedback.score >= 11 && feedback.score <= 20,
  );
  const highValue = groupFeedback.filter((feedback) => feedback.score >= 21);

  return (
    <div className="border border-greyFive p-6 rounded-md">
      <p className="text-base text-darkBlue font-normal text-3xl mb-5">
        {t("meeting_summary.group_feedback")}
      </p>
      {lowValue.length !== 0 && (
        <FeedbackSection
          title={t("meeting_summary.must_be_addressed")}
          feedbacks={lowValue}
        />
      )}
      {mediumValue.length !== 0 && (
        <FeedbackSection
          title={t("meeting_summary.should_improve")}
          feedbacks={mediumValue}
        />
      )}
      {highValue.length !== 0 && (
        <FeedbackSection
          title={t("meeting_summary.good")}
          feedbacks={highValue}
        />
      )}
    </div>
  );
};
