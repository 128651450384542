import { calendarMonths } from "utils/dateUtils";
import { CalendarButtons } from "./calendarButtons";
import { MonthCard } from "./monthCard";

export const Calendar = ({
  year,
  isFirstHalfYear,
  onClickNext,
  onClickPrev,
  meetings,
}) => {
  const firstSixMonths = calendarMonths.slice(0, 6);
  const lastSixMonths = calendarMonths.slice(6, 12);
  return (
    <div className="calendar m-16">
      <div className="calenderTop mb-2">
        <span className="text-base text-darkBlue font-normal text-2xl">
          {year}
        </span>
        <CalendarButtons onClickNext={onClickNext} onClickPrev={onClickPrev} />
      </div>
      <div className="grid grid-cols-3 grid-rows-2 gap-0 h-full cardsContainer">
        {isFirstHalfYear
          ? firstSixMonths.map((month, index) => (
              <MonthCard
                year={year}
                meetings={meetings}
                month={month}
                key={index}
              />
            ))
          : lastSixMonths.map((month, index) => {
              return (
                <MonthCard
                  year={year}
                  meetings={meetings}
                  month={month}
                  key={index}
                />
              );
            })}
      </div>
    </div>
  );
};
