import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import Button from "components/button";
import api from "api";

const DESCRIPTION_FIELD_MAXLENGTH = 40;
const PURPOSE_FIELD_MAXLENGTH = 350;

const fieldClassName = "w-full flex border p-4 border-ligtherGrey resize-none";

const ConsequenceForm = ({
  makeRequest,
  createMakeRequest,
  formValues,
  setFormValues,
  selectedFrame,
  setSelectedFrame,
  company,
  userId,
  roleType,
}) => {
  const { t } = useTranslation();

  const onSubmit = async ({ description, purpose, type, id }) => {
    if (id) {
      await api.patch(`/v1/consequence/${id}/`, { description, purpose, type });
      setSelectedFrame(null);
      makeRequest();
    } else {
      createMakeRequest({
        description,
        purpose,
        type,
        company,
        user: userId,
        role_type: roleType,
      });
    }
    setFormValues({});
  };

  return (
    <Form
      initialValues={formValues}
      validate={(values) => {
        const errors = {};
        if (!values.description) {
          errors.description = "Required";
        }
        if (!values.type) {
          errors.values = "Required";
        }
        return errors;
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors, values }) => (
        <form className="flex flex-col h-full w-full" onSubmit={handleSubmit}>
          <div className="flex-col py-4">
            <label className="flex text-2xl pb-4">
              {t("consequence.form.stakeholder_description")}
            </label>
            <Field
              name="description"
              type="text"
              component="input"
              placeholder={t("consequence.form.write_here")}
            >
              {({ input }) => (
                <div className="relative">
                  <input
                    maxLength={DESCRIPTION_FIELD_MAXLENGTH}
                    className={fieldClassName}
                    {...input}
                  />
                  <span className="absolute right-2 top-1 text-xs">
                    {(values.description || "").length} /
                    {DESCRIPTION_FIELD_MAXLENGTH}
                  </span>
                </div>
              )}
            </Field>
          </div>
          <div className="flex flex-col h-full py-4">
            <label className="flex text-2xl pb-4">
              {t("consequence.form.purpose")}
            </label>
            <Field
              name="purpose"
              type="textarea"
              component="textarea"
              placeholder={t("consequence.form.write_here")}
              className={`${fieldClassName} h-5/6`}
              maxLength={PURPOSE_FIELD_MAXLENGTH}
            />
            <div className="flex justify-end pt-1">
              {(values.purpose || "").length}/ {PURPOSE_FIELD_MAXLENGTH}
            </div>
          </div>
          <div className="flex flex-col py-4">
            <label className="flex text-2xl pb-4">
              {t("consequence.form.type")}
            </label>
            <label className="custom-radio-container">
              <Field
                name="type"
                component="input"
                type="radio"
                value="positive"
              />
              <span className="custom-radio"></span>
              <span className="ml-2 text-lightGrey">
                {t("consequence.form.positive")}
              </span>
            </label>
            <label className="custom-radio-container">
              <Field
                name="type"
                component="input"
                type="radio"
                value="negative"
              />
              <span className="custom-radio"></span>
              <span className="ml-2 text-lightGrey">
                {t("consequence.form.negative")}
              </span>
            </label>
          </div>
          <Field name="id" component="input" type="hidden" />
          <div className="flex mt-10">
            <Button
              disabled={submitting || hasValidationErrors}
              className="h-10 w-5/12 mr-auto"
              type="submit"
              variant="default"
            >
              {selectedFrame ? t("generic.save") : t("consequence.form.submit")}
            </Button>

            {selectedFrame && (
              <Button
                className="h-10 w-5/12 ml-auto"
                variant="blueWhite"
                onClick={() => {
                  setSelectedFrame(null);
                  setFormValues({});
                }}
              >
                {t("generic.cancel")}
              </Button>
            )}
          </div>
        </form>
      )}
    />
  );
};

export default ConsequenceForm;
