import { Arrow } from "assets/svg/buttons";

const cssVariants = {
  default: "bg-ctaBlue border border-ctaBlue rounded-4xl text-white text-18",
  action: "bg-safflowerOrange  rounded-4xl text-darkGrey text-18",
  orangeWhite:
    "bg-white border border-lighterGrey rounded-4xl text-safflowerDark text-18 shadow-custom",
  blueWhite:
    "bg-white border border-lighterGrey rounded-4xl text-ctaBlue text-18 shadow-custom",
  flat: "rounded-4xl text-ctaBlue text-14 font-semibold",
};

const iconMap = {
  arrow: Arrow,
};

const Button = (props) => {
  const {
    children,
    className,
    variant,
    icon,
    iconColor = "#333333",
    disabled,
    ...rest
  } = props;

  const buttonVariant = cssVariants[variant] || cssVariants["default"];
  const classNames = disabled
    ? `${buttonVariant} ${className || ""} opacity-60 cursor-not-allowed`
    : `${buttonVariant} ${className || ""}`;
  const modifiedProps = {
    className: classNames,
    ...rest,
  };

  const IconComponent = (() => {
    return iconMap[icon];
  })();

  return (
    <button {...modifiedProps}>
      {icon ? (
        <div className="flex items-center justify-center h-full">
          <span>{children}</span>
          <IconComponent className="ml-4" fill={iconColor} stroke={iconColor} />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
