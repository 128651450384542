import HTTP from "api";

export const createSignificationEndpoint = () => ({
  path: "/v1/signification/",
  method: "POST",
});

export const getSignificationEndpoint = () => ({
  path: "/v1/signification/",
  method: "GET",
});

export const getSignificationsRequest = () => HTTP.get("/v1/signification/");
