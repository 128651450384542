import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import useApi from "hooks/useApi";
import useLocalStorage from "hooks/useLocalStorage";
import { getCompaniesEndpoint } from "api/company";
import { useTranslation } from "react-i18next";
import {
  setCompanyLanguagePath,
  clearCompanyLanguagePath,
} from "utils/localStorage";
import config from "../config";
const COMPANY_ID = "company_id";
const COMPANY_NAME = "company_name";
const COMPANY_STATE = "company_state";
const COMPANY_LOGO = "company_logo";
const COMPANY_BACKGROUND = "company_background";

const initialContext = {
  isLoading: true,
  companyId: null,
  companyName: null,
  companyState: null,
  companyLogo: null,
  companyBackground: null,
  setCompanyState: () => {},
  resetCompany: () => {},
  fetchCompany: () => {},
};

const CompanyContext = createContext(initialContext);

const CompanyProvider = (props) => {
  const { i18n } = useTranslation();
  const [companyId, setCompany] = useLocalStorage(COMPANY_ID, null);
  const [companyName, setCompanyName] = useLocalStorage(COMPANY_NAME, null);
  const [companyState, setCompanyState] = useLocalStorage(COMPANY_STATE, null);
  const [companyLogo, setCompanyLogo] = useLocalStorage(COMPANY_LOGO, null);
  const [companyBackground, setCompanyBackground] = useLocalStorage(
    COMPANY_BACKGROUND,
    null,
  );

  const [isLoading, setLoading] = useState(true);
  const { path, method } = getCompaniesEndpoint();
  const [{ data, status }, makeRequest] = useApi(path, method);

  const fetchCompany = useCallback(
    (forceRequest = false) => {
      if (companyId && companyState && !forceRequest) {
        setLoading(false);
        return companyId;
      } else {
        makeRequest();
      }
    },
    [companyId, companyState, setLoading, makeRequest],
  );

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  useEffect(() => {
    if (status === "SUCCESS" && data) {
      setCompany(data[0]["id"]);
      setCompanyName(data[0]["name"]);
      const state = data[0]["state"];
      setCompanyState(state);

      const logo = data[0]["logo"];
      setCompanyLogo(logo);

      const background = data[0]["background"];
      setCompanyBackground(background);
      setLoading(false);
    } else if (status === "ERROR") {
      setLoading(false);
    }
  }, [
    status,
    data,
    setLoading,
    setCompany,
    setCompanyName,
    setCompanyState,
    setCompanyLogo,
    setCompanyBackground,
  ]);

  useEffect(() => {
    if (status === "SUCCESS" && data) {
      if (data[0]["translation_file"]) {
        setCompanyLanguagePath(
          `/locales/${config.server_environment}/companies/${data[0]["id"]}/translation.json`,
        );
        i18n.init({
          fallbackLng: "se",
          interpolation: {
            escapeValue: false,
          },
          backend: {
            loadPath: `/locales/${config.server_environment}/companies/${data[0]["id"]}/translation.json`,
          },
          debug: false,
        });
      } else {
        clearCompanyLanguagePath();
      }
    }
  }, [status, data, i18n]);

  const resetCompany = () => {
    setCompany();
    setCompanyState();
    setCompanyLogo();
  };

  const sharedProps = {
    companyState,
    company: companyId,
    companyName,
    companyLogo,
    companyBackground,
    isLoading,
    setCompanyState,
    resetCompany,
    fetchCompany,
  };

  return <CompanyContext.Provider value={{ ...sharedProps }} {...props} />;
};

const useCompany = () => useContext(CompanyContext);

export { CompanyProvider, useCompany };
