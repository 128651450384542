import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import { Form, Field } from "react-final-form";
import api from "api";

const AddUser = ({ isOpen = false, onCloseClick, refetchUsers, company }) => {
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    console.log("values", values);
    try {
      await api.post("/v1/invite/", { invites: [{ company, ...values }] });
    } catch {
      console.log(`Failed to create invite with data ${values}`);
    }
    onCloseClick();
    refetchUsers();
  };

  if (!isOpen) return <></>;
  const required = (value) => (value ? undefined : "Required");

  return (
    <div className="absolute w-screen top-0 flex mt-56 justify-center">
      <div className="relative flex flex-col  bg-white shadow-popUp rounded px-4 pt-12 w-4/12">
        <RoundedCloseIcon
          onClick={() => {
            onCloseClick();
          }}
          className="absolute top-4 right-2 "
        />
        <h3 className="text-2xl text-darkBlue mb-6">
          {t("handle_users.new_user")}
        </h3>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit} className="flex flex-col mb-12">
                <div className="flex flex-row">
                  <Field
                    className="flex rounded-md border-2 border-lighterGrey w-2/4 h-8 pl-2"
                    name="first_name"
                    type="text"
                    component="input"
                    placeholder="Förnamn"
                  />
                  <Field
                    className="flex rounded-md border-2 border-lighterGrey w-2/4 h-8 pl-2"
                    name="last_name"
                    type="text"
                    component="input"
                    placeholder="Efternamn"
                  />
                </div>
                <Field
                  className="flex rounded-md border-2 border-lighterGrey w-full h-8 pl-2 mt-4"
                  name="email"
                  type="text"
                  component="input"
                  placeholder="E-post"
                  validate={required}
                />
                <Field
                  className="flex rounded-md border-2 border-lighterGrey w-full h-8 pl-2 mt-4"
                  name="role"
                  component="select"
                  initialValue="employee"
                >
                  <option value="employee">{t("roles.employee")}</option>;
                  <option value="teamleader">{t("roles.teamleader")}</option>;
                  <option value="stakeholder">{t("roles.stakeholder")}</option>;
                  <option value="executive">{t("roles.executive")}</option>;
                  <option value="consultant">{t("roles.consultant")}</option>;
                </Field>
                <Button
                  disabled={submitting}
                  className="h-10 w-2/6 ml-auto mt-4"
                  type="submit"
                  variant="default"
                >
                  {t("opinion.form.submit")}
                </Button>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AddUser;
