import CalendarContainer from "components/calendar/calendarContainer";
import { createContext, useEffect, useState } from "react";
import { getValuationDialoguesEndpoint } from "api/valuationDialogues";
import useApi from "hooks/useApi";
import MenuWrapper from "components/menuWrapper";
import { format, parseISO } from "date-fns";

export const DialogsContext = createContext({
  dialogs: [],
  setDialogs: () => {},
});

const ValuationDialogue = () => {
  const { path, method } = getValuationDialoguesEndpoint();
  const [{ data, status }, makeRequest] = useApi(path, method);

  const [dialogs, setDialogs] = useState([]);
  const value = { dialogs, setDialogs };

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  const formatDialogResponse = (data) => {
    if (!data || !data.length > 0) return [];
    return data.map(({ start_date, end_date, id }) => ({
      date: start_date.substring(0, 10),
      startTime: format(parseISO(start_date), "kk:mm"),
      endTime: format(parseISO(end_date), "kk:mm"),
      id,
    }));
  };
  useEffect(() => {
    if (status === "SUCCESS") {
      const formattedData = formatDialogResponse(data);
      setDialogs([...formattedData]);
    }
  }, [status, setDialogs, data]);
  return (
    <>
      <DialogsContext.Provider value={value}>
        <MenuWrapper />
        <CalendarContainer />
      </DialogsContext.Provider>
    </>
  );
};

export default ValuationDialogue;
