import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TopBar from "components/topBar";
import { paths } from "routing/routes";
import Button from "components/button";

const OverviewVision = ({
  vision,
  isPreProcess = false,
  isPrintout = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <TopBar menu={!isPrintout}>
      <div className="grid grid-cols-12 gap-3 z-2 min-h-16rem">
        <div className="col-start-4 col-span-6">
          {isPreProcess && !isPrintout && (
            <span className="float-right mr-20">
              <Button onClick={() => history.push(paths.VISION)} variant="flat">
                {t("overview.edit_vision")}
              </Button>
            </span>
          )}
          <div className="text-2xl pb-4 uppercase text-gray-500">
            {t("vision.our_vision")}
          </div>

          <div className="text-3xl pb-4 text-darkBlue">
            {!vision && !isPrintout && (
              <Button
                className="m-auto"
                onClick={() => history.push(paths.VISION)}
                variant="flat"
              >
                {t("overview.empty_vision")}
              </Button>
            )}
            {vision?.description}
          </div>

          <div className="text-xl pb-4 text-grey">{vision?.purpose}</div>
        </div>
      </div>
    </TopBar>
  );
};

export default OverviewVision;
