import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth-context";
import { paths } from "routing/routes";
import { Grid, GridLeftContent } from "components/grid";
import Button from "components/button";
import loginBgImage from "assets/img/backgrounds/login.png";
import { smartGoalLogo } from "assets/svg/logos";
import { copyRight } from "assets/svg/global";
import { ErrorAlert } from "components/alert";
import { hiddenIcon, viewIcon } from "assets/img/icons";

const Login = () => {
  const { login, isAuthenticated, authError, user } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (isAuthenticated) {
      if (user.groups.length > 1) {
        history.push(paths.CHANGE_GROUP);
        // use .go here to refresh context
        history.go();
      } else {
        history.push(paths.OVERVIEW);
        // use .go here to refresh context
        history.go();
      }
    }
  }, [isAuthenticated, history, user]);

  const onSubmit = ({ email, password }) => {
    login(email, password);
  };

  const [showPassword, setShowPassword] = useState(false);

  const fieldClassName =
    "flex rounded-md border-2 border-lighterGrey w-full h-12 pl-4";

  return (
    <>
      <Grid
        left={
          <GridLeftContent
            backgroundImage={loginBgImage}
            contentImage={smartGoalLogo}
            text={t("login.description_text")}
            text_alignment="center"
          >
            <div className="absolute bottom-8 text-darkBlue">
              <img
                src={copyRight}
                alt="copy right icon"
                className="inline pr-2"
              />
              <span className="text-xs">{"2021. Powered by: CediTech"}</span>
            </div>
          </GridLeftContent>
        }
        right={
          <div className="m-auto items-center">
            <ErrorAlert show={authError} text={t("login.failed_login")} />
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="flex flex-col w-500" onSubmit={handleSubmit}>
                  <div className="flex-col py-4 text-28 text-darkBlue">
                    <span>{t("login.login")}</span>
                  </div>
                  <div className="flex-col py-4">
                    <label className="flex text-2xl pb-4">
                      {t("login.email")}:
                    </label>
                    <Field
                      name="email"
                      type="email"
                      component="input"
                      placeholder="email@email.com"
                      className={fieldClassName}
                    />
                  </div>
                  <div className="flex-col py-4">
                    <label className="flex text-2xl pb-4">
                      {t("login.password")}:
                    </label>
                    <Field
                      name="password"
                      className={fieldClassName}
                      render={({ input, meta }) => (
                        <div className="relative">
                          <input
                            className={fieldClassName}
                            type={showPassword ? "text" : "password"}
                            {...input}
                            placeholder="*****"
                          />
                          <div
                            className="absolute top-3 right-3 cursor-pointer"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            <img
                              src={showPassword ? hiddenIcon : viewIcon}
                              width="25px"
                              height="25px"
                              alt="showPasswordIcon"
                            />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <Button
                    className="h-12 mt-auto absolute bottom-8 w-500"
                    type="submit"
                    variant="action"
                    icon="arrow"
                  >
                    {t("login.next")}
                  </Button>
                </form>
              )}
            />
            <div className="my-4 text-center">
              <Button
                variant="flat"
                onClick={() => history.push("/forgot-password")}
              >
                {t("login.forgot_password")}
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Login;
