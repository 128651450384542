const cssVariants = {
  negative: "bg-lightRed",
  positive: "bg-lightGreen",
  isProposal: "bg-lightBlue",
};

const Pill = ({ type, text, className }) => {
  return (
    <span
      className={`rounded-4xl py-1 px-3 my-auto text-center text-xs text-grey ${cssVariants[type]} ${className}`}
    >
      {text}
    </span>
  );
};

export default Pill;
