import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCompany } from "context/company-context";
import { useAuth } from "context/auth-context";
import { paths } from "routing/routes";
import useApi from "hooks/useApi";
import api from "api";
import { getValuesEndpoint } from "api/value";
import { getBehaviorsEndpoint } from "api/behavior";
import { getOpinionsEndpoint } from "api/opinion";
import BehaviorStakeholderWrapper from "./behaviorStakeholderWrapper";
import OpinionForm from "./opinionForm";
import { Grid, GridLeftContent } from "components/grid";
import Header from "components/header";
import Button from "components/button";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";
import { behaviorIcon } from "assets/svg/icons";
import { valueIcon } from "assets/svg/icons";
import { behaviorBg } from "assets/svg/backgrounds";
import visionBgImage from "assets/img/backgrounds/vision.jpg";
import { smartGoalLogo } from "assets/svg/logos";

const BehaviorStakeholder = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuth();
  const { companyLogo } = useCompany();

  const { path: valuesPath, method: valuesMethod } = getValuesEndpoint();
  const [{ data: valuesData, status: valuesStatus }, makeValuesRequest] =
    useApi(valuesPath, valuesMethod);
  const { path: behaviorsPath, behaviorsMethod } = getBehaviorsEndpoint();
  const [
    { data: behaviorsData, status: behaviorsStatus },
    makeBehaviorsRequest,
  ] = useApi(behaviorsPath, behaviorsMethod);
  const { path: opinionsPath, method: opinionsMethod } = getOpinionsEndpoint();
  const [{ data: opinionsData, status: opinionsStatus }, makeOpinionsRequest] =
    useApi(opinionsPath, opinionsMethod);

  const userId = user.pk;

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedBehavior, setSelectedBehavior] = useState(null);

  useEffect(() => {
    makeValuesRequest();
    makeBehaviorsRequest();
    makeOpinionsRequest();
  }, [makeBehaviorsRequest, makeOpinionsRequest, makeValuesRequest]);

  const onDelete = async (id) => {
    try {
      await api.delete(`/v1/opinion/${id}/`);
    } catch {
      // TODO: Implement proper error handling here.
      console.log(`Failed to delete opinion with ID ${id}`);
    }
    reFetch();
  };

  const onEdit = ({ id, description, note, value, opinion }) => {
    setSelectedBehavior({ id, description, note, value, opinion });
    setSelectedValue(value);
  };

  const reFetch = () => {
    makeValuesRequest();
    makeBehaviorsRequest();
    makeOpinionsRequest();
  };

  if (
    valuesStatus === "FETCHING" ||
    behaviorsStatus === "FETCHING" ||
    opinionsStatus === "FETCHING"
  ) {
    return <Spinner />;
  }

  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={behaviorIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("opinion.header")}
          text={t("opinion.description_text")}
          example_title={t("opinion.example.title")}
          example_header={t("opinion.example.header")}
          example_text={t("opinion.example.text")}
        />
      }
      right={
        <div className="w-full flex flex-col">
          <Header showOnlyMenu />
          <div className="flex flex-row h-4/5 mt-10">
            <div className="flex flex-col ml-10 w-35/100 h-full p-4 border border-greyFive overflow-auto">
              {selectedBehavior ? (
                <OpinionForm
                  {...selectedValue}
                  userId={userId}
                  setSelected={setSelectedValue}
                  setSelectedBehavior={setSelectedBehavior}
                  behavior={selectedBehavior}
                  onComplete={reFetch}
                />
              ) : (
                <>
                  <span className="text-grey font-bold mb-4">
                    {t("opinion.choose_behavior")}
                  </span>
                  <FrameWrapper
                    frames={valuesData}
                    bgImage={behaviorBg}
                    icon={valueIcon}
                    frameClassname={"w-full cursor-pointer"}
                    selectedFrame={null}
                    frameOnClick={setSelectedValue}
                  />
                </>
              )}
            </div>
            <div className="flex flex-col w-2/3 ml-10 mr-2 overflow-auto">
              <BehaviorStakeholderWrapper
                userId={userId}
                behaviorsData={behaviorsData}
                selectedFrame={selectedBehavior}
                selectedValueDescription={selectedValue?.description}
                onEdit={onEdit}
                onDelete={onDelete}
                opinionsData={opinionsData}
              />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <Button
              disabled={false}
              className="h-12 mb-6 mt-8 w-1/3 mx-auto"
              variant="default"
              icon="arrow"
              iconColor="white"
              onClick={() => history.push(paths.OVERVIEW)}
            >
              {t("opinion.navigate_next")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default BehaviorStakeholder;
