import RoundedCloseIcon from "assets/svg/icons/RoundedCloseIcon.svg.js";
import Button from "components/button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "hooks/useApi";
import {
  patchValuationDialogNoteEndpoint,
  createValuationDialogNoteEndpoint,
} from "api/valuationDialogues";

const NotePopup = ({
  showPopup,
  onCloseClick,
  note,
  dialogId,
  setShowNotePopup,
  refetchNotes,
}) => {
  const { path: patchDialogNotePath, method: patchDialogNoteMethod } =
    patchValuationDialogNoteEndpoint(dialogId, note?.id);
  const [{ status }, makePatchRequest] = useApi(
    patchDialogNotePath,
    patchDialogNoteMethod,
  );
  const { path: createDialogNotePath, method: createDialogNoteMethod } =
    createValuationDialogNoteEndpoint(dialogId);
  const [{ status: createDialogNoteStatus }, makePostRequest] = useApi(
    createDialogNotePath,
    createDialogNoteMethod,
  );

  const [noteText, setNoteText] = useState(note?.text);
  const submit = () => {
    if (note) {
      makePatchRequest({ text: noteText });
    } else {
      makePostRequest({ text: noteText });
    }
  };

  useEffect(() => {
    if (createDialogNoteStatus === "SUCCESS" || status === "SUCCESS") {
      refetchNotes();
      setShowNotePopup(false);
      setNoteText("");
    }
  }, [
    createDialogNoteStatus,
    status,
    setShowNotePopup,
    setNoteText,
    refetchNotes,
  ]);

  useEffect(() => {
    if (note) {
      setNoteText(note?.text);
    } else {
      setNoteText("");
    }
  }, [note]);

  const { t } = useTranslation();
  if (showPopup)
    return (
      <div className="absolute w-screen h-screen inset-0 flex justify-center items-center">
        <div className="relative flex flex-col w-96 shadow-popUp rounded pt-11 px-4 pb-6 bg-white">
          <RoundedCloseIcon
            onClick={onCloseClick}
            className="absolute top-4 right-2"
          />
          <h2 className="mb-6 text-2xl text-darkBlue">
            {note
              ? t("meeting_summary.edit_note")
              : t("meeting_summary.new_note")}
          </h2>
          <label htmlFor="label" className="mb-2 text-base text-grey">
            {t("meeting_summary.note")}:
          </label>
          <textarea
            className="border border-greyFive p-4  h-28 text-justify"
            type="text"
            onChange={(event) => {
              setNoteText(event.target.value);
            }}
            value={noteText}
          />
          <Button
            className="h-12 pl-5 pr-5 self-end mt-4"
            variant="action"
            onClick={submit}
          >
            {t("handle_group_members.save")}
          </Button>
        </div>
      </div>
    );
  return null;
};

export default NotePopup;
