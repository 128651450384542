import OrganizationPrintoutLandscape from "views/printouts/organizationPrintoutLandscape";
import Menu from "components/menu";

const OrganizationOverview = () => {
  return (
    <>
      <OrganizationPrintoutLandscape />
      <Menu />
    </>
  );
};

export default OrganizationOverview;
