import * as React from "react";

const CalendarIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.333 2H2.667a2 2 0 0 0-2 2v9.333a2 2 0 0 0 2 2h10.666a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2ZM.667 6h14.666M8 3.333V.667M4 3.333V.667M12 3.333V.667M3.333 9.333h1.334M7.333 9.333h1.334M11.334 9.333h1.333M3.333 12h1.334M7.333 12h1.334M11.334 12h1.333"
      stroke="#193557"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarIcon;
