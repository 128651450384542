export const calculatePercentage = (total, partialValue) => {
  if (!total || !partialValue) return 0;

  const percentage = (partialValue / total) * 100;
  return percentage.toFixed(0);
};

export const getFeedbackStats = (totalMembers, dialogs) => {
  let good = 0;
  let shouldImprove = 0;
  let mustBeAddressed = 0;
  let didntAnswer = 0;
  let totalFeedback = totalMembers;

  dialogs.forEach((dialog) => {
    if (dialog.feedback.length === 0) {
      didntAnswer = 100;
      return {
        good: 0,
        shouldImprove: 0,
        mustBeAddressed: 0,
        didntAnswer: didntAnswer,
      };
    }
    dialog.feedback.forEach((feedback) => {
      if (feedback.score >= 1 && feedback.score <= 10) mustBeAddressed++;
      if (feedback.score >= 11 && feedback.score <= 20) shouldImprove++;
      if (feedback.score >= 21 && feedback.score <= 30) good++;
    });
  });

  if (totalFeedback === 0) {
    return { good: 0, shouldImprove: 0, mustBeAddressed: 0, didntAnswer: 100 };
  }

  const goodPercent = Math.round((good / totalFeedback) * 100);
  const shouldImprovePercent = Math.round(
    (shouldImprove / totalFeedback) * 100,
  );
  const mustBeAddressedPercent = Math.round(
    (mustBeAddressed / totalFeedback) * 100,
  );
  didntAnswer = Math.round(
    ((totalFeedback - good - shouldImprove - mustBeAddressed) / totalFeedback) *
      100,
  );

  if (
    isNaN(goodPercent) ||
    isNaN(shouldImprovePercent) ||
    isNaN(mustBeAddressedPercent)
  ) {
    return {
      good: 0,
      shouldImprove: 0,
      mustBeAddressed: 0,
      didntAnswer: didntAnswer,
    };
  }

  return {
    good: goodPercent,
    shouldImprove: shouldImprovePercent,
    mustBeAddressed: mustBeAddressedPercent,
    didntAnswer: didntAnswer,
  };
};
