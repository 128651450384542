const RoundedCloseIcon = ({ ...props }) => (
  <svg
    width={39}
    height={38}
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect x={4.4} width={30} height={30} rx={15} fill="#fff" />
      <g clipPath="url(#b)" fill="#193557">
        <path d="M14.888 20a.489.489 0 0 1-.345-.834l9.024-9.023a.488.488 0 1 1 .69.69l-9.023 9.024a.486.486 0 0 1-.346.143Z" />
        <path d="M23.912 20a.487.487 0 0 1-.345-.143l-9.024-9.024a.488.488 0 1 1 .69-.69l9.024 9.023a.489.489 0 0 1-.345.834Z" />
      </g>
      <rect
        x={5.15}
        y={0.75}
        width={28.5}
        height={28.5}
        rx={14.25}
        stroke="#F2F2F2"
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" transform="translate(14.4 10)" d="M0 0h10v10H0z" />
      </clipPath>
      <filter
        id="a"
        x={0.4}
        y={0}
        width={38}
        height={38}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_29_15725"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_29_15725"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default RoundedCloseIcon;
