export const loginEndpoint = () => ({
  path: "/api/token/",
  method: "POST",
});

export const logoutEndpoint = () => ({
  path: "/rest-auth/logout/",
  method: "POST",
});

export const userEndpoint = () => ({
  path: "/rest-auth/user/",
  method: "GET",
});

export const refreshTokenEndpoint = () => ({
  path: "/api/token/refresh/",
  method: "POST",
});

export const resetPasswordEndpoint = () => ({
  path: "/rest-auth/password/reset/",
  method: "POST",
});

export const resetPasswordConfirmEndpoint = () => ({
  path: "/rest-auth/password/reset/confirm/",
  method: "POST",
});

export const signupEndpoint = () => ({
  path: "/rest-auth/registration/",
  method: "POST",
});
