import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  getConsequenceEndpoint,
  createConsequenceEndpoint,
} from "api/consequence";
import { getRolesEndpoint } from "api/role";
import useApi from "hooks/useApi";
import api from "api";
import { transitionCompanyToOverview } from "api/company";
import { paths } from "routing/routes";
import { useAuth } from "context/auth-context";
import { useCompany } from "context/company-context";

import visionBgImage from "assets/img/backgrounds/vision.jpg";
import { consequenceIcon } from "assets/svg/icons";
import { consequenceBg } from "assets/svg/backgrounds";
import { smartGoalLogo } from "assets/svg/logos";
import { changeLocalCompanyState } from "utils/changeLocalCompanyState";
import constants from "utils/constants";
import Button from "components/button";
import Header from "components/header";
import Spinner from "components/spinner";
import FrameWrapper from "components/frameWrapper";
import { Grid, GridLeftContent } from "components/grid";

const DESCRIPTION_FIELD_MAXLENGTH = 40;
const PURPOSE_FIELD_MAXLENGTH = 350;

const Consequence = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useAuth();
  const { company, isLoading, setCompanyState, companyLogo } = useCompany();
  const { path: getConsequencePath, method: getConsequenceMethod } =
    getConsequenceEndpoint();
  const { path: createConsequencePath, method: createConsequenceMethod } =
    createConsequenceEndpoint();
  const [{ status: createStatus }, makeCreateConsequenceRequest] = useApi(
    createConsequencePath,
    createConsequenceMethod,
  );
  const [
    { data: consequenceData, status: consequenceStatus },
    makeGetConsequenceRequest,
  ] = useApi(getConsequencePath, getConsequenceMethod);
  const roleEndpoint = getRolesEndpoint();
  const [{ data: roleData, status: roleStatus }, makeGetRoleRequest] = useApi(
    roleEndpoint.path,
    roleEndpoint.method,
  );

  const { path: transPath, method: transMethod } =
    transitionCompanyToOverview(company);

  const [{ status: stateStatus, data: companyData }, transitionToOverview] =
    useApi(transPath, transMethod);

  const userId = user.pk;
  const role = roleData?.find((role) => role?.user === userId);
  const isPreProcess = constants.PRE_PROCESS_ROLES.includes(role?.type);
  const consequences = consequenceData?.filter(
    (consequence) =>
      isPreProcess &&
      constants.PRE_PROCESS_ROLES.includes(consequence.role_type),
  );

  const [formValues, setFormValues] = useState({});
  const [selectedFrame, setSelectedFrame] = useState(null);

  useEffect(() => {
    makeGetConsequenceRequest();
    makeGetRoleRequest();
  }, [makeGetConsequenceRequest, makeGetRoleRequest]);

  useEffect(() => {
    if (createStatus === "SUCCESS") {
      makeGetConsequenceRequest();
    }
  }, [createStatus, makeGetConsequenceRequest]);

  useEffect(() => {
    changeLocalCompanyState(stateStatus, companyData, setCompanyState);
  }, [stateStatus, companyData, setCompanyState]);

  const onSubmit = async ({ description, purpose, type, id }) => {
    // If ID we are editing existing resource
    if (id) {
      await api.patch(`/v1/consequence/${id}/`, { description, purpose, type });
      setSelectedFrame(null);
      makeGetConsequenceRequest();
    } else {
      makeCreateConsequenceRequest({
        description,
        purpose,
        type,
        company,
        user: userId,
        role_type: role?.type,
      });
    }
    setFormValues({});
  };

  const onDelete = async (id) => {
    try {
      await api.delete(`/v1/consequence/${id}/`);
    } catch {
      // TODO: Implement proper error handling here.
      console.log(`Failed to delete consequence with ID ${id}`);
    }
    setSelectedFrame(null);
    setFormValues({});
    makeGetConsequenceRequest();
  };

  const onEdit = ({ id, description, purpose, type }) => {
    setFormValues({ id, description, purpose, type });
    setSelectedFrame(id);
  };

  const nextStep = () => {
    if (selectedFrame !== null) {
      return;
    }
    transitionToOverview();
    history.push(paths.OVERVIEW);
  };

  if (
    isLoading ||
    consequenceStatus === "FETCHING" ||
    roleStatus === "FETCHING"
  ) {
    return <Spinner />;
  }

  const fieldClassName =
    "w-full flex border p-4 border-ligtherGrey resize-none";

  return (
    <Grid
      left={
        <GridLeftContent
          backgroundImage={visionBgImage}
          contentImage={consequenceIcon}
          topLeftImage={companyLogo || smartGoalLogo}
          header={t("consequence.header")}
          text={t("consequence.description_text")}
          example_title={t("consequence.example.title")}
          example_header={t("consequence.example.header")}
        ></GridLeftContent>
      }
      right={
        <div className="w-full flex flex-col">
          <Header
            amountStep={4}
            currentStep={3}
            backOnClick={() => {
              history.push(paths.BEHAVIOR);
            }}
          />
          <div className="flex flex-row h-4/5  mt-10 overflow-auto">
            <div className="flex flex-col ml-10 w-35/100 p-8 h-full border border-greyFive">
              <Form
                initialValues={formValues}
                validate={(values) => {
                  const errors = {};
                  if (!values.description) {
                    errors.description = "Required";
                  }
                  if (!values.type) {
                    errors.values = "Required";
                  }
                  return errors;
                }}
                onSubmit={onSubmit}
                render={({
                  handleSubmit,
                  submitting,
                  hasValidationErrors,
                  values,
                }) => (
                  <form
                    className="flex flex-col h-full w-full"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex-col py-4">
                      <label className="flex text-2xl pb-4">
                        {t("consequence.form.description")}
                      </label>
                      <Field
                        name="description"
                        type="text"
                        component="input"
                        placeholder={t("consequence.form.write_here")}
                      >
                        {({ input }) => (
                          <div className="relative">
                            <input
                              maxLength={DESCRIPTION_FIELD_MAXLENGTH}
                              className={fieldClassName}
                              {...input}
                            />
                            <span className="absolute right-2 top-1 text-xs">
                              {(values.description || "").length} /
                              {DESCRIPTION_FIELD_MAXLENGTH}
                            </span>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex flex-col h-full py-4">
                      <label className="flex text-2xl pb-4">
                        {t("consequence.form.purpose")}
                      </label>
                      <Field
                        name="purpose"
                        type="textarea"
                        component="textarea"
                        placeholder={t("consequence.form.write_here")}
                        className={`${fieldClassName} h-5/6`}
                        maxLength={PURPOSE_FIELD_MAXLENGTH}
                      />
                      <div className="flex justify-end pt-1">
                        {(values.purpose || "").length}/{" "}
                        {PURPOSE_FIELD_MAXLENGTH}
                      </div>
                    </div>
                    <div className="flex flex-col py-4">
                      <label className="flex text-2xl pb-4">
                        {t("consequence.form.type")}
                      </label>
                      <label className="custom-radio-container">
                        <Field
                          name="type"
                          component="input"
                          type="radio"
                          value="positive"
                        />
                        <span className="custom-radio"></span>
                        <span className="ml-2 text-lightGrey">
                          {t("consequence.form.positive")}
                        </span>
                      </label>
                      <label className="custom-radio-container">
                        <Field
                          name="type"
                          component="input"
                          type="radio"
                          value="negative"
                        />
                        <span className="custom-radio"></span>
                        <span className="ml-2 text-lightGrey">
                          {t("consequence.form.negative")}
                        </span>
                      </label>
                    </div>
                    <Field name="id" component="input" type="hidden" />
                    <div className="flex mt-10">
                      <Button
                        disabled={submitting || hasValidationErrors}
                        className="h-10 w-5/12 mr-auto"
                        type="submit"
                        variant="default"
                      >
                        {selectedFrame
                          ? t("generic.save")
                          : t("consequence.form.submit")}
                      </Button>

                      {selectedFrame && (
                        <Button
                          className="h-10 w-5/12 ml-auto"
                          variant="blueWhite"
                          onClick={() => {
                            setSelectedFrame(null);
                            setFormValues({});
                          }}
                        >
                          {t("generic.cancel")}
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              />
            </div>
            <div className="flex flex-col w-2/3 ml-10 mr-2">
              <FrameWrapper
                frames={consequences}
                selectedFrame={selectedFrame}
                bgImage={consequenceBg}
                icon={consequenceIcon}
                onEdit={onEdit}
                onDelete={onDelete}
                type="consequence"
              />
            </div>
          </div>
          <div className="flex flex-row w-full mt-10">
            <Button
              disabled={
                consequences
                  ? consequences.length === 0 || selectedFrame !== null
                  : true
              }
              className="h-12 w-1/3 mx-auto"
              variant="default"
              icon="arrow"
              iconColor="white"
              onClick={nextStep}
            >
              {t("consequence.navigate_next")}
            </Button>
          </div>
        </div>
      }
    />
  );
};
export default Consequence;
