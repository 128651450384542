import { caretIcon } from "assets/svg/icons";
export const CalendarButtons = ({ onClickPrev, onClickNext }) => {
  return (
    <div className="buttonsContainer">
      <img
        onClick={() => onClickPrev()}
        src={caretIcon}
        alt="prev"
        style={{ transform: "rotate(180deg)" }}
      />
      <img onClick={() => onClickNext()} src={caretIcon} alt="next" />
    </div>
  );
};
