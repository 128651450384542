import PlusIcon from "assets/svg/icons/PlusIcon.svg.js";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import { Note } from "components/note";

export const Notes = ({
  notes,
  onEditClick,
  onAddNoteClick,
  dialogId,
  refetchNotes,
}) => {
  const { t } = useTranslation();

  return (
    <div className="border border-greyFive p-6 rounded-md max-h-526 flex flex-col overflow-scroll">
      <div className="flex">
        <p className="text-base text-darkBlue font-normal text-3xl mb-5">
          {t("meeting_summary.notes")}
        </p>

        <Button
          className="flex h-10 px-5 items-center justify-between ml-auto"
          variant="action"
          onClick={onAddNoteClick}
        >
          <PlusIcon className="mr-4" />
          {t("meeting_summary.note")}
        </Button>
      </div>

      {notes.map((note, index) => {
        return (
          <Note
            note={note}
            index={index}
            dialogId={dialogId}
            onEditClick={onEditClick}
            refetchNotes={refetchNotes}
          />
        );
      })}
    </div>
  );
};
