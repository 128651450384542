import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useCompany } from 'context/company-context';
import useApi from 'hooks/useApi';
import api from 'api';
import { createOpinionEndpoint } from 'api/opinion';
import Button from 'components/button';

const NOTE_FIELD_MAXLENGTH = 350;

const OpinionForm = ({
  id,
  description,
  userId,
  setSelectedFrame,
  consequence,
  setFormValues,
  onComplete,
}) => {
  const { t } = useTranslation();
  const { company, isLoading } = useCompany();
  const { path, method } = createOpinionEndpoint();
  const [{ status }, makeRequest] = useApi(path, method);

  useEffect(() => {
    if (status === 'SUCCESS') {
      setSelectedFrame(null);
      onComplete();
    }
  }, [status, setSelectedFrame, onComplete]);

  const onSubmit = async ({ opinion, opinionId }) => {
    const opinionNote = opinion?.note;
    if (opinionId) {
      try {
        await api.patch(`/v1/opinion/${opinionId}/`, { note: opinionNote });
      } catch {
        // TODO: Implement proper error handling here.
        console.log(`Failed to submit opinion with ID ${opinionId}`);
      }
      setFormValues({});
      setSelectedFrame(null);
      onComplete();
    } else {
      makeRequest({
        opinion: opinionId,
        note: opinionNote,
        behavior: null,
        company,
        consequence: consequence.id,
        user: userId,
      });
    }
  };

  const serializeInitialData = (behavior) => {
    if (!behavior) {
      return null;
    }
    const opinionId = behavior?.opinion?.id;
    return { opinionId, ...behavior };
  };

  if (!id || isLoading) {
    return null;
  }

  const fieldClassName =
    'w-full flex border p-4 border-ligtherGrey resize-none';

  return (
    <>
      <span className="text-grey font-bold mb-4">
        {`${t('consequence.form.description')}: ${description}`}
      </span>
      <Form
        initialValues={serializeInitialData(consequence)}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form
              className="flex flex-col h-full w-full"
              onSubmit={handleSubmit}
            >
              <div className="flex-col h-full py-4">
                <label className="flex text-2xl pb-4">
                  {t('opinion.form.note')}
                </label>
                <Field
                  name="opinion.note"
                  type="textarea"
                  component="textarea"
                  placeholder={t('opinion.form.write_here')}
                  className={`${fieldClassName} h-5/6`}
                  maxLength={NOTE_FIELD_MAXLENGTH}
                />
                <div className="flex justify-end pt-1">
                  {(values.opinion?.note || '').length}/ {NOTE_FIELD_MAXLENGTH}
                </div>
              </div>
              <Field name="opinion_id" component="input" type="hidden" />
              <div className="flex mt-10">
                <Button
                  disabled={submitting}
                  className="h-10 w-2/6 mr-auto"
                  type="submit"
                  variant="default"
                >
                  {t('opinion.form.submit')}
                </Button>

                <Button
                  className="h-10 w-2/6 ml-auto"
                  variant="blueWhite"
                  onClick={() => {
                    setFormValues({});
                    setSelectedFrame(null);
                  }}
                >
                  {t('generic.cancel')}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default OpinionForm;
